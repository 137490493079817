import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getCompanyList } from "../../../Redux/API/api_userManagement";
import {
  addDivisionMaster,
  editDivisionMaster,
  getDivisionMasterList,
} from "../../../Redux/API/api_master";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddDivision() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Division");
  const [data, setData] = useState([]);
  const [divisionId, setDivisionId] = useState("");
  const [divisionCode, setDivisionCode] = useState("");
  const [divisionCodeHelper, setDivisionCodeHelper] = useState("");
  const [divisionName, setDivisionName] = useState("");
  const [divisionNameHelper, setDivisionNameHelper] = useState("");
  const [statusValue, setStatusValue] = useState(true);
  let [isLoader, setIsLoader] = useState(false);

  
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/division";
    }
  };

  //! Multi-select
  const [selectedOptionsCompany, setSelectedOptionsCompany] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [filterCompanyID, setFilterCompanyId] = useState("");
  function editCompanyByDivision(companyId) {
    var arrayCompany = companyId.split(",");
    setSelectedOptionsCompany(
      companyData.filter((x) => arrayCompany.includes(x.id))
    );
  }
  let companyArr = [];
  const [companyString, setCompanyString] = useState("");
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChangeCompany(value, event) {
    let companyValue = [];

    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      companyValue = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      companyValue = companyData;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      companyValue = [];
    } else {
      companyValue = value;
    }
    for (let i = 0; i < companyValue.length; i++) {
      if (companyValue[i].id !== undefined) companyArr.push(companyValue[i].id);
    }

    setCompanyString(companyArr.toString());
    console.log(companyString);
  }

  //! Dropdown functions
  const handleChangeCode = (e) => {
    setDivisionCode(e.target.value);
    let valid = /^[a-zA-Z0-9 _-]*$/.test(e.target.value);
    if (!valid) {
      setDivisionCodeHelper("Please enter valid values.");
    } else if (e.target.value.length < 1) {
      setDivisionCodeHelper("Minimum 2 characters required");
    } else {
      setDivisionCodeHelper("");
    }
  };
  const handleChangeName = (e) => {
    setDivisionName(e.target.value);
    if (e.target.value.length === 0) {
      setDivisionNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setDivisionNameHelper("Length cannot excced more than 200.");
    } else {
      setDivisionNameHelper("");
    }
  };
  const handleChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };

  //!Get Methods
  function getCompany() {
    getCompanyList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setCompanyData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  var formdata = {
    divisionCode: divisionCode,
    divisionName: divisionName,
    companyId: companyString,
    status: statusValue,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    divisionId: divisionId,
    divisionCode: divisionCode,
    divisionName: divisionName,
    companyId: companyString,
    status: statusValue,
    modifiedBy: userDetails.userId,
  };

  //! Post methods
  function addDivisionData() {
    setIsLoader(true);
    addDivisionMaster(formdata,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Division Added Successfully.", "success");
          setIsLoader(false);
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Division Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Division.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        setIsLoader(false);
        console.log("error", error);
      });
  }

  function editDivisionData() {
    setIsLoader(true);
    debugger;
    editDivisionMaster(formdataEdit,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Division Edited Successfully.", "success");
          setIsLoader(false);
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Division Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Editing Division.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        setIsLoader(false);
        console.log("error", error);
      });
  }

  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");

  function getDivisionList() {
    setIsLoader(true);
    getDivisionMasterList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,atobSecure(encryptedId))
      .then((response) => {
        setDivisionCode(response[0].divisionCode);
        setDivisionName(response[0].divisionName);
        setCompanyString(response[0].companyId);
        editCompanyByDivision(response[0].companyId);
        setFilterCompanyId(response[0].companyId);
        setStatusValue(response[0].status);
        setDivisionId(response[0].divisionId);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (siteToken) {
      getCompany();
    }
    if (encryptedId) {
      setPageHeader("Edit Division");
      getDivisionList();
    }
  }, [siteToken, encryptedId]);
  useEffect(() => {
    editCompanyByDivision(filterCompanyID);
  }, [companyData, filterCompanyID]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <div className="box-shadow">
        <Grid
          container
          spacing={2}
          direction="row"
          className="upload-block pt-1">
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Division Code"
              id="masterKey"
              name="Master Key"
              className=""
              value={divisionCode}
              onChange={handleChangeCode}
              helperText={divisionCodeHelper}
              fullWidth
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={4} className="">
            <TextField
              label="Division Name"
              id="divisionName"
              name="divisionName"
              className=""
              fullWidth
              variant="filled"
              value={divisionName}
              helperText={divisionNameHelper}
              inputProps={{ maxLength: 100 }}
              onChange={handleChangeName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...companyData]}
              placeholderButtonLabel="Company"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={selectedOptionsCompany}
              onChange={onChangeCompany}
              setState={setSelectedOptionsCompany}
            />
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              select
              label="Status"
              id="status"
              name="Status"
              className=""
              fullWidth
              value={statusValue}
              onChange={handleChangeStatus}
              variant="filled">
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="upload-block d-flex jc-center pb-2">
          {encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={editDivisionData}
                disabled={
                  !divisionCode || !divisionName || companyString.length === 0
                }
                fullWidth>
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {!encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={addDivisionData}
                disabled={
                  !divisionCode || !divisionName || companyString.length === 0
                }
                fullWidth>
                Submit
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={1} className="align-center width-100">
            <Link to={"/division"}>
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                fullWidth>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
        {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      </div>

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
