import { baseApiURL } from "../../Utilities/Utility";

export function GetMetaDataDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/GetMetadata?toolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export async function getLastUploadedFormAdjustment(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  issueId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Appeal/GetLatestFormAdjustmenUploadExcel?issueID=" +
        issueId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getFilteredUploadedFormAdjustment(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  issueId,
  startDate,
  endDate
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Appeal/GetFormAdjustmentCalculationHistory?issueID=" +
        issueId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
