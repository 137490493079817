import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Screens/Home/Home";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import ToolCaseList from "../Screens/ToolCaseList/ToolCaseList";
import Landing from "../Screens/LandingPage/Landing";
import Payment from "../Screens/Payment/Payment";
import AddUser from "../Screens/Administrator/UserManagement/AddUser";
import Admin from "../Screens/Administrator/Admin";
import HardCodeMasterList from "../Screens/Administrator/HardCodeMaster/HardCodeMasterList";
import AddHardCodeMaster from "../Screens/Administrator/HardCodeMaster/HardCodeMasterInput";
import AddLinkedMaster from "../Screens/Administrator/LinkedMaster/LinkedMasterInput";
import LinkedMasterList from "../Screens/Administrator/LinkedMaster/LinkedMasterList";
import AddCompanyMaster from "../Screens/Administrator/CompanyMaster/CompanyMasterInput";
import CompanyMasterList from "../Screens/Administrator/CompanyMaster/CompanyMasterList";
import WorkflowHistory from "../Screens/Workflow/Workflow";
import DivisionMasterList from "./../Screens/Administrator/Division/DivisionList";
import AddDivision from "./../Screens/Administrator/Division/DivisionInput";
import BUMasterList from "./../Screens/Administrator/BU/BuList";
import BSMasterList from "./../Screens/Administrator/BS/BSList";
import AddBu from "./../Screens/Administrator/BU/BUInput";
import AddBS from "./../Screens/Administrator/BS/BSInput";
import CostCenterList from "../Screens/Administrator/CostCenter/CostCenterList";
import AddCostCenter from "../Screens/Administrator/CostCenter/CostCenterInput";
import IssueDetails from "./../Screens/Appeal/IssueDetails";
import AppealList from "../Screens/Appeal/AppealList";
import ToolCaseRegistration from "../Screens/NewApproach/ToolCaseRegistration";
import AddEvents from "../Screens/NewApproach/AddEvents";
import CFRReport from "../Screens/Report/CFRReport";
import IssueWorkFlow from "../Screens/NewApproach/IssueWorkFlow";
import AddPaymentDetails from "../Screens/Payment/AddPayment";
import POMasterList from "../Screens/Administrator/PO/POMasterList";
import AddPO from "../Screens/Administrator/PO/AddPOMaster";
import RefundList from "../Screens/Refund/Refund";
import AddRefund from "../Screens/Refund/AddRefund";
import Reports from "../Screens/Report/Report";
import MasterReport from "../Screens/Report/MasterReport";
import RefundIssueDetails from "../Screens/Refund/RefundIssueDetails";
import AddRefundEvents from "../Screens/Refund/AddRefundEvents";
import EventReport from "../Screens/Report/EventReport";
import RefundSummaryReport from "../Screens/Report/RefundSummaryReport";
import AddRefundHistory from "../Screens/Refund/AddRefundHistory";
import Error from "../Components/Error/Error404";

export const MasterRoute = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />

    <Route exact path="/" render={() => <Home />} />

    <Route exact path="/userManagement" render={() => <UserManagement />} />
    <Route exact path="/ViewToolCase" render={() => <ToolCaseList />} />
    <Route exact path="/Payment" render={() => <Payment />} />
    <Route exact path="/addUser" render={() => <AddUser />} />
    <Route exact path="/admin" render={() => <Admin />} />
    <Route exact path="/hardcodeMaster" render={() => <HardCodeMasterList />} />
    <Route
      exact
      path="/addHardCodeMaster"
      render={() => <AddHardCodeMaster />}
    />
    <Route exact path="/linkedMasterList" render={() => <LinkedMasterList />} />
    <Route exact path="/companyList" render={() => <CompanyMasterList />} />
    <Route exact path="/addLinkedMaster" render={() => <AddLinkedMaster />} />
    <Route exact path="/addCompany" render={() => <AddCompanyMaster />} />
    <Route exact path="/division" render={() => <DivisionMasterList />} />
    <Route exact path="/addDivision" render={() => <AddDivision />} />
    <Route exact path="/buList" render={() => <BUMasterList />} />
    <Route exact path="/addBU" render={() => <AddBu />} />
    <Route exact path="/bsList" render={() => <BSMasterList />} />
    <Route exact path="/addBS" render={() => <AddBS />} />
    <Route exact path="/poList" render={() => <POMasterList />} />
    <Route exact path="/addPO" render={() => <AddPO />} />
    <Route exact path="/workflowHistory" render={() => <WorkflowHistory />} />
    <Route exact path="/costCenter" render={() => <CostCenterList />} />
    <Route exact path="/addCostCenter" render={() => <AddCostCenter />} />
    <Route exact path="/issueDetails" render={() => <IssueDetails />} />
    <Route exact path="/appealList" render={() => <AppealList />} />
    <Route
      exact
      path="/ToolCaseRegistration"
      render={() => <ToolCaseRegistration />}
    />
    <Route exact path="/addEvents" render={() => <AddEvents />} />
    <Route exact path="/reports" render={() => <Reports />} />
    <Route exact path="/cfrReport" render={() => <CFRReport />} />
    <Route
      exact
      path="/issueWiseCompleteList"
      render={() => <IssueWorkFlow />}
    />
    <Route exact path="/AddRefundHistory" render={() => <AddRefundHistory />} />
    <Route exact path="/addPayment" render={() => <AddPaymentDetails />} />
    <Route exact path="/refundList" render={() => <RefundList />} />
    <Route exact path="/addRefund" render={() => <AddRefund />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/masterReport" render={() => <MasterReport />} />
    <Route exact path="/addRefundEvents" render={() => <AddRefundEvents />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/eventReport" render={() => <EventReport />} />
    <Route
      exact
      path="/refundSummaryReport"
      render={() => <RefundSummaryReport />}
    />
    <Route component={Error} />
  </Switch>
);

export const TaxProfessionalRouteIDT = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />

    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/ViewToolCase" render={() => <ToolCaseList />} />
    <Route exact path="/Payment" render={() => <Payment />} />
    <Route exact path="/workflowHistory" render={() => <WorkflowHistory />} />
    <Route exact path="/issueDetails" render={() => <IssueDetails />} />
    <Route exact path="/appealList" render={() => <AppealList />} />
    <Route
      exact
      path="/ToolCaseRegistration"
      render={() => <ToolCaseRegistration />}
    />
    <Route exact path="/addEvents" render={() => <AddEvents />} />
    <Route exact path="/cfrReport" render={() => <CFRReport />} />
    <Route
      exact
      path="/issueWiseCompleteList"
      render={() => <IssueWorkFlow />}
    />
    <Route exact path="/addPayment" render={() => <AddPaymentDetails />} />
    <Route exact path="/refundList" render={() => <RefundList />} />
    <Route exact path="/addRefund" render={() => <AddRefund />} />
    <Route exact path="/reports" render={() => <Reports />} />
    <Route exact path="/masterReport" render={() => <MasterReport />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/addRefundEvents" render={() => <AddRefundEvents />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/eventReport" render={() => <EventReport />} />
    <Route
      exact
      path="/refundSummaryReport"
      render={() => <RefundSummaryReport />}
    />
    <Route exact path="/AddRefundHistory" render={() => <AddRefundHistory />} />
    <Route component={Error} />
  </Switch>
);

export const TaxManagerRouteIDT = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/ViewToolCase" render={() => <ToolCaseList />} />
    <Route exact path="/Payment" render={() => <Payment />} />
    <Route exact path="/workflowHistory" render={() => <WorkflowHistory />} />
    <Route exact path="/appealList" render={() => <AppealList />} />
    <Route
      exact
      path="/ToolCaseRegistration"
      render={() => <ToolCaseRegistration />}
    />
    <Route exact path="/addEvents" render={() => <AddEvents />} />
    <Route exact path="/issueDetails" render={() => <IssueDetails />} />
    <Route exact path="/cfrReport" render={() => <CFRReport />} />
    <Route
      exact
      path="/issueWiseCompleteList"
      render={() => <IssueWorkFlow />}
    />
    <Route exact path="/addPayment" render={() => <AddPaymentDetails />} />
    <Route exact path="/refundList" render={() => <RefundList />} />
    <Route exact path="/addRefund" render={() => <AddRefund />} />
    <Route exact path="/reports" render={() => <Reports />} />
    <Route exact path="/masterReport" render={() => <MasterReport />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/addRefundEvents" render={() => <AddRefundEvents />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/eventReport" render={() => <EventReport />} />
    <Route
      exact
      path="/refundSummaryReport"
      render={() => <RefundSummaryReport />}
    />{" "}
    <Route exact path="/AddRefundHistory" render={() => <AddRefundHistory />} />
    <Route component={Error} />
  </Switch>
);

export const TaxHeadIDT = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/ViewToolCase" render={() => <ToolCaseList />} />
    <Route exact path="/Payment" render={() => <Payment />} />
    <Route exact path="/workflowHistory" render={() => <WorkflowHistory />} />
    <Route exact path="/appealList" render={() => <AppealList />} />
    <Route
      exact
      path="/ToolCaseRegistration"
      render={() => <ToolCaseRegistration />}
    />
    <Route exact path="/addEvents" render={() => <AddEvents />} />
    <Route exact path="/issueDetails" render={() => <IssueDetails />} />
    <Route exact path="/cfrReport" render={() => <CFRReport />} />
    <Route
      exact
      path="/issueWiseCompleteList"
      render={() => <IssueWorkFlow />}
    />
    <Route exact path="/addPayment" render={() => <AddPaymentDetails />} />
    <Route exact path="/refundList" render={() => <RefundList />} />
    <Route exact path="/addRefund" render={() => <AddRefund />} />
    <Route exact path="/reports" render={() => <Reports />} />
    <Route exact path="/masterReport" render={() => <MasterReport />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/addRefundEvents" render={() => <AddRefundEvents />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/eventReport" render={() => <EventReport />} />
    <Route
      exact
      path="/refundSummaryReport"
      render={() => <RefundSummaryReport />}
    />{" "}
    <Route exact path="/AddRefundHistory" render={() => <AddRefundHistory />} />
    <Route component={Error} />
  </Switch>
);

export const TaxAdminRouteIDT = (
  <Switch>
    <Redirect from="/?Code*" to="/" />
    <Redirect from="/signin-oidc*" to="/" />
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/ViewToolCase" render={() => <ToolCaseList />} />
    <Route exact path="/Payment" render={() => <Payment />} />
    <Route exact path="/workflowHistory" render={() => <WorkflowHistory />} />
    <Route exact path="/appealList" render={() => <AppealList />} />
    <Route
      exact
      path="/ToolCaseRegistration"
      render={() => <ToolCaseRegistration />}
    />
    <Route exact path="/addEvents" render={() => <AddEvents />} />
    <Route exact path="/issueDetails" render={() => <IssueDetails />} />
    <Route exact path="/cfrReport" render={() => <CFRReport />} />
    <Route
      exact
      path="/issueWiseCompleteList"
      render={() => <IssueWorkFlow />}
    />
    <Route exact path="/addPayment" render={() => <AddPaymentDetails />} />
    <Route exact path="/refundList" render={() => <RefundList />} />
    <Route exact path="/addRefund" render={() => <AddRefund />} />
    <Route exact path="/reports" render={() => <Reports />} />
    <Route exact path="/masterReport" render={() => <MasterReport />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/addRefundEvents" render={() => <AddRefundEvents />} />
    <Route
      exact
      path="/refundIssueDetails"
      render={() => <RefundIssueDetails />}
    />
    <Route exact path="/eventReport" render={() => <EventReport />} />\{" "}
    <Route
      exact
      path="/refundSummaryReport"
      render={() => <RefundSummaryReport />}
    />{" "}
    <Route exact path="/AddRefundHistory" render={() => <AddRefundHistory />} />
    <Route component={Error} />
  </Switch>
);
