import { combineReducers } from "redux";

let tokenInitialState = {
  token: "",
};
const saveTokenReducer = (state = tokenInitialState, action) => {
  switch (action.type) {
    case "saveAccessToken": {
      return {
        token: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let userDataInitialState = {
  userData: {
    gid: "",
    roleId: 0,
    roleName: "",
    userEmail: "",
    userFirstName: "",
    userLastName: "",
    userId: 0,
  },
};
const storeUserDetailsReducer = (
  state = userDataInitialState,
  { type, payLoad }
) => {
  switch (type) {
    case "storeUserData": {
      return {
        userData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};
let userInitialState = {
  gid: "",
};
const authUserReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "myIDLogin": {
      return {
        gid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let isUpdateResponse = { response: 0 };
const updateResponseReducer = (state = isUpdateResponse, action) => {
  switch (action.type) {
    case "updatePotential": {
      return { response: action.payLoad + state.response };
    }
    case "updateBudget": {
      return { response: action.payLoad + state.response };
    }
    case "updateManualIndirect": {
      return { response: action.payLoad + state.response };
    }
    default:
      return state;
  }
};

let vatcstIdState = {
  id: 0,
};
const saveVatCstIdReducer = (state = vatcstIdState, action) => {
  switch (action.type) {
    case "vatcstId": {
      return {
        id: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

let assesmentStatusValue = {
  value: 0,
};
const saveAssesmentStatusValueReducer = (
  state = assesmentStatusValue,
  action
) => {
  switch (action.type) {
    case "assesmentStatusValue": {
      return {
        value: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

let initialIssueDetails = {
  issueData: {
    issueId: 0,
    issueInvolvedId: 0,
    briefDescId: 0,
    issueRemarks: "",
    issueName: "",
    toolcaseId: 0,
    uploadId: 0,
    subModuleId: 0,
    natureOfTax: 0,
    status: "",
    statusId: 0,
    caseOriginId: 0,
    currentStageId: 0,
  },
};
const storeIssueDetailsReducer = (
  state = initialIssueDetails,
  { type, payLoad }
) => {
  switch (type) {
    case "storeIssueData": {
      return {
        issueData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

let eventToolCaseId = {
  value: 0,
};
const saveEventToolCaseIdReducer = (state = eventToolCaseId, action) => {
  switch (action.type) {
    case "eventToolCaseId": {
      return {
        value: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let dateValidator = {
  date: null,
};
const saveDateValidatorReducer = (state = dateValidator, action) => {
  switch (action.type) {
    case "fromDate": {
      return {
        value: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let metaDataId = {
  value: 0,
};
const saveMetaDataIdReducer = (state = metaDataId, action) => {
  switch (action.type) {
    case "metadataId": {
      return {
        value: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let initialRefundIssueDetails = {
  issueData: {
    issueId: 0,
    metaDataId: 0,
    subModuleId: 0,
    statusName: "",
    subModuleName: "",
  },
};
const storeRefundIssueDetailsReducer = (
  state = initialRefundIssueDetails,
  { type, payLoad }
) => {
  switch (type) {
    case "storeRefundIssueData": {
      return {
        issueData: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

let refundCaseValue = {
  value: false,
};
const saveRefundCaseValue = (state = refundCaseValue, action) => {
  switch (action.type) {
    case "refundCaseValue": {
      return {
        value: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

let toolCaseNo = {
  toolCaseNo: {
    draft: "",
    approved: "",
  },
};
const saveToolCaseNo = (state = toolCaseNo, { type, payLoad }) => {
  switch (type) {
    case "toolCaseNo": {
      return {
        toolCaseNo: {
          ...payLoad,
        },
      };
    }
    default: {
      return state;
    }
  }
};

let isRefundCaseClosed = {
  value: false,
};
const saveRefundCaseStatus = (state = isRefundCaseClosed, action) => {
  switch (action.type) {
    case "isRefundCaseClosed": {
      return {
        value: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let bearerToken = {
  token: "",
};
const saveBearerTokenReducer = (state = bearerToken, action) => {
  switch (action.type) {
    case "saveBearerToken": {
      return {
        bearerToken: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};
let uid = {
  token: "",
};
const saveUIDReducer = (state = uid, action) => {
  switch (action.type) {
    case "saveUID": {
      return {
        uid: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

const storeMenuItems = [];
const storeLandingMenuItemsReducer = (state = storeMenuItems, action) => {
  switch (action.type) {
    case "saveLandingMenuItems": {
      return {
        menuItems: action.payLoad,
      };
    }
    default: {
      return state;
    }
  }
};

export const masterReducer = combineReducers({
  saveTokenReducer: saveTokenReducer,
  storeUserDetailsReducer: storeUserDetailsReducer,
  authUserReducer: authUserReducer,
  updateResponseReducer: updateResponseReducer,
  saveVatCstIdReducer: saveVatCstIdReducer,
  saveAssesmentStatusValueReducer: saveAssesmentStatusValueReducer,
  storeIssueDetailsReducer: storeIssueDetailsReducer,
  saveEventToolCaseIdReducer: saveEventToolCaseIdReducer,
  saveDateValidatorReducer: saveDateValidatorReducer,
  saveMetaDataIdReducer: saveMetaDataIdReducer,
  storeRefundIssueDetailsReducer: storeRefundIssueDetailsReducer,
  saveRefundCaseValue: saveRefundCaseValue,
  saveToolCaseNo: saveToolCaseNo,
  saveRefundCaseStatus: saveRefundCaseStatus,
  saveBearerTokenReducer: saveBearerTokenReducer,
  saveUIDReducer: saveUIDReducer,
  storeLandingMenuItemsReducer: storeLandingMenuItemsReducer,
});

