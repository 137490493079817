import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getCompanyList } from "../../../Redux/API/api_userManagement";
import {
  addBSMaster,
  editBSMaster,
  getBSMasterList,
  getBUMasterList,
} from "../../../Redux/API/api_master";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddBS() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Business Segment");
  const [data, setData] = useState([]);
  const [bsId, setDBsId] = useState(0);
  const [buCode, setBuCode] = useState([]);
  const [buCodeValue, setBuCodeValue] = useState("");
  const [bsName, setBsName] = useState("");
  const [bsNameHelper, setBsNameHelper] = useState("");
  const [bsCode, setBsCode] = useState("");
  const [bsCodeHelper, setBsCodeHelper] = useState("");
  const [statusValue, setStatusValue] = useState(true);
  let [isLoader, setIsLoader] = useState(false);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/bsList";
    }
  };

  //! Dropdown functions
  const handleChangeBUCode = (e) => {
    setBuCodeValue(e.target.value);
  };
  const handleChangebsCode = (e) => {
    setBsCode(e.target.value);
    let valid = /^[a-zA-Z0-9 _-]*$/.test(e.target.value);
    if (!valid) {
      setBsCodeHelper("Please enter valid values.");
    } else if (e.target.value.length < 1) {
      setBsCodeHelper("Minimum 2 characters required");
    } else {
      setBsCodeHelper("");
    }
  };
  const handleChangeBsName = (e) => {
    setBsName(e.target.value);
    if (e.target.value.length === 0) {
      setBsNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setBsNameHelper("Length cannot exceed more than 200.");
    } else {
      setBsNameHelper("");
    }
  };
  const handleChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };
  //! Dropdown Get Methods

  function getBU() {
    getBUMasterList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setBuCode(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  var formdata = {
    bsCode: bsCode,
    bsName: bsName,
    buId: buCodeValue,
    status: statusValue,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    bsId: bsId,
    bsCode: bsCode,
    bsName: bsName,
    buId: buCodeValue,
    status: statusValue,
    modifiedBy: userDetails.userId,
  };

  //! Post Methods
  function addBSMasterData() {
    setIsLoader(true);
    addBSMaster(formdata,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Business Segment Added Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Business Segment Already Exists.", "warning");
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Business Segment.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  function editBSMasterData() {
    setIsLoader(true);
    editBSMaster(formdataEdit,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("Business Segment Edited Successfully.", "success");
          setIsLoader(false);
          //reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("Business Segment Already Exists.", "warning");
          //reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Editing Business Segment.", "error");
          //reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  //! Get Method - Master data
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");

  function getBSList() {
    setIsLoader(true);
    getBSMasterList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,atobSecure(encryptedId))
      .then((response) => {
        setBsCode(response[0].bsCode);
        setBsName(response[0].bsName);
        setBuCodeValue(response[0].buId);
        setStatusValue(response[0].status);
        setDBsId(response[0].bsId);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (siteToken) {
      getBU();
    }
    if (encryptedId) {
      setPageHeader("Edit Business Segment");
      getBSList();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <div className="box-shadow">
        <Grid
          container
          spacing={2}
          direction="row"
          className="upload-block pt-1">
          <Grid item xs={12} md={4} className="">
            <TextField
              label="Business Segment Code"
              id="businessSegmentCode"
              name="businessSegmentCode"
              className=""
              value={bsCode}
              onChange={handleChangebsCode}
              helperText={bsCodeHelper}
              fullWidth
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={4} className="">
            <TextField
              label="Business Segment Name"
              id="businessSegmentName"
              name="businessSegmentName"
              className=""
              fullWidth
              variant="filled"
              value={bsName}
              helperText={bsNameHelper}
              inputProps={{ maxLength: 100 }}
              onChange={handleChangeBsName}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Business Unit Code"
              id="masterKey"
              name="Master Key"
              className=""
              select
              value={buCodeValue}
              onChange={handleChangeBUCode}
              fullWidth
              variant="filled">
              {buCode.map((item) => (
                <MenuItem key={item.buId} value={item.buId}>
                  {item.buCode}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} className="">
            <TextField
              select
              label="Status"
              id="status"
              name="Status"
              className=""
              fullWidth
              value={statusValue}
              onChange={handleChangeStatus}
              variant="filled">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="upload-block d-flex jc-center pb-2">
          {encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={editBSMasterData}
                disabled={!bsCode || !bsName}
                fullWidth>
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {!encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={addBSMasterData}
                disabled={!bsCode || !bsName || !buCodeValue}
                fullWidth>
                Submit
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={1} className="align-center width-100">
            <Link to={"/bsList"}>
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                fullWidth>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
        {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      </div>

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
