import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import { AdminMenu } from "../../Utilities/Utility";

export default function Admin() {
  const [routes, setRoutes] = useState([]);
  function getMenus() {
    let filtered = AdminMenu.filter((c) => c.roleId === 1);
    setRoutes(filtered);
  }

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <div className="potts-master-block">
      <div className="bg-landing-page">
        <Container maxWidth="lg">
          <Grid container className="homepage-container">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="landing-page-menu">
              <Grid container spacing={2}>
                {routes.map((item, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Link to={item.menuLink} key={index}>
                      <div className="menu-item-container">
                        <div className="menu-item-text-block">
                          <span className="align-center ml-1">
                            {item.menuName}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
