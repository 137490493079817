import { baseApiURL } from "../../Utilities/Utility";

//!Get methods
export async function GetReferenceToolCaseList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetRefundRefrenceToolCase",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundSubModuleList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetIdtRefundSubModuleList",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetNatureOfRefundList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetNatureOfRefundList?subModuleID=" + subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundRegNoList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  stateId,
  companyId,
  regId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetRegistrationNo?stateId=" +
        stateId +
        "&companyId=" +
        companyId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetRefundToolCaseList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  caseType,
  refundCaseNo,
  locationId,
  subModuleId,
  refundCaseStatus,
  companyID
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetIdtRefundCaseList?refundCaseType=" +
        caseType +
        "&refundCaseNumber=" +
        refundCaseNo +
        "&locationId=" +
        locationId +
        "&currentStageId=" +
        subModuleId +
        "&refundCaseStatus=" +
        refundCaseStatus +
        "&companyID=" +
        companyID,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetRefundMetaDataDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundMetaDataId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetIdtRefundMetaData?refundMetaDataID=" +
        refundMetaDataId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetRefundSectionData(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundCaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetRefundSectionDataList?refundCaseID=" +
        refundCaseId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundEventList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetEventTypesList?subModuleID=" + subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundEventGridList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundCaseID,
  eventId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetRefundEventsList?refundCaseID=" +
        refundCaseID +
        "&evntID=" +
        eventId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundIssueData(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundCaseId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetRefundUploadedIssesList?refundCaseID=" +
        refundCaseId +
        "&subModuleID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetNextAppealStageList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetRefundNextAppealStageList?issueID=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRemandAuthorityList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetRefundRemandAuthorityList?issueID=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundIssueDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetRefundIssueLevelDetails?issueID=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function GetRefundSectionDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetSectionUnderDocumentIssued?refundCaseID=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//! Post methods
export async function SaveRefundMetaData(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/SaveRefundData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function InsertRefundSection(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundId,
  userId,
  subModuleId
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/RefundSectionUpload?refundCaseID=" +
        refundId +
        "&userID=" +
        userId +
        "&subModuleID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function InsertRefundEventDetails(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/SaveOrUpdateRefundEventDetails",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export function ChangeStatusOfRefundCase(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Refund/SendForApprovalForRefund?refundCaseID=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function approveRefundCaseData(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id,
  userId
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Refund/ApproveRefundCase?refundCaseID=" +
      id +
      "&userID=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function rejectRefundCaseData(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id,
  reason,
  userId
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Refund/RejectRefundCaseDetails?refundCaseID=" +
      id +
      "&RejectionReason=" +
      reason +
      "&userID=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export async function updateStage(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundCaseId,
  userId
) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/ChangeCurrentStage?refundCaseID=" +
        refundCaseId +
        "&userID=" +
        userId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function CheckRefundModuleProceedStatus(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundCaseId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetMoveStageButton?refundCaseID=" +
        refundCaseId +
        "&currentStageID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function InsertRefundIssues(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  refundId,
  userId,
  subModuleId
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/RefundIssueUpload?refundCaseID=" +
        refundId +
        "&userID=" +
        userId +
        "&subModuleID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//! Approve / Reject Methods
export function ApproveRefundCase(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id,
  userId
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Refund/ApproveRefundCase?refundCaseID" +
      id +
      "&userID=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function RejectRefundCase(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id,
  reason,
  userId
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/RejectIdtEnquiryDetails?ToolCaseId=" +
      id +
      "&RejectionReason=" +
      reason +
      "&UserId=" +
      userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export async function addRefundIssueLevelDetails(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/RefundIssueLevelDetailsChanges",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function DeleteRefundEventById(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "delete",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/DeleteRefundEvent?eventID=" + id,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
