import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Grid,
    MenuItem,
    TextField,
    Button,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    IDTModuleId,
    baseApiURL,
    tableIconsInputScreen,
} from "../../Utilities/Utility";
import { getEnquiryStatusList } from "../../Redux/API/api_createEnquiry";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import {
    GetFormAdjustmentList,
    GetLiabilityAcceptedList,
    InsertFormAdjustment,
    RunFormAdjustmentCalculation,
    addIssueLevelDetails,
    getAppealStageList,
    getIssueLevelAllData,
    getRemandAuthorityList,
} from "../../Redux/API/api_events";
import {
    getAppealPreferredList,
    getOrderSummaryList,
    getPPRList,
} from "../../Redux/API/api_scn";
import FormAdjustmentTemplate from "../../assets/template/UploadTemplate/Form_adjustment_upload_template.xlsx";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
    getFilteredUploadedFormAdjustment,
    getLastUploadedFormAdjustment,
} from "../../Redux/API/api_appeal";
import { storeToolCaseNo } from "../../Redux/Actions/actions";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: `90%`,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        display: `flex`,
        justifyContent: `flex-start`,
        margin: `0`
    },
    sectionHeader: {
        marginTop: `1em`,
        marginBottom: `0.8em`,
        // borderBottom: `2px solid #00BCBC`,
    },
    sectionHeader2: {
        marginTop: `0.8em`,
        marginBottom: `0.8em`,
        //borderBottom: `2px solid #00BCBC`,
    },
    pageheader: {
        display: `flex`,
        justifyContent: `center`,
        width: `100%`,
        margin: `0`
    },
    rejectDiv: {
        color: `red`,
    },
    errorMsg: {
        color: `red !important`,
        marginTop: `4px`,
        marginBottom: `4px`,
        borderBottom: `2px solid red`,
    },
    mandatoryNote: {
        color: `red !important`,
        fontSize: `14import { baseApiURL } from './../../Utilities/Utility';
px`,
        fontStyle: `italic`,
        display: `flex`,
        justifyContent: `flex-end`,
        marginBottom: `0`,
    },
    subGrid: {
        boxShadow: `1px 2px 5px 3px #ccc`,
        marginTop: `1rem`,
        paddingBottom: `8px`,
        marginBottom: `1rem`,
        borderRadius: `8px`,
    },
    aTagTemplate: {
        color: `#00BCBC`,
        fontStyle: `italic`,
        borderBottom: `1px solid #00BCBC`,
        fontWeight: `bold`,
        width: `fit-content`,
        padding: `0`,
        margin: `0`,
    },
    errorTag: {
        color: `#FF5454`,
        fontStyle: `italic`,
        borderBottom: `1px solid #FF5454`,
        fontWeight: `bold`,
        width: `fit-content`,
        padding: `0`,
        margin: `0`,
    },
    alignSelf: {
        alignSelf: `center`,
    },
    p_8px: {
        paddingLeft: `8px`,
    },
    p_14px: {
        paddingLeft: `14px`,
    },
    mb_0: {
        marginBottom: `0`,
    },
}));

export default function IssueDetails() {
    const classes = useStyles();
   
    var encryptedId = getURLParameter("id");
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const bearerToken = useSelector(
      (state) => state.saveBearerTokenReducer.bearerToken
    );
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const userDetails = useSelector(
      (state) => state.storeUserDetailsReducer.userData
    );
    const toolCaseDetails = useSelector(
      (state) => state.saveToolCaseNo.toolCaseNo
    );
    const issueData = useSelector(
      (state) => state.storeIssueDetailsReducer.issueData
    );
    const [draftNo, setDraftNo] = useState("");
    const [toolCaseNo, setToolCaseNo] = useState("");
    const [orderNo, setOrderNo] = useState("");
    const [ppr, setPPR] = useState([]);
    const [pprValue, setPPRValue] = useState("");
    const [pprReasoning, setPPRReasoning] = useState("");
    const [orderSummary, setOrderSummary] = useState([]);
    const [orderSummaryValue, setOrderSummaryValue] = useState("");
    const [status, setStatus] = useState([]);
    const [statusValue, setStatusValue] = useState("");
    const [statusName, setStatusName] = useState("");
    const [appealPref, setAppealPref] = useState([]);
    const [appealPrefValue, setAppealPrefValue] = useState("");
    const [appealStage, setAppealStage] = useState([]);
    const [appealStageValue, setAppealStageValue] = useState("");
    const [remandAuthority, setRemandAuthority] = useState([]);
    const [remandAuthorityValue, setRemandAuthorityValue] = useState("");
    const [appealStageName, setAppealStageName] = useState("");
    const [liabilityAccepted, setLiabilityAccepted] = useState([]);
    const [liabilityAcceptedValue, setLiabilityAcceptedValue] = useState("");
    const [errorFileName, setErrorFileName] = useState("");
    const [selectedFileFormAdjustments, setSelectedFileFormAdjustments] =
      useState(null);
    const [inputFileFormAdjustments, setInputFileFormAdjustments] = useState(
      Date.now
    );
    const [formAdjList, setFormAdjList] = useState([]);
    const [latestExcelLInk, setLatestExcelLink] = useState("");
    const [quarterEndDate, setQuarterEndDateDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDateDate] = useState(null);
    const [formAdjTitle, setFormAdjTitle] = useState("Form Adjustment Data");
    //? Snackbar & Loaders
    let [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
      setSnackMessage(text);
      setAlertType(type);
      setOpen(true);
    };

    const handleClose = (e) => {
      setOpen(false);
      if (!encryptedId && alertType === "success") {
        window.location.href =
          "/ToolCaseRegistration?id=" + btoaSecure(issueData.toolcaseId);
      } else if (encryptedId && alertType === "success") {
        window.location.reload();
      }
    };

    const handleChangeOrderNo = (event) => {
      setOrderNo(event.target.value);
    };
    const handleChangePPR = (event) => {
      setPPRValue(event.target.value);
    };
    const handleChangePPRReasoning = (event) => {
      setPPRReasoning(event.target.value);
    };
    const handleChangeOrderSummary = (event) => {
      setOrderSummaryValue(event.target.value);
    };
    const handleChangeStatus = (event) => {
      setAppealStageValue("");
      setRemandAuthorityValue("");
      setStatusValue(event.target.value);
      var statusName = status.filter(
        (c) => c.hardCodeId === event.target.value
      );
      setStatusName(statusName[0].name);
    };
    const handleChangeAppealPref = (event) => {
      setAppealPrefValue(event.target.value);
    };
    const handleChangeAppealStage = (event) => {
      setAppealStageValue(event.target.value);
    };
    const handleChangeRemandAuthority = (event) => {
      setRemandAuthorityValue(event.target.value);
    };
    const handleChangeLiabilityAccepted = (event) => {
      setLiabilityAcceptedValue(event.target.value);
    };
    const handleChangeFormAdjustmentsFile = (event) => {
      setSelectedFileFormAdjustments(event.target.files[0]);
    };
    const handleQuarterEndDate = (date) => {
      setQuarterEndDateDate(date.toISOString());
    };

    const handleStartDate = (date) => {
      setStartDate(date.toISOString());
    };
    const handleEndDate = (date) => {
      setEndDateDate(date.toISOString());
    };

    //* Issue Grid Columns
    const liabilityColumns = [
      { title: "Id", field: "id", hidden: true },
      { title: "ToolCase Id", field: "toolCaseId", hidden: true },
      {
        title: "Form Adj Ref Id",
        field: "formAdjustmentRefereceId",
        hidden: true,
      },
      { title: "Sub-Module Id", field: "subModuleId", hidden: true },
      { title: "Upload Id", field: "uploadId", hidden: true },
      { title: "Invoice No", field: "invoiceNo" },
      {
        title: "Invoice Date",
        field: "invoiceDateString",
      },
      { title: "Gross Tax Liability", field: "grossTaxLiability" },
      {
        title: "Refund Adjusted Against CST Liability",
        field: "vatrefundAdjustedAgainstCst",
      },
      { title: "Net Tax Liability As Per Order", field: "netTaxLiability" },
      // {
      //   title: "Ecess, SHEcess and Others ",
      //   field: "tax",
      // },
      { title: "Interest As Per Order", field: "interest" },
      { title: "Penalty As Per Order", field: "penalty" },
      {
        title: "Total Disputed Amount As Per Order",
        field: "totalDisputedAmount",
      },
      // {
      //   title: "BU",
      //   field: "penalty",
      // },
      {
        title: "Profit Center",
        field: "profitCentresName",
        hidden: true,
        export: true,
      },
      {
        title: "Reduction In Liability Due To Receipt Of Forms",
        field: "liabilityReduction",
      },
      {
        title: "Net Tax Liability(Excluding Interest And Penalty)",
        field: "calculatedNetTaxLiability",
      },
      {
        title: "Interest Calculation-Start Date(Annual Return Date)",
        field: "interestCalculationQuarterStartDateString",
      },
      {
        title: "Assessment Order Date",
        field: "assessmentOrderDateString",
      },
      {
        title: "Interest Calculation-End Date(Quarter End Date)",
        field: "interestCalculationQuarterEndDateString",
      },
      {
        title: "Interest % Per Month",
        field: "interestPercentagePerMonth",
      },
      {
        title: "Interest Payable-Annual Return Date To Assessment Order Date",
        field: "interestPayableAnnualReturnDateToAssessmentOrderDate",
      },
      {
        title: "Interest Payable-Assessment Order Date To End Date",
        field: "interestPayableAssessmentOrderDateToEndDate",
      },
      {
        title: "Total Interest Payable",
        field: "totalInterestPayable",
      },
      {
        title: "Interest On Pre-Deposits ",
        field: "interestOnPreDeposits",
      },
      {
        title: "Minimum Interest",
        field: "minimumBetweenInterestOnPredepositAndTotalInterestPayable",
      },
      {
        title: "Net Interest Total-Min. Interest",
        field: "netInterestTotal",
      },
      {
        title: "Interest",
        field: "calculatedInterest",
      },
      {
        title: "Penalty",
        field: "calculatedPenalty",
      },
      {
        title: "Total Disputed Amount",
        field: "calculatedTotalDisputedAmount",
      },
    ];

    function getIssueStatus() {
      getEnquiryStatusList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        IDTModuleId,
        issueData.subModuleId
      )
        .then((response) => {
          setStatus(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getPPR() {
      getPPRList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        IDTModuleId,
        issueData.subModuleId
      )
        .then((response) => {
          setPPR(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getOrderSummary() {
      getOrderSummaryList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        IDTModuleId,
        issueData.subModuleId
      )
        .then((response) => {
          setOrderSummary(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getAppealPreferred() {
      getAppealPreferredList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        IDTModuleId,
        issueData.subModuleId
      )
        .then((response) => {
          setAppealPref(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getAllIssueDetails(id, subModuleId) {
      getIssueLevelAllData(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        id,
        subModuleId
      )
        .then((response) => {
          setOrderNo(response.orderNo);
          setPPRValue(response.ppr === 0 ? "" : response.ppr);
          setOrderSummaryValue(
            response.orderSummary === 0 ? "" : response.orderSummary
          );
          setStatusValue(
            response.issueLevelStatus === 0 ? "" : response.issueLevelStatus
          );
          setAppealPrefValue(
            response.appealPreferred === 0 ? "" : response.appealPreferred
          );
          setAppealStageName(response.appealStageName);
          setRemandAuthorityValue(
            response.remandAuthority === 0 ? "" : response.remandAuthority
          );
          setAppealStageValue(
            response.higherAppealStage === 0 ? "" : response.higherAppealStage
          );
          setLiabilityAcceptedValue(
            response.liabilityAccepted === 0 ? "" : response.liabilityAccepted
          );
          setPPRReasoning(response.pprReasoning);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getRemandAuthority(
      moduleId,
      subModuleId,
      originId,
      currentStageId,
      issueID
    ) {
      getRemandAuthorityList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        moduleId,
        subModuleId,
        originId,
        currentStageId,
        issueID
      )
        .then((response) => {
          setRemandAuthority(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getAppealStage(moduleId, subModuleId, statusId, currentStageId) {
      getAppealStageList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        moduleId,
        subModuleId,
        statusId,
        currentStageId
      )
        .then((response) => {
          setAppealStage(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getLiabilityAccepted() {
      GetLiabilityAcceptedList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        IDTModuleId,
        issueData.subModuleId
      )
        .then((response) => {
          setLiabilityAccepted(response);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    //* Get Form Adjustments Data

    function getFormAdjustment() {
      setIsLoader(true);
      GetFormAdjustmentList(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        issueData.issueId
      )
        .then((response) => {
          setFormAdjList(response);
          setFormAdjTitle("Form Adjustment Data");
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // ! Encrypt Decrypt Methods

    function btoaSecure(value) {
      const { btoa } = require("abab");
      let currDate = new Date().toDateString();
      let valueModified = value + " " + currDate;
      let valueSecured = btoa(btoa(valueModified));
      return valueSecured;
    }
    function atobSecure(value) {
      const { atob } = require("abab");
      let arrValue = atob(atob(value)).split(" ");
      let valueActual = arrValue[0];
      return valueActual;
    }
    function getURLParameter(sParam) {
      var sPageURL = window.location.search.substring(1);
      var sURLVariables = sPageURL.split("&");
      for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split("=");
        var value = "";
        for (var j = 0; j < sParameterName.length; j++) {
          if (sParameterName[j] == sParam) {
            for (var k = j + 1; k < sParameterName.length; k++) {
              value = value + sParameterName[k];
            }
            break;
          }
          // return sParameterName[1];
        }
        return value;
      }
    }
    //let encryptedId = getURLParameter("id");
    function GetSaveButton(rowData) {
      return (
        <Tooltip
          title={
            latestExcelLInk === ""
              ? "No File To Download"
              : "Download last Uploaded File"
          }
          placement="top">
          <span>
            <IconButton
              href={latestExcelLInk}
              disabled={latestExcelLInk === "" ? true : false}>
              <CloudDownloadIcon fontSize="small" className="attachFileIcon" />
            </IconButton>
          </span>
        </Tooltip>
      );
    }
    function getLatestFormsAdjUploaded() {
      getLastUploadedFormAdjustment(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        issueData.issueId
      )
        .then((response) => {
          if (response.status === true) {
            setLatestExcelLink(response.result);
          }
          if (response.status === false) {
            setLatestExcelLink("");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    function getFilteredFormsAdjUploaded() {
      setIsLoader(true);
      getFilteredUploadedFormAdjustment(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        issueData.issueId,
        startDate,
        endDate
      )
        .then((response) => {
          setFormAdjList(response.result);
          setFormAdjTitle("Form Adjustment History Data");
          setIsLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    //! Post Method

    var formdata = {
      id: issueData.issueId,
      toolCaseID: issueData.toolcaseId,
      subModuleId: issueData.subModuleId,
      orderNo: orderNo,
      ppr: pprValue ? pprValue : 0,
      orderSummary: orderSummaryValue ? orderSummaryValue : 0,
      issueLevelStatus: statusValue,
      appealPreferred: appealPrefValue ? appealPrefValue : 0,
      appealStage: appealStageValue ? appealStageValue : 0,
      remandAuthority: remandAuthorityValue ? remandAuthorityValue : 0,
      pprReasoning: pprReasoning,
      liabilityAccepted: liabilityAcceptedValue ? liabilityAcceptedValue : 0,
      modifiedBy: userDetails.userId,
    };
    function addIssueDetails() {
      setIsLoader(true);
      addIssueLevelDetails(
        formdata,
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId
      )
        .then((response) => {
          if (response.status == 1) {
            handleSnackOpen("Issue Details Added Successfully.", "success");
            setIsLoader(false);
          } else if (response.status === 4) {
            setIsLoader(false);
            handleSnackOpen(response.result, "error");
          } else {
            setIsLoader(false);
            handleSnackOpen("Error While Adding Issue Details.", "error");
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    function submitFormAdjustments() {
      setIsLoader(true);
      var formdataPart1 = new FormData();
      formdataPart1.append("SubModuleId", issueData.subModuleId);
      formdataPart1.append("ToolCaseId", issueData.toolcaseId);
      formdataPart1.append("UploadId", issueData.uploadId);
      formdataPart1.append("IssueId", issueData.issueInvolvedId);
      formdataPart1.append("IssueDescId", issueData.briefDescId);
      formdataPart1.append("IssueRemarks", issueData.issueRemarks);
      formdataPart1.append("UserId", userDetails.userId);
      formdataPart1.append("ExcelFile", selectedFileFormAdjustments);
      InsertFormAdjustment(
        formdataPart1,
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId
      )
        .then((response) => {
          if (response.status === 1) {
            handleSnackOpen(
              "Form Adjustments Details Added Successfully.",
              "success"
            );
            setIsLoader(false);
            setErrorFileName("");
          } else if (response.status === 4) {
            setErrorFileName(response.result);
            setIsLoader(false);
            setInputFileFormAdjustments(Date.now);
            setSelectedFileFormAdjustments(null);
            handleSnackOpen("Error While Uploading Form Adjustments.", "error");
          } else {
            setIsLoader(false);
            setErrorFileName("");
            handleSnackOpen(response.result, "error");
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.log("error", error);
        });
    }

    function runFormAdjCalculation() {
      setIsLoader(true);
      RunFormAdjustmentCalculation(
        siteToken,
        bearerToken,
        userDetails.emailId,
        userDetails.gid,
        userDetails.roleId,
        userDetails.roleName,
        uId,
        quarterEndDate
      )
        .then((response) => {
          if (response === true) {
            handleSnackOpen("Calculation Ran Successfully.", "success");
            setIsLoader(false);
          } else {
            setIsLoader(false);
            handleSnackOpen("Error While Running Calculation.", "error");
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.log("error", error);
        });
    }

    useEffect(() => {
        getIssueStatus();
        setStatusName(issueData.status);
        setToolCaseNo(toolCaseDetails.approved);
        setDraftNo(toolCaseDetails.draft);
        if (
            encryptedId &&
            (issueData.subModuleId === 3 ||
                issueData.subModuleId === 5 ||
                issueData.subModuleId === 2 ||
                issueData.subModuleId === 4)
        ) {
            getPPR();
            getOrderSummary();
            getAppealPreferred();
        }
        getLiabilityAccepted();
        getAllIssueDetails(atobSecure(encryptedId), issueData.subModuleId);
        getRemandAuthority(
            IDTModuleId,
            issueData.subModuleId,
            issueData.caseOriginId,
            issueData.currentStageId,
            issueData.issueId
        );
        getAppealStage(
            IDTModuleId,
            issueData.subModuleId,
            issueData.statusId,
            issueData.currentStageId
        );
        if (issueData.subModuleId === 5 && issueData.natureOfTax === "CST") {
            getFormAdjustment();
            getLatestFormsAdjUploaded();
        }
    }, [encryptedId]);

    return (
        <div className="">
            <Grid container spacing={2} className="m-0">
                <Grid item xs={12} md={6} className="d-flex jc-flex-start">
                    <h4 className={classes.pageheader}>
                        Tool Case No:- {toolCaseNo ? toolCaseNo : draftNo}
                    </h4><br />
                    <h4 className={classes.pageheader}>
                        Issue Description:- {issueData.issueDescName}
                    </h4>
                </Grid>
                <Grid item xs={12} md={6} className="d-flex jc-flex-end">
                    {statusName === "Remanded" ? (
                        <p className={classes.mandatoryNote}>
                            Remand Authority Is Mandatory
                        </p>
                    ) : (
                        <></>
                    )}
                    {statusName === "Higher Appeal" ? (
                        <p className={classes.mandatoryNote}>
                            Next Appeal Stage Is Mandatory
                        </p>
                    ) : (
                        <></>
                    )}
                    {orderNo && statusName === "Open" ? (
                        <p className={classes.mandatoryNote}>
                            Order Number Received, Change Issue Status
                        </p>
                    ) : (
                        <></>
                    )}
                    {!orderNo && statusName !== "Open" ? (
                        <p className={classes.mandatoryNote}>
                            Issue Status Changed, Fill Order Number
                        </p>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
            {issueData.subModuleId === 3 ||
                issueData.subModuleId === 5 ||
                issueData.subModuleId === 2 ? (
                <Grid container spacing={2} className={classes.subGrid}>
                    <Grid item xs={12}>
                        <h4 className={classes.header}>Pre-Order Details</h4>
                    </Grid>
                    {issueData.subModuleId === 5 ? (
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Current Appeal Stage"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                disabled={true}
                                variant="filled"
                                value={appealStageName}
                            />
                        </Grid>
                    ) : (
                        <></>
                    )}
                    {issueData.subModuleId === 3 || issueData.subModuleId === 5 ? (
                        <>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="PPR"
                                    id=""
                                    name=""
                                    className=""
                                    fullWidth
                                    variant="filled"
                                    value={pprValue}
                                    onChange={handleChangePPR}
                                    select>
                                    {ppr.map((item) => (
                                        <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="PPR Reasoning"
                                    id=""
                                    name=""
                                    className=""
                                    fullWidth
                                    variant="filled"
                                    value={pprReasoning}
                                    onChange={handleChangePPRReasoning}
                                />
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                    {issueData.subModuleId === 2 ? (
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Liability Accepted"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                variant="filled"
                                value={liabilityAcceptedValue}
                                onChange={handleChangeLiabilityAccepted}
                                select>
                                {liabilityAccepted.map((item) => (
                                    <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            ) : (
                <></>
            )}

            <Grid container spacing={2} className={classes.subGrid}>
                <Grid item xs={12}>
                    <h4 className={classes.header}>Add Order No</h4>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Order Number"
                        required
                        id=""
                        name=""
                        className=""
                        fullWidth
                        variant="filled"
                        value={orderNo}
                        onChange={handleChangeOrderNo}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.subGrid}>
                <Grid item xs={12}>
                    <h4 className={classes.header}>Issue Level Details</h4>
                </Grid>
                {issueData.subModuleId === 3 ||
                    issueData.subModuleId === 5 ||
                    issueData.subModuleId === 2 ||
                    issueData.subModuleId === 4 ? (
                    <>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Order Summary"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                select
                                required={statusName !== "Open" ? true : false}
                                variant="filled"
                                value={orderSummaryValue}
                                onChange={handleChangeOrderSummary}>
                                {orderSummary.map((item) => (
                                    <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Appeal Preferred"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                select
                                required={statusName !== "Open" ? true : false}
                                variant="filled"
                                value={appealPrefValue}
                                onChange={handleChangeAppealPref}>
                                {appealPref.map((item) => (
                                    <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </>
                ) : (
                    <></>
                )}

                <Grid item xs={12} md={3}>
                    <TextField
                        label="Status"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        variant="filled"
                        value={statusValue}
                        select
                        disabled={
                            issueData.status !== "Open" ||
                                (issueData.status === "" && statusName === "Open")
                                ? true
                                : false
                        }
                        onChange={handleChangeStatus}>
                        {status.map((item) => (
                            <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {issueData.subModuleId === 5 ? (
                    <>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Next Appeal Stage"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                select
                                disabled={
                                    statusName === "Closed" ||
                                        statusName === "Remanded" ||
                                        statusName === "Closed-Refund Filed"
                                        ? true
                                        : false
                                }
                                variant="filled"
                                value={appealStageValue}
                                onChange={handleChangeAppealStage}>
                                {appealStage.map((item) => (
                                    <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Remand Authority"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                select
                                disabled={
                                    statusName === "Closed" ||
                                        statusName === "Higher Appeal" ||
                                        statusName === "Closed-Refund Filed"
                                        ? true
                                        : false
                                }
                                variant="filled"
                                value={remandAuthorityValue}
                                onChange={handleChangeRemandAuthority}>
                                {remandAuthority.map((item) => (
                                    <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>

            {issueData.subModuleId === 5 &&
                issueData.natureOfTax === "CST" &&
                issueData.issueName === "FORMS Disallowance (C,E,F,I,H)" ? (
                <Grid container spacing={2} className={classes.subGrid}>
                    <Grid item xs={12}>
                        <h4 className={classes.sectionHeader2}>
                            Form Adjustments Details
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <a href={FormAdjustmentTemplate}>
                            <p className={classes.aTagTemplate}>
                                Click to download Form Adjustments Template
                            </p>
                        </a>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                        className="d-flex jc-flex-start">
                        <Grid item xs={12} md={8}>
                            <TextField
                                label="Upload Form Adjustments"
                                id="businessUnitName"
                                name="businessUnitName"
                                className=""
                                fullWidth
                                type="file"
                                key={inputFileFormAdjustments}
                                onChange={handleChangeFormAdjustmentsFile}
                                variant="filled"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.alignSelf}>
                            <Button
                                type="submit"
                                fullWidth
                                className="pt-button--primary"
                                disabled={!selectedFileFormAdjustments}
                                onClick={submitFormAdjustments}
                                id="SupplierLoginBtn">
                                Upload
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        spacing={3}
                        className="d-flex jc-flex-end">
                        <Grid item xs={12} md={8} className="">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="fromPeriod"
                                    className="datepicker"
                                    label="Quarter End Date"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={quarterEndDate}
                                    onChange={handleQuarterEndDate}
                                    disablePast
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputProps={{ readOnly: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.alignSelf}>
                            <Button
                                type="submit"
                                fullWidth
                                className="pt-button--primary"
                                disabled={!quarterEndDate}
                                onClick={runFormAdjCalculation}
                                id="SupplierLoginBtn">
                                Calculate
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.p_8px}>
                        <Grid xs={12} className={classes.p_14px}>
                            <h4 className={classes.mb_0}>Form Adjustment History</h4>
                        </Grid>
                        <Grid item xs={12} md={4} className="">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="fromPeriod"
                                    className="datepicker"
                                    label="Start Date"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={startDate}
                                    onChange={handleStartDate}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputProps={{ readOnly: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={4} className="">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="fromPeriod"
                                    className="datepicker"
                                    label="End Date"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={endDate}
                                    onChange={handleEndDate}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputProps={{ readOnly: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={1} className={classes.alignSelf}>
                            <Button
                                type="submit"
                                fullWidth
                                className="pt-button--primary"
                                disabled={!startDate || !endDate}
                                onClick={getFilteredFormsAdjUploaded}
                                id="SupplierLoginBtn">
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={1} className={classes.alignSelf}>
                            <Button
                                type="submit"
                                fullWidth
                                className="pt-button--secondary"
                                disabled={!startDate || !endDate}
                                onClick={getFormAdjustment}
                                id="SupplierLoginBtn">
                                Reset
                            </Button>
                        </Grid>
                    </Grid>

                    {errorFileName ? (
                        <Grid item xs={12} className="d-flex jc-center">
                            <a
                                href={
                                    baseApiURL + "/Common/DownloadLog?fileName=" + errorFileName
                                }>
                                <p className={classes.errorTag}>
                                    Click to download Error Log File
                                </p>
                            </a>
                        </Grid>
                    ) : (
                        <></>
                    )}
                    <Grid item xs={12} className="FormAdjGrid">
                        <MaterialTable
                            title={formAdjTitle}
                            columns={liabilityColumns}
                            data={formAdjList}
                            icons={tableIconsInputScreen}
                            options={{
                                search: false,
                                emptyRowsWhenPaging: false,
                                exportButton: {
                                    csv: true,
                                    pdf: false,
                                },
                                exportAllData: true,
                            }}
                            actions={[
                                {
                                    icon: GetSaveButton,
                                    isFreeAction: true,
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
            <Grid
                container
                item
                xs={12}
                spacing={3}
                className="mt-1 d-flex jc-center">
                {userDetails.roleId === 2 || userDetails.roleId === 1 ? (
                    <Grid item xs={12} md={1} className="align-self">
                        <Button
                            type="submit"
                            fullWidth
                            className="pt-button--primary"
                            disabled={
                                (statusName === "Remanded" && !remandAuthorityValue) ||
                                    (statusName === "Higher Appeal" && !appealStageValue) ||
                                    (orderNo && statusName === "Open") ||
                                    (!orderNo && statusName !== "Open") ||
                                    (!orderSummaryValue &&
                                        statusName !== "Open" &&
                                        issueData.subModuleId !== 1) ||
                                    (!appealPrefValue &&
                                        statusName !== "Open" &&
                                        issueData.subModuleId !== 1)
                                    ? true
                                    : false
                            }
                            onClick={addIssueDetails}
                            id="SupplierLoginBtn">
                            Save
                        </Button>
                    </Grid>
                ) : (
                    <></>
                )}
                <Grid item xs={12} md={1} className="align-self">
                    <Link
                        to={
                            "/ToolCaseRegistration?id=" + btoaSecure(issueData.toolcaseId)
                        }>
                        <Button
                            type="submit"
                            fullWidth
                            className="pt-button--secondary"
                            id="SupplierLoginBtn">
                            Back
                        </Button>
                    </Link>
                </Grid>
            </Grid>

            {isLoader ? <Loader loaderText="Loading...." /> : <></>}
            <>
                <SnackbarCustom
                    open={open}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleClose}
                />
            </>
        </div>
    );
}
