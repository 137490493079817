import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { baseAddressURL } from "../../../Utilities/Utility";
import {
  getUserList,
  getCompanyList,
  getRoleList,
  getDivisionList,
  getBUList,
  getBSList,
  addUserData,
  getDefaultManagerList,
} from "../../../Redux/API/api_userManagement";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./UserManagement.scss";
import Loader from "../../../Components/Loader/Loader";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import { getModuleList } from "../../../Redux/API/api_admin";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  note: {
    fontSize: `12px`,
    color: `#FF5454`,
    margin: `0`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddUser() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add User");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gid, setGID] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameHelper, setFirstNameHelper] = useState("");
  const [lastNameHelper, setLastNameHelper] = useState("");
  const [gidHelper, setGIDHelper] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const [status, setStatus] = useState(true);
  const [moduleId, setModuleId] = useState([]);
  const [moduleValue, setModuleIdValue] = useState("");
  const [role, setRole] = useState([]);
  const [managerValue, setManagerValue] = useState("");
  const [manager, setManager] = useState([]);
  const [roleValue, setRoleValue] = useState("");
  const [userId, setUserId] = useState();
  let [isLoader, setIsLoader] = useState(false);
  const [btnName, setBtnName] = useState("Submit");
  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = baseAddressURL + "/userManagement";
    }
  };

  //division multi select
  const [division, setDivision] = useState([]);
  const [selectedOptionsDivision, setSelectedOptionsDivision] = useState([]);
  let divisionArr = [];
  const [divisionString, setDivisionString] = useState("");
  function onChangeDivision(value, event) {
    let divisionValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      divisionValue = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      divisionValue = division;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      divisionValue = [];
    } else {
      divisionValue = value;
    }
    for (let i = 0; i < divisionValue.length; i++) {
      if (divisionValue[i].id !== undefined)
        divisionArr.push(divisionValue[i].id);
    }
    setDivisionString(divisionArr.toString());
    getBU(divisionArr.toString());
  }

  //company multi select
  const [selectedOptionsCompany, setSelectedOptionsCompany] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  let companyArr = [];
  const [companyString, setCompanyString] = useState("");
  //let companyArr = [];
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChangeCompany(value, event) {
    let companyValue = [];

    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      companyValue = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      companyValue = companyData;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      companyValue = [];
    } else {
      companyValue = value;
    }
    for (let i = 0; i < companyValue.length; i++) {
      if (companyValue[i].id !== undefined) companyArr.push(companyValue[i].id);
    }

    setCompanyString(companyArr.toString());
    getDivision(companyArr.toString());
  }

  //BU multi select
  const [bu, setBU] = useState([]);
  const [selectedOptionsBU, setSelectedOptionsBU] = useState([]);
  let buArr = [];
  const [buString, setBUString] = useState("");
  //let buArr = [];
  function onChangeBU(value, event) {
    let buValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      buValue = bu;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      buValue = [];
    } else {
      buValue = value;
    }
    for (let i = 0; i < buValue.length; i++) {
      if (buValue[i].id !== undefined) buArr.push(buValue[i].id);
    }
    setBUString(buArr.toString());
    getBS(buArr.toString());
  }

  //BS multi select
  const [bs, setBS] = useState([]);
  const [selectedOptionsBS, setSelectedOptionsBS] = useState([]);
  const bsArr = [];
  const [bsString, setBsString] = useState("");
  //let bsArr = [];
  function onChangeBS(value, event) {
    let bsValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      bsValue = bs;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      bsValue = [];
    } else {
      bsValue = value;
    }
    for (let i = 0; i < bsValue.length; i++) {
      if (bsValue[i].id !== undefined) bsArr.push(bsValue[i].id);
    }
    setBsString(bsArr.toString());
  }

  //details
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);

  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");
  //get company list
  function getCompany() {
    getCompanyList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setCompanyData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //get Division list
  function getDivision(id) {
    getDivisionList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setDivision(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //get BU list
  function getBU(id) {
    getBUList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setBU(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //get BS list
  function getBS(id) {
    getBSList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setBS(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //Role
  function getRoles(id) {
    getRoleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setRole(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  // Manager
  function getDefaultManager(id) {
    getDefaultManagerList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setManager(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  // Get Module

  function getModule() {
    getModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setModuleId(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //Input Onchange

  const onChange = (event) => {
    let valid;

    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        valid = /^\w+([\.-]?\w+)*@siemens.com$/.test(event.target.value);
        if (!valid) {
          setEmailHelper("Invalid Email");
        } else {
          setEmailHelper("");
        }
        break;
      case "gid":
        setGID(event.target.value);
        valid = /^[a-zA-Z][a-zA-Z0-9]*$/.test(event.target.value);
        if (!valid) {
          setGIDHelper("Cannot start with Number and Special Character");
        } else if (event.target.value.length !== 8) {
          setGIDHelper("Minimum 8 characters");
        } else {
          setGIDHelper("");
        }

        break;
      case "firstName":
        setFirstName(event.target.value);
        valid = /^[a-zA-Z][a-zA-Z0-9 ]*$/.test(event.target.value);
        if (!valid) {
          setFirstNameHelper("Cannot contain special characters");
        } else if (event.target.value.length === 0) {
          setFirstNameHelper("Cannot be left blank");
        } else {
          setFirstNameHelper("");
        }
        break;
      case "lastName":
        setLastName(event.target.value);
        valid = /^[a-zA-Z][a-zA-Z0-9 ]*$/.test(event.target.value);
        if (!valid) {
          setLastNameHelper("Cannot contain special characters");
        } else if (event.target.value.length === 0) {
          setLastNameHelper("Cannot be left blank");
        } else {
          setLastNameHelper("");
        }
        break;
      default:
        break;
    }
  };
  const handleChangeModule = (e) => {
    setModuleIdValue(e.target.value);
    getRoles(e.target.value);
    getDefaultManager(e.target.value);
  };
  function handleChangeRole(e) {
    setRoleValue(e.target.value);
    setManagerValue("");
  }
  function handleChangeManager(e) {
    setManagerValue(e.target.value);
  }
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  //set company for existing user
  const [filterCompanyID, setFilterCompanyId] = useState("");
  const [filterDivisionID, setFilterDivisionId] = useState("");
  const [filterBUID, setFilterBUId] = useState("");
  const [filterBSID, setFilterBSId] = useState("");

  function editComapnyByUser(companyId) {
    var arrayCompany = companyId.split(",");
    setSelectedOptionsCompany(
      companyData.filter((x) => arrayCompany.includes(x.id))
    );
    getDivision(companyId);
  }
  //set division for existing user

  function editDivisionByUser(DivisionId) {
    var arrayDivision = DivisionId.split(",");
    setSelectedOptionsDivision(
      division.filter((x) => arrayDivision.includes(x.id))
    );
    getBU(DivisionId);
  }
  //set bu for existing user

  function editBUByUser(BUId) {
    var arrayBU = BUId.split(",");
    setSelectedOptionsBU(bu.filter((x) => arrayBU.includes(x.id)));
    getBS(BUId);
  }
  //set bs for existing user

  function editBSByUser(BSId) {
    var arrayBS = BSId.split(",");
    setSelectedOptionsBS(bs.filter((x) => arrayBS.includes(x.id)));
  }

  //get user data
  function getUserData() {
    setIsLoader(true);
    let id = atobSecure(encryptedId);
    setUserId(parseInt(id));
    getUserList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setFirstName(response[0].firstName);
        setLastName(response[0].lastName);
        setGID(response[0].gid);
        setEmail(response[0].emailId);
        setStatus(response[0].status);
        setModuleIdValue(response[0].moduleId);
        getRoles(response[0].moduleId);
        getDefaultManager(response[0].moduleId);
        setRoleValue(response[0].roleId);
        setManagerValue(response[0].defaultManagerId);
        setFilterCompanyId(response[0].companyId);
        setFilterDivisionId(response[0].divisionId);
        setFilterBUId(response[0].buid);
        setFilterBSId(response[0].bsid);
        setCompanyString(response[0].companyId);
        setDivisionString(response[0].divisionId);
        setBUString(response[0].buid);
        setBsString(response[0].bsid);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //Registration Data

  var formdata = {
    userId: 0,
    firstName: firstName,
    lastName: lastName,
    emailId: email,
    status: status,
    gid: gid,
    moduleId: moduleValue,
    defaultManagerId: roleValue === 2 || roleValue === 6 ? managerValue : 0,
    roleId: roleValue,
    companyId: companyString,
    divisionId: divisionString,
    buid: buString,
    bsid: bsString,
    createdBy: userDetails.userId,
  };
  let id = atobSecure(encryptedId);
  var formdataedit = {
    userId: parseInt(id),
    firstName: firstName,
    lastName: lastName,
    emailId: email,
    status: status,
    gid: gid,
    moduleId: moduleValue,
    defaultManagerId: roleValue === 2 || roleValue === 6 ? managerValue : 0,
    roleId: roleValue,
    companyId: companyString,
    divisionId: divisionString,
    buid: buString,
    bsid: bsString,
    createdBy: userDetails.userId,
  };

  //Add user details

  function addUser() {
    setIsLoader(true);
    var data;
    if (encryptedId) {
      data = formdataedit;
    } else {
      data = formdata;
    }
    addUserData(data,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status === 1) {
          if (encryptedId) {
            handleSnackOpen("User Data Updated Successfully.", "success");
          } else {
            handleSnackOpen("User Added Successfully.", "success");
          }

          setIsLoader(false);
        } else if (response.status === 2) {
          setIsLoader(false);
          handleSnackOpen("User Already Exists.", "warning");
          //reset();
        } else {
          setIsLoader(false);
          if (encryptedId) {
            handleSnackOpen("Error While Updating User.", "success");
          } else {
            handleSnackOpen("Error While Adding User.", "success");
          }
          //reset();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (encryptedId) {
      setPageHeader("Edit User");
      setBtnName("Update");
      getUserData();
    }
    getCompany();
    getModule();
  }, [encryptedId]);

  useEffect(() => {
    editComapnyByUser(filterCompanyID);
  }, [companyData, filterCompanyID]);

  useEffect(() => {
    editDivisionByUser(filterDivisionID);
  }, [division, filterDivisionID]);
  useEffect(() => {
    editBUByUser(filterBUID);
  }, [bu, filterBUID]);
  useEffect(() => {
    editBSByUser(filterBSID);
  }, [bu, filterBSID]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <div className="box-shadow">
        <Grid
          container
          spacing={2}
          direction="row"
          className="upload-block  pt-1">
          <Grid item xs={12} className="d-flex jc-flex-end">
            <p className={classes.note}>All fields are mandatory.</p>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              label="First Name"
              id="firstName"
              name="First Name"
              className=""
              value={firstName}
              helperText={firstNameHelper}
              onChange={onChange}
              fullWidth
              variant="filled"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              label="Last Name"
              id="lastName"
              name="lastName"
              className=""
              fullWidth
              variant="filled"
              value={lastName}
              helperText={lastNameHelper}
              onChange={onChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              label="Email"
              id="email"
              name="Email"
              className=""
              fullWidth
              value={email}
              variant="filled"
              helperText={emailHelper}
              placeholder="test@siemens.com"
              onChange={onChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              label="GID"
              id="gid"
              name="GID"
              className=""
              fullWidth
              value={gid}
              variant="filled"
              helperText={gidHelper}
              onChange={onChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="Module"
              id="module"
              name="Module"
              className=""
              fullWidth
              value={moduleValue}
              onChange={handleChangeModule}
              variant="filled">
              <MenuItem value={0}>
                <em>Select</em>
              </MenuItem>
              {moduleId.map((item) => (
                <MenuItem key={item.moduleId} value={item.moduleId}>
                  {item.moduleName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="Role"
              id="role"
              name="Role"
              className=""
              fullWidth
              value={roleValue}
              onChange={handleChangeRole}
              variant="filled">
              <MenuItem value={0}>
                <em>Select</em>
              </MenuItem>
              {role.map((item) => (
                <MenuItem key={item.roleId} value={item.roleId}>
                  {item.roleName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {roleValue === 2 || roleValue === 6 ? (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              className="d-flex jc-flex-start width-100">
              <TextField
                select
                label="Default Manager"
                id="defaultManager"
                name="DefaultManager"
                className=""
                fullWidth
                value={managerValue}
                onChange={handleChangeManager}
                variant="filled">
                <MenuItem value={0}>
                  <em>Select</em>
                </MenuItem>
                {manager.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={6} lg={3}>
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...companyData]}
              placeholderButtonLabel="Company"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={selectedOptionsCompany}
              onChange={onChangeCompany}
              setState={setSelectedOptionsCompany}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100 division_field">
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...division]}
              placeholderButtonLabel="Division"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={selectedOptionsDivision}
              onChange={onChangeDivision}
              setState={setSelectedOptionsDivision}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100 bu_field">
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...bu]}
              placeholderButtonLabel="BU"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={selectedOptionsBU}
              onChange={onChangeBU}
              setState={setSelectedOptionsBU}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100 bs_field">
            <ReactMultiSelectCheckboxes
              options={[{ label: "All", value: "*" }, ...bs]}
              placeholderButtonLabel="BS"
              getDropdownButtonLabel={getDropdownButtonLabel}
              value={selectedOptionsBS}
              onChange={onChangeBS}
              setState={setSelectedOptionsBS}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="Status"
              id="status"
              name="Status"
              className=""
              fullWidth
              onChange={handleStatus}
              value={status}
              variant="filled">
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="upload-block d-flex jc-center pt-2">
          <Grid item xs={12} md={1} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              fullWidth
              disabled={
                !firstName ||
                firstNameHelper ||
                !lastName ||
                lastNameHelper ||
                !email ||
                emailHelper ||
                !gid ||
                gidHelper ||
                !roleValue ||
                !companyString ||
                !divisionString ||
                !buString ||
                !bsString
              }
              onClick={addUser}>
              {btnName}
            </Button>
          </Grid>
          <Grid item xs={12} md={1} className="align-center width-100">
            <Link to={"/userManagement"}>
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                fullWidth>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
