import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import {
  addHardCodeMaster,
  editHardCodeMaster,
  getModuleList,
  getAllSubModuleList,
  getHardCodeMasterKey,
  getHardCodeMasterList,
} from "../../../Redux/API/api_admin";
import {
  baseAddressURL,
  CreateEnqModuleId,
  IDTModuleId,
  tableIconsInputScreen,
} from "../../../Utilities/Utility";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddHardCodeMaster() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add HardCode Master Data");
  const [data, setData] = useState([]);
  const [masterKey, setMasterkey] = useState([]);
  const [masterKeyValue, setMasterKeyValue] = useState("");
  const [masterKeyHelper, setMasterkeyHelper] = useState("");
  const [code, setCode] = useState("");
  const [codeHelper, setCodeHelper] = useState("");
  const [moduleId, setModuleId] = useState([]);
  const [moduleIdValue, setModuleIdValue] = useState("");
  const [subModuleId, setSubModuleId] = useState([]);
  const [subModuleIdValue, setSubModuleIdValue] = useState("");
  const [moduleIdHelper, setModuleIdHelper] = useState("");
  const [subModuleIdHelper, setSubModuleIdHelper] = useState("");
  const [name, setName] = useState("");
  const [nameHelper, setNameHelper] = useState("");
  const [hardCodeId, setHardCodeId] = useState();
  let [isLoader, setIsLoader] = useState(false);
  const [status, setStatus] = useState(true);

  
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/hardCodeMaster";
    }
  };

  const handleChangeMasterKey = (e) => {
    setMasterKeyValue(e.target.value);
    if (e.target.value === 0) {
      setMasterkeyHelper("Select any option.");
    } else {
      setMasterkeyHelper("");
    }
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleChangeCode = (e) => {
    setCode(e.target.value);
    let valid = /^[a-zA-Z0-9 _-]*$/.test(e.target.value);
    if (!valid) {
      setCodeHelper("Please enter valid values.");
    } else if (e.target.value.length < 1) {
      setCodeHelper("Minimum 2 characters required");
    } else {
      setCodeHelper("");
    }
  };
  const handleChangeName = (e) => {
    setName(e.target.value);
    if (e.target.value.length === 0) {
      setNameHelper("Cannot be left blank");
    } else if (e.target.value.length > 200) {
      setNameHelper("Length cannot excced more than 200.");
    } else {
      setNameHelper("");
    }
  };
  const handleChangeModule = (e) => {
    setModuleIdValue(e.target.value);
    getSubModule(e.target.value);
  };
  const handleChangeSub = (e) => {
    setSubModuleIdValue(e.target.value);
  };

  function getModule() {
    getModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setModuleId(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getSubModule(id) {
    getAllSubModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id, true)
      .then((response) => {
        setSubModuleId(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getMasterKey() {
    getHardCodeMasterKey(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setMasterkey(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  var formdata = {
    masterKey: masterKeyValue,
    code: code,
    name: name,
    moduleId: moduleIdValue,
    subModuleId: subModuleIdValue,
    status: status,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    hardCodeId: hardCodeId,
    masterKey: masterKeyValue,
    code: code,
    name: name,
    moduleId: moduleIdValue,
    subModuleId: subModuleIdValue,
    status: status,
    modifiedBy: userDetails.userId,
  };

  function reset() {
    setMasterKeyValue("");
    setCode("");
    setStatus(true);
    setName("");
    setModuleIdValue("");
    setSubModuleIdValue("");
  }

  function addMasterData() {
    setIsLoader(true);
    addHardCodeMaster(formdata, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("HardCode Master Added Successfully.", "success");
          setIsLoader(false);
          reset();
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("HardCode Data Already Exists.", "warning");
          reset();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding HardCode Data.", "error");
          reset();
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  function editMasterData() {
    setIsLoader(true);
    editHardCodeMaster(formdataEdit,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status == 1) {
          handleSnackOpen("HardCode Master Updated Successfully.", "success");
          setIsLoader(false);
        } else if (response.status == 2) {
          setIsLoader(false);
          handleSnackOpen("HardCode Data Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding HardCode Data.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  function getMasterData() {
    let id = atobSecure(encryptedId);
    setHardCodeId(parseInt(id));
    getHardCodeMasterList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setData(response);
        setMasterKeyValue(response[0].masterKey);
        setCode(response[0].code);
        setName(response[0].name);
        setModuleIdValue(response[0].moduleId);
        getSubModule(response[0].moduleId);
        setSubModuleIdValue(response[0].subModuleId);
        setStatus(response[0].status);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");

  useEffect(() => {
    if (siteToken) {
      getModule();
      getSubModule();
      getMasterKey();
    }
    if (encryptedId) {
      setPageHeader("Edit HardCode Master Data");
      getMasterData();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>
      <div className="box-shadow">
        <Grid
          container
          spacing={2}
          direction="row"
          className="upload-block pt-1">
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="Master Key"
              id="masterKey"
              name="Master Key"
              className=""
              value={masterKeyValue}
              onChange={handleChangeMasterKey}
              helperText={masterKeyHelper}
              fullWidth
              variant="filled">
              <MenuItem value={0}>
                <em>Select</em>
              </MenuItem>
              {masterKey?.map((item) => (
                <MenuItem key={item.masterKey} value={item.masterKey}>
                  {item.masterKey}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              label="Code"
              id="code"
              name="Code"
              className=""
              fullWidth
              variant="filled"
              value={code}
              helperText={codeHelper}
              inputProps={{ maxLength: 100 }}
              onChange={handleChangeCode}></TextField>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              label="Name"
              id="name"
              name="Name"
              className=""
              fullWidth
              value={name}
              helperText={nameHelper}
              onChange={handleChangeName}
              variant="filled"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="Module Name"
              id="moduleid"
              name="moduleid"
              className=""
              fullWidth
              value={moduleIdValue}
              helperText={moduleIdHelper}
              onChange={handleChangeModule}
              variant="filled">
              <MenuItem value={0}>
                <em>Select</em>
              </MenuItem>
              {moduleId?.map((item) => (
                <MenuItem key={item.moduleId} value={item.moduleId}>
                  {item.moduleName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="SubModule Name"
              id="submodule"
              name="submodule"
              className=""
              fullWidth
              value={subModuleIdValue}
              helperText={subModuleIdHelper}
              onChange={handleChangeSub}
              variant="filled">
              <MenuItem value={0}>
                <em>Select</em>
              </MenuItem>
              {subModuleId?.map((item) => (
                <MenuItem key={item.subModuleId} value={item.subModuleId}>
                  {item.subModuleName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            className="d-flex jc-flex-start width-100">
            <TextField
              select
              label="Status"
              id="status"
              name="Status"
              className=""
              fullWidth
              value={status}
              onChange={handleStatus}
              variant="filled">
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="upload-block d-flex jc-center pb-2">
          {encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={editMasterData}
                disabled={
                  !masterKeyValue ||
                  !code ||
                  !name ||
                  !moduleIdValue ||
                  !subModuleIdValue ||
                  masterKeyHelper ||
                  moduleIdHelper ||
                  codeHelper ||
                  nameHelper ||
                  subModuleIdHelper
                }
                fullWidth>
                Update
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          {!encryptedId ? (
            <Grid item xs={12} md={1} className="align-center width-100">
              <Button
                type="submit"
                className="pt-button--primary"
                id="SupplierLoginBtn"
                onClick={addMasterData}
                disabled={
                  !masterKeyValue ||
                  !code ||
                  !name ||
                  !moduleIdValue ||
                  !subModuleIdValue ||
                  masterKeyHelper ||
                  moduleIdHelper ||
                  codeHelper ||
                  nameHelper ||
                  subModuleIdHelper
                }
                fullWidth>
                Submit
              </Button>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12} md={1} className="align-center width-100">
            <Link to={"/hardCodeMaster"}>
              <Button
                type="submit"
                className="pt-button--secondary"
                id="SupplierLoginBtn"
                fullWidth>
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
        {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      </div>

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
