import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Button, Container } from "@material-ui/core";
import "../LoginRegister/LoginRegister.scss";
import OfficialLogo from "../../assets/images/siemens-logo-white.svg";
import { useDispatch } from "react-redux";
//import { useAuth } from "react-oidc-context";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  infotag: {
    fontSize: `1rem`,
  },
}));

export default function LoginRegister() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { instance } = useMsal();

  function azureADLogin() {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  }

  return (
    <div className="bg-login-register">
      <div className={classes.root}>
        <Container maxWidth="xl">
          <Grid item xs={12}>
            <div className="litigation-logo">
              <img src={OfficialLogo} className="official-logo" alt="Siemens" />
            </div>
          </Grid>
          <Grid container className="litigation-mobileContainer">
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              className="litigation--project-block">
              <div className="litigation--project">
                <p className="litigation-project-name">LITMUS TOOL</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={10} md={5} className="pt-lr-master-block">
              <div className="pt-lr-block">
                <div className="pt-login-siemens_user">
                  <h2 className="m-0">Siemens Employee Login </h2>
                  <span className={classes.infotag}>
                    If you are a Siemens Employee, login via Azure Ad
                  </span>
                  <div className="d-flex jc-center myid-login-btn-block">
                    <Link to="/">
                      <Button
                        className="pt-button--hero myid-login-btn"
                        id="AzureAdLogin"
                        onClick={azureADLogin}>
                        <span>Ad Login</span>
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
