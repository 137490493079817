import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { Container, Paper, Button } from "@material-ui/core";
import SiemensLogo from "../../assets/images/siemens-logo-petrol.svg";
import UnauthorizedAccessImage from "../../assets/images/unauthorized_y.svg";
import { lightTheme } from "../../Themes/LightTheme";
import "./UnauthorizedAccess.css";
import { useAuth } from "react-oidc-context";

const useStyles = makeStyles((theme) => ({
    'page_header': {
        margin: `20px 0`,
    },
    'unauthorized_block': {
        marginTop: `25px`,
        padding: `40px`
    },
    'ua-icon': {
        color: `var(--unauthorized-icon-bg-color)`,
        height: `5rem !important`,
        width: `5rem !important`,
        background: `var(--transparent)`,
        borderRadius: 0
    },
    'ua_text': {
        margin: `5px 0`
    },
    'back_home': {
        marginTop: `40px`,
    }
}));

export default function UnAuthorizedAccess() {
    const auth = useAuth();
    const classes = useStyles();
    function logoutUser(auth, dispatch) {
        if (auth !== undefined) {
            sessionStorage.clear();
            localStorage.clear();
            
            if ('caches' in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        caches.delete(name);
                    })
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.href = "/";
            }
        }
    }

    return (<ThemeProvider theme={lightTheme}><Container maxWidth={"sm"}>
        <header className={classes.page_header}>
            <Link to="/">
                <img src={SiemensLogo} className="official-logo" alt="Siemens Logo" />
            </Link>
        </header>
        <Paper className={classes.unauthorized_block}>
            <img src={UnauthorizedAccessImage} alt="Unauthorized Access" className="unauthorized-image" />
            <p className={classes.ua_text}>You are not authorized to view this page.</p>
           {/* <p className={classes.ua_text}>If this is an error, please contact admin@besys.co for permission</p>*/}
            <div className={classes.back_home}>
                <Link to="/">
                    <Button className="besys-button--secondary" onClick={logoutUser}>Go back to Login</Button>
                </Link>
            </div>
        </Paper>
    </Container>
    </ThemeProvider>
    )
}