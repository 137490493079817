import React from "react";
import { Link } from "react-router-dom";
import { CssBaseline, Container, Grid, Typography } from "@material-ui/core";
import "./Report.scss";

export default function Reports() {
  return (
    <React.Fragment>
      <CssBaseline />

      <main className="litigation-main">
        <Container maxWidth="lg">
          <div className="litigation-homepage--card--block">
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={3}>
                <Link to="/masterReport">
                  <div
                    className="litigation-homepage--card--block-item litigation-homepage--card--block-item--special"
                    id="litigationHomepageSales">
                    {/*The image of each card will be added as background*/}
                    <span className="litigation-homepage--card--block-item-text text-uppercase">
                      Issues Report
                    </span>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to="/cfrReport">
                  <div
                    className="litigation-homepage--card--block-item litigation-homepage--card--block-item--cfr"
                    id="litigationHomepageServices">
                    {/*The image of each card will be added as background*/}
                    <span className="litigation-homepage--card--block-item-text text-uppercase">
                      CFR Report
                    </span>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to="/eventReport">
                  <div
                    className="litigation-homepage--card--block-item litigation-homepage--card--block-item--event"
                    id="litigationHomepageServices">
                    {/*The image of each card will be added as background*/}
                    <span className="litigation-homepage--card--block-item-text text-uppercase">
                      Event Report
                    </span>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Link to="/refundSummaryReport">
                  <div
                    className="litigation-homepage--card--block-item litigation-homepage--card--block-item--refund"
                    id="litigationHomepageServices">
                    {/*The image of each card will be added as background*/}
                    <span className="litigation-homepage--card--block-item-text text-uppercase">
                      Refund Summary Report
                    </span>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
      </main>
    </React.Fragment>
  );
}
