import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Loader from "../../../Components/Loader/Loader";
import { getBUMasterList } from "../../../Redux/API/api_master";

export default function BUMasterList() {
  const [masterData, setMasterData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);

  const token = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  function GetEditButton(rowData) {
    return (
      <Tooltip title="" placement="bottom">
        <span>
          <Button href="javascript:void(0)">
            <span>
              <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

  function clickEditHardCodeMaster(rowData) {
    let id = rowData.buId;
    let idEncrypted = btoaSecure(id);
    window.location.href = "/addBU?id=" + idEncrypted;
  }

  const columns = [
    { title: "BUid", field: "buid", hidden: true },
    { title: "Business Unit Code", field: "buCode" },
    { title: "Business Unit Name", field: "buName" },
    { title: "Division", field: "divisionCode" },
    {
      title: "Status",
      field: "statusValue",
    },
  ];

  function clickAddHardCodeMaster() {
    window.location.href = "/addBU";
  }

  function getBUList() {
    setIsLoader(true);
    getBUMasterList(token,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setMasterData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (token) {
      getBUList();
    }
  }, [token]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} className="d-flex jc-space-bt">
          <Grid item xs={9} className="text-left ml-1">
            <h2>Business Unit List</h2>
          </Grid>
          <Grid item xs={3} className="text-right"></Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title=""
            columns={columns}
            data={masterData}
            icons={tableIconsInputScreen}
            options={{
              exportButton: {
                csv: true,
                pdf: false,
              },
              search: true,
              pageSize: 10,
              exportAllData: true,
              emptyRowsWhenPaging: false,
              exportFileName: "BuList",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
            actions={[
              {
                icon: AddIcon,
                isFreeAction: true,
                tooltip: "Add BU",
                onClick: () => {
                  clickAddHardCodeMaster();
                },
              },
              {
                icon: GetEditButton,
                tooltip: "Edit BU",
                onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={1} className="mb-1">
        <Link to={"/admin"}>
          <Button
            type="submit"
            className="pt-button--tertiary"
            id="SupplierLoginBtn"
            fullWidth>
            &lt; &lt; Back
          </Button>
        </Link>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}
