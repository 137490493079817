import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getModuleWiseIssuesListInRefund } from "../../../src/Redux/API/api_events";

const AddRefundHistory = () => {
  const dispatch = useDispatch();
  var encryptedId = getURLParameter("id");
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  let [isLoader, setIsLoader] = useState(false);

  const toolCaseDetails = useSelector(
    (state) => state.saveToolCaseNo.toolCaseNo
  );
  const [refundCaseNo, setRefundCaseNo] = useState("");
  const [draftNo, setDraftNo] = useState("");
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        // return sParameterName[1];
      }
      return value;
    }
  }
  const columnsForm = [
    { title: "Issue", field: "issueName" },
    { title: "Brief Description", field: "issueDescriptionName" },
    { title: "Remarks", field: "issueRemarks" },
    { title: "SubModule", field: "subModuleName" },
    { title: "IGST", field: "igst" },
    { title: "CGST", field: "cgst" },
    { title: "SGST", field: "sgst" },

    { title: "Total Sum", field: "totalSum" },

    {
      title: "Current Appeal Stage",
      field: "currentStageName",
    },
    { title: "Status", field: "issueLevelStatusName" }, 
     
      {
          title: "Remand Id", field: "remandCounter",
          render: (rowData) =>
              rowData.remandCounter === null
                  ? ""
                  : rowData.remandCounter === 0
                      ? "Remand "
                      : "Remand " + rowData.remandCounter, 
      },
    { title: "Destination", field: "destinationName" },
    { title: "Action Date", field: "createdDateString" },
  ];

  const [enqIssuesListsInRefund, setEnqIssuesListsInRefund] = useState([]);
  function getIssuesListInRefund(refundCaseId) {
    setIsLoader(true);

    getModuleWiseIssuesListInRefund(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, refundCaseId)
        .then((response) => {
        setEnqIssuesListsInRefund(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getIssuesListInRefund(atobSecure(encryptedId));
    setRefundCaseNo(toolCaseDetails.approved);
    setDraftNo(toolCaseDetails.draft);
  }, [encryptedId]);

  return (
    <div>
      <h3>Refund Case Number: {refundCaseNo ? refundCaseNo : draftNo} </h3>
      <h3>Issue Workflow History</h3>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={12}>
          <MaterialTable
            title=""
            columns={columnsForm}
            data={enqIssuesListsInRefund}
            icons={tableIconsInputScreen}
            options={{
              search: true,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 50],
              pageSize: 10,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportAllData: true,
              exportFileName: "IssueWorkFlow",
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-1 d-flex jc-center">
        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/AddRefund?id=" + encryptedId}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--secondary"
              id="SupplierLoginBtn">
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
export default AddRefundHistory;
