import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import PaymentIcon from "@material-ui/icons/Payment";
// import ServicesGroup from "../../../assets/icons/groups.svg";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SecurityIcon from "@material-ui/icons/Security";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import "./SideDrawer.scss";
import { MasterSideDrawer } from "./../../Utilities/Utility";
import { getLandingMenuList } from "../../Redux/API/api_admin";

const useStyles = makeStyles({
  list: {
    width: 300,
    background: `#099`,
  },
  fullList: {
    width: "auto",
  },
});

export default function SideDrawer() {
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState({
    left: false,
  });
  const [routes, setRoutes] = useState([]);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  const landingMenuItems =
    useSelector((state) => state.storeLandingMenuItemsReducer.menuItems) || [];

  function homeClick() {
    window.location.href = "/";
  }

  const icons = {
    "<PaymentIcon/>": <PaymentIcon />,
    "<AssessmentIcon/>": <AssessmentIcon />,
    "<ListAltOutlinedIcon/>": <ListAltOutlinedIcon />,
    "<SecurityIcon/>": <SecurityIcon />,
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  function logoutUser() {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.location.href = "/";
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Link to="/" className="d-flex jc-center side-drawer-logo">
        <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
      </Link>

      <List className="side-drawer-menu-list">
        <ListItem button onClick={homeClick}>
          <ListItemIcon className="side-drawer-icons">
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {landingMenuItems.map((item) => (
          <Link to={item.menuLink} key={item.landingMenuId}>
            <ListItem button key={item.landingMenuId}>
              <ListItemIcon className="side-drawer-icons">
                {icons[item.icon]}
              </ListItemIcon>
              <ListItemText primary={item.menuName} />
            </ListItem>
          </Link>
        ))}
        <ListItem button onClick={logoutUser}>
          <ListItemIcon className="side-drawer-icons">
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      <div className="sideDrawer-footer-block">
        <p>© 2023 Siemens Intranet</p>
      </div>
    </div>
  );
  // useEffect(() => {
  //   if (userDetails.roleId) getMenus();
  // }, [userDetails.roleId]);

  return (
    <React.Fragment>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <img
            src={DrawerMenuIcon}
            alt="Litigation Menu"
            className="besys-menu-icon"
            onClick={toggleDrawer(anchor, true)}
          />
          <SwipeableDrawer
            anchor={anchor}
            open={drawerState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}>
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
