import {
  authUserAction,
  storeUserDetailsAction,
  saveAccessTokenAction,
  errorMessageAction,
  saveUIDAction,
  saveBearerTokenAction,
} from "../Actions/actions";
import { baseApiURL } from "../../Utilities/Utility";
let gID,
  generatedToken = "";

export function authenticateUserBasedOnValidToken(dispatch, newToken) {
  var formdataPart1 = new FormData();
  formdataPart1.append("Token", newToken);
  const options = {
    method: "post",
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formdataPart1,
  };
  return fetch(baseApiURL + "/Login/ValidateUser", options)
    .then((response) => response.json())
    .then((data) => {
      if (data.result) {
        dispatch(storeUserDetailsAction(data.result.response));
        dispatch(saveUIDAction(data.result.uid));
        dispatch(saveBearerTokenAction(data.result.tokenString));
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
  // const responseJson = await response.json();
  // dispatch(storeUserDetailsAction(responseJson.result));
}

// We are getting ONLY token from this api
export function validateTheUserBasedOnApi(dispatch, auth) {
  const arr = auth.user.profile.sub.split("|");
  const gid = arr && arr.length ? arr[1] : "";
  const systemUser = {
    UserGID: gid,
  };
  const options = {
    method: "post",
    mode: "cors",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
      "Access-Control-Allow-Origin": `${window.location.origin}`,
    },
    body: JSON.stringify(systemUser),
  };

  return fetch(baseApiURL + "/Login/ValidateRequest", options)
    .then((res) => res.json())
    .then((res_token) => {
      if (auth !== undefined && auth.user) {
        const arr = auth.user.profile.sub.split("|");
        gID = arr && arr.length ? arr[1] : "";
        dispatch(authUserAction(window.btoa(gID)));
      }
      generatedToken = res_token.token;
      dispatch(saveAccessTokenAction(generatedToken));

      return generatedToken;
    })
    .catch((error) =>
      dispatch(errorMessageAction("Invalid Email or Password"))
    );
}
