import { baseApiURL } from "../../Utilities/Utility";

export function getCompany(siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    userId: uid,
    RoleName: roleName,
    Gid: gid,
    Roleid: roleID,
    AzureToken: siteToken},
  };
  return fetch(baseApiURL + "/Master/GetCompanyList", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getIssueList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetIssueList?moduleId=" + moduleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getIssueDescriptionList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  issueId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetIssueDescriptionList?issueId=" + issueId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getYear(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  fromDate,
  toDate
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetFinancialYears?fromDate=" +
      fromDate +
      "&toDate=" +
      toDate,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getEnquiryList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetEnquiryType?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getCommunicationModeList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetModeOfDepartmentCommunication?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getCommunicationTypeList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetTypeOfDepartmentCommunication?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getEnquiryStatusList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetIssueStatus?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getLocationList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(baseApiURL + "/Master/GetLocationState?companyId=" + id, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getTaxProfessionalList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetTaxProfessional?moduleId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getTaxManagerList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id,
  profId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetTaxManager?moduleId=" + id + "&userId=" + profId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getGSTINList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  stateId,
  companyId,
  regId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstCompanyGst?stateId=" +
      stateId +
      "&companyId=" +
      companyId +
      "&typeOfReg=" +
      regId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//! New APi calls
export function SaveMetaData(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveMetadata", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveIssueList(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveEnquiryIssuePart1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveModeOfComm(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/SaveModeOfComm", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function GetGeneralEnqFormDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/GetIdtEnquiryFormDetails?ToolCaseId=" +
      toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function GetIssueGridDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtEnquiry/GetEnquiryIssueInvoled?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function GetCommunicationGridDetails(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/IdtEnquiry/GetIdtEnquiryGridDetails?ToolCaseId=" +
      toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//* Put methods
export function editGeneralEnqFormData(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditEnquiryPart1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function editIssueGrid(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditIssueInvolved", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function editModeOfCommGrid(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditModeOfComm", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//! new update methods

export function UpdateMetaDataDetails(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/IdtEnquiry/EditEnquiryPart1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
