import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  MenuItem,
  TextField,
  Button,
  TextareaAutosize,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./Report.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  GetReportModuleList,
  getBUListByUserId,
  getCompanyListByUserId,
  getDivisionListByUserId,
  getIssueReportFile,
  getReportLocationList,
  getRefundIssueReportFile,
} from "../../Redux/API/api_reports";
import { getTypeOfRegList } from "../../Redux/API/api_master";
import { IDTRefundID } from "../../Utilities/Utility";
import { GetNatureOfRefundList } from "../../Redux/API/api_refund";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  root: {
    padding: "0 8px",
  },
  paper: {
    width: `100%`,
    height: 260,
    overflow: "auto",
    background: `#e8f8f8`,
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  note: {
    fontSize: `12px`,
    color: `#FF5454`,
    margin: `0`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
  mt1: {
    marginTop: `1rem`,
  },
  m0: {
    margin: `0`,
  },
}));

export default function MasterReport() {
  const classes = useStyles();
  let [isLoader, setIsLoader] = useState(false);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector(
    (state) => state.saveBearerTokenReducer.bearerToken
  );
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  //Search Options
  const [reportCategory, setReportCategory] = useState([]);
  const [reportCategoryValue, setReportCategoryValue] = useState(1);
  const [module, setModule] = useState([]);
  const [moduleValue, setModuleValue] = useState(0);
  const [reportType, setReportType] = useState([]);
  const [reportTypeValue, setReportTypeValue] = useState("");
  const [issueType, setIssueType] = useState([]);
  const [issueTypeValue, setIssueTypeValue] = useState(0);
  const [dateType, setDateType] = useState([]);
  const [dateTypeValue, setDateTypeValue] = useState(1);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [asOnDate, setAsOnDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [reportModule, setReportModule] = useState([]);
  const [reportModuleValue, setReportModuleValue] = useState("");
  const [natureOfTax, setNatureOfTax] = useState([]);
  const [natureOfTaxValue, setNatureOfTaxValue] = useState(0);
  const [natureOfRefund, setNatureOfRefund] = useState([]);
  const [natureOfRefundValue, setNatureOfRefundValue] = useState(0);
  // Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleModule = (e) => {
    setModuleValue(e.target.value);
    getTypeOfReg(e.target.value);
  };
  const handleReportCategory = (e) => {
    setReportCategoryValue(e.target.value);
    getReportModule(e.target.value);
    setReportTypeValue("");
    setNatureOfTaxValue(0);
    setNatureOfRefundValue(0);
  };
  const handleReportType = (e) => {
    setReportTypeValue(e.target.value);
  };
  const handleIssueType = (e) => {
    setIssueTypeValue(e.target.value);
  };
  const handleDateType = (e) => {
    setDateTypeValue(e.target.value);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date.toISOString());
    setMinDate(date.toISOString());
  };
  const handleToDateChange = (date) => {
    setToDate(date.toISOString());
  };
  const handleAsOnDateChange = (date) => {
    setAsOnDate(date.toISOString());
  };
  const handleNatureOfTax = (e) => {
    setNatureOfTaxValue(e.target.value);
  };

  const handleNatureOfRefund = (e) => {
    setNatureOfRefundValue(e.target.value);
  };

  function getReportModule(id) {
    GetReportModuleList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id
    )
      .then((response) => {
        setReportModule(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getNatureOfRefund() {
    GetNatureOfRefundList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      IDTRefundID
    )
      .then((response) => {
        setNatureOfRefund(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //! company multi select
  const [selectedOptionsCompany, setSelectedOptionsCompany] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  let companyArr = [];
  const [companyString, setCompanyString] = useState("");
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  function onChangeCompany(value, event) {
    let companyValue = [];
    setSelectedOptionsDivision([]);
    setDivisionString("");
    setBUString("");
    setSelectedOptionsBU([]);
    setLocationString("");
    setSelectedOptionsLocation([]);
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      companyValue = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      companyValue = companyData;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      companyValue = [];
    } else {
      companyValue = value;
    }
    for (let i = 0; i < companyValue.length; i++) {
      if (companyValue[i].id !== undefined) companyArr.push(companyValue[i].id);
    }

    setCompanyString(companyArr.toString());
    getDivision(userDetails.userId, companyArr.toString());
    getLocation(companyArr.toString());
  }

  //! division multi select
  const [division, setDivision] = useState([]);
  const [selectedOptionsDivision, setSelectedOptionsDivision] = useState([]);
  let divisionArr = [];
  const [divisionString, setDivisionString] = useState("");
  function onChangeDivision(value, event) {
    let divisionValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      divisionValue = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      divisionValue = division;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      divisionValue = [];
    } else {
      divisionValue = value;
    }
    for (let i = 0; i < divisionValue.length; i++) {
      if (divisionValue[i].id !== undefined)
        divisionArr.push(divisionValue[i].id);
    }
    setDivisionString(divisionArr.toString());
    getBU(userDetails.userId, companyString, divisionArr.toString());
  }

  //!BU multi select
  const [bu, setBU] = useState([]);
  const [selectedOptionsBU, setSelectedOptionsBU] = useState([]);
  let buArr = [];
  const [buString, setBUString] = useState("");
  function onChangeBU(value, event) {
    let buValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      buValue = bu;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      buValue = [];
    } else {
      buValue = value;
    }
    for (let i = 0; i < buValue.length; i++) {
      if (buValue[i].id !== undefined) buArr.push(buValue[i].id);
    }
    setBUString(buArr.toString());
  }

  //!Location multi select
  const [location, setLocation] = useState([]);
  const [selectedOptionsLocation, setSelectedOptionsLocation] = useState([]);
  let locationArr = [];
  const [locationString, setLocationString] = useState("");
  function onChangeLocation(value, event) {
    let locationValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      locationValue = location;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      locationValue = [];
    } else {
      locationValue = value;
    }
    for (let i = 0; i < locationValue.length; i++) {
      if (locationValue[i].id !== undefined)
        locationArr.push(locationValue[i].id);
    }
    setLocationString(locationArr.toString());
  }

  //!Get Company list
  function getCompany() {
    getCompanyListByUserId(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      userDetails.userId
    )
      .then((response) => {
        setCompanyData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getDivision(userId, id) {
    getDivisionListByUserId(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      userId,
      id
    )
      .then((response) => {
        setDivision(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getBU(userId, companyId, divId) {
    getBUListByUserId(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      userId,
      companyId,
      divId
    )
      .then((response) => {
        setBU(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getTypeOfReg(subModuleId) {
    getTypeOfRegList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      subModuleId
    )
      .then((response) => {
        setNatureOfTax(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getLocation(id) {
    getReportLocationList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id
    )
      .then((response) => {
        if (response !== null) {
          setLocation(response);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //! post data
  var formdata = {
    companyIds: companyString,
    divisionIds: divisionString,
    buIds: buString,
    subModuleId: moduleValue,
    natureOfTaxId: natureOfTaxValue,
    locationId: locationString,
    issueStatusId: issueTypeValue ? issueTypeValue : 0,
    fromDate: fromDate,
    toDate: toDate,
    reportTypeId: reportTypeValue,
    AsOnDate: asOnDate,
  };
  var formdataRefund = {
    companyIDs: companyString,
    divisionIDs: divisionString,
    buIDs: buString,
    subModuleID: moduleValue,
    natureOfRefund: natureOfRefundValue,
    locationID: locationString,
    isssueStatus: issueTypeValue ? issueTypeValue : 0,
    startDate: fromDate,
    endDate: toDate,
    AsOnDate: asOnDate,
  };

  function reset() {
    setCompanyString("");
    setSelectedOptionsCompany([]);
    setDivisionString("");
    setSelectedOptionsDivision([]);
    setBUString("");
    setSelectedOptionsBU([]);
    setLocationString("");
    setSelectedOptionsLocation([]);
    setModuleValue(0);
    setNatureOfTaxValue(0);
    setReportTypeValue("");
    setIssueTypeValue(0);
    setFromDate(null);
    setToDate(null);
    setReportCategoryValue(1);
    setAsOnDate(null);
  }

  function getIssueReport() {
    setIsLoader(true);
    getIssueReportFile(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      formdata
    )
      .then((response) => {
        if (response.status === true) {
          window.location.href = response.result;
          handleSnackOpen("Issue report downloaded successfully.", "success");
        } else {
          handleSnackOpen(response.result, "error");
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
        handleSnackOpen("Error with download report.", "error");
      });
  }
  function getRefundIssueReport() {
    setIsLoader(true);
    getRefundIssueReportFile(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      formdataRefund
    )
      .then((response) => {
        if (response.status === true) {
          window.location.href = response.result;
          handleSnackOpen("Issue report downloaded successfully.", "success");
        } else {
          handleSnackOpen(response.result, "error");
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
        handleSnackOpen("Error with download report.", "error");
      });
  }

  useEffect(() => {
    getCompany();
    getReportModule(1);
    getTypeOfReg(0);
    getNatureOfRefund();
  }, [siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, userDetails]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={6} lg={6} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>Issues Report</h3>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className="d-flex jc-flex-end">
          <h3 className={classes.note}>
            Company & Fields Marked With '*' Are Mandatory{" "}
          </h3>
        </Grid>
      </Grid>

      <Grid container spacing={2} direction="row" className="upload-block pt-1">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Report Category"
            id="year"
            name=""
            className=""
            fullWidth
            value={reportCategoryValue}
            onChange={handleReportCategory}
            variant="filled">
            <MenuItem value={1}>Litigation</MenuItem>
            <MenuItem value={2}>Refund</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ReactMultiSelectCheckboxes
            options={[{ label: "All", value: "*" }, ...companyData]}
            placeholderButtonLabel="Company"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptionsCompany}
            onChange={onChangeCompany}
            required
            setState={setSelectedOptionsCompany}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <ReactMultiSelectCheckboxes
            options={[{ label: "All", value: "*" }, ...division]}
            placeholderButtonLabel="Division"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptionsDivision}
            onChange={onChangeDivision}
            setState={setSelectedOptionsDivision}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <ReactMultiSelectCheckboxes
            options={[{ label: "All", value: "*" }, ...bu]}
            placeholderButtonLabel="BU"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptionsBU}
            onChange={onChangeBU}
            setState={setSelectedOptionsBU}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Module"
            id="year"
            name=""
            className=""
            fullWidth
            value={moduleValue}
            onChange={handleModule}
            variant="filled">
            <MenuItem key={0} value={0}>
              All
            </MenuItem>
            {reportModule.map((item) => (
              <MenuItem key={item.subModuleId} value={item.subModuleId}>
                {item.subModuleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {reportCategoryValue === 1 ? (
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Nature Of Tax"
              id="year"
              name=""
              className=""
              fullWidth
              value={natureOfTaxValue}
              onChange={handleNatureOfTax}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {natureOfTax.map((item) => (
                <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              select
              label="Nature Of Refund"
              id="year"
              name=""
              className=""
              fullWidth
              value={natureOfRefundValue}
              onChange={handleNatureOfRefund}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {natureOfRefund.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={3}>
          <ReactMultiSelectCheckboxes
            options={[{ label: "All", value: "*" }, ...location]}
            placeholderButtonLabel="Location"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptionsLocation}
            onChange={onChangeLocation}
            setState={setSelectedOptionsLocation}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Report Type"
            id="year"
            name=""
            className=""
            fullWidth
            required
            value={reportTypeValue}
            onChange={handleReportType}
            variant="filled">
            {reportCategoryValue === 1 ? (
              <MenuItem value={1}>Issue Report</MenuItem>
            ) : (
              <MenuItem value={2}>Invoice Wise Issue Report</MenuItem>
            )}
            {reportCategoryValue === 1 ? (
              <MenuItem value={2}>Invoice Wise Issue Report</MenuItem>
            ) : (
              <s></s>
            )}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            select
            label="Issue Report Type"
            id="year"
            name=""
            className=""
            fullWidth
            disabled={reportTypeValue === 3 ? true : false}
            value={issueTypeValue}
            onChange={handleIssueType}
            variant="filled">
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={1}>Open Issue</MenuItem>
            <MenuItem value={2}>Closed Issue</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h4 className={classes.m0}>Time Period</h4>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="From Period Involved"
              format="dd/MM/yyyy"
              fullWidth
              required
              value={fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="To Period Involved"
              format="dd/MM/yyyy"
              fullWidth
              value={toDate}
              required
              onChange={handleToDateChange}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="As On Date"
              format="dd/MM/yyyy"
              fullWidth
              value={asOnDate}
              onChange={handleAsOnDateChange}
              minDate={fromDate}
              //maxDate={toDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        className="upload-block pt-1 multi-select">
        {/* <Grid item xs={12}>
          <h4 className={classes.m0}>Miscellaneous Filters</h4>
        </Grid> */}

        {/*  */}

        {/* {module === 1 && !companyString ? (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <p className={classes.note}>Please select any company.</p>
            </Grid>
          </>
        ) : (
          <></>
        )} */}
      </Grid>

      <Grid
        container
        spacing={2}
        className="upload-block d-flex jc-center pt-2">
        <Grid item xs={12} md={1} className="align-center width-100">
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            fullWidth
            disabled={
              !companyString || !fromDate || !toDate || !reportTypeValue
            }
            onClick={
              reportCategoryValue === 1 ? getIssueReport : getRefundIssueReport
            }>
            Export
          </Button>
        </Grid>
        <Grid item xs={12} md={1} className="align-center width-100">
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            fullWidth
            onClick={reset}>
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/reports"}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
