// JavaScript source code
import { baseApiURL } from "../../Utilities/Utility";

export function getModuleList(siteToken,bearerToken,email,gid,roleID,roleName,uid,) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken,
  
  },
  };
  return fetch(baseApiURL + "/Master/GetMstModuleList", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getLandingMenuList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  return fetch(baseApiURL + "/Master/GetLandingMenu", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getSubModuleList(siteToken,bearerToken,email,gid,roleID,roleName,uid, moduleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken,
  
  },
  };
  return fetch(
    baseApiURL + "/Master/GetMstSubModuleList?moduleId=" + moduleId,

    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getAllSubModuleList(siteToken,bearerToken,email,gid,roleID,roleName,uid,moduleId,isAll) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken, },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstSubModuleList?moduleId=" +
      moduleId +
      "&isAll=" +
      isAll,

    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getHardCodeMasterKey(siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken, },
  };
  return fetch(baseApiURL + "/Master/GetHardCodeMasterKey", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function addHardCodeMaster(formData, siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Master/InsertHardCodeMasterData", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function editHardCodeMaster(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Master/UpdateHardCodeMasterData", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getHardCodeMasterList(siteToken,bearerToken,email,gid,roleID,roleName,uid,type) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken,
  
  
  
  },
  };
  return fetch(baseApiURL + "/Master/GetHardCodeMasterList?id=" + type, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//Issue Master API Call
export function addIssueMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Master/SaveIssueMasterData", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getIssueMasterList(siteToken, type) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  return fetch(baseApiURL + "/Master/GetIssueMasterList?id=" + type, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function editIssueMaster(formData, siteToken) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + siteToken,
    },
    body: JSON.stringify(formData),
  };
  return fetch(baseApiURL + "/Master/UpdateIssueMasterData", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
