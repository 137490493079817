import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import VisibilityIcon from "@material-ui/icons/Visibility";

const dataForm = [
  {
    1: "First Appellate",
    2: "Test1234",
    3: "22/05/2023",
    4: "Appeal filed-PH awaited",
    5: "2000",
    6: "3000",
    7: "4000",
    8: "9000",
    9: "10000",
  },
  {
    1: "Tribunal",
    2: "Test1234",
    3: "22/05/2023",
    4: "PH held-order awaited",
    5: "200",
    6: "300",
    7: "400",
    8: "900",
    9: "1000",
  },
  {
    1: "High Court",
    2: "Test1234",
    3: "22/05/2023",
    4: "Order Received.",
    5: "100",
    6: "200",
    7: "300",
    8: "600",
    9: "700",
  },
  {
    1: "Supreme Court",
    2: "Test1234",
    3: "22/05/2023",
    4: "Order Received.",
    5: "200",
    6: "300",
    7: "400",
    8: "900",
    9: "1000",
  },
  {
    1: "First Appellate",
    2: "Test1234",
    3: "22/05/2023",
    4: "Appeal filed-PH awaited",
    5: "100",
    6: "200",
    7: "300",
    8: "600",
    9: "700",
  },
];
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
    //borderBottom: `2px solid #00BCBC`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
}));
export default function AppealList() {
  const classes = useStyles();

  const columnsForm = [
    { title: "Appeal Stage", field: "1" },
    { title: "Order No/DRC-07", field: "2" },
    { title: "Order/DRC-07 Date", field: "3" },
    { title: "Appeal Status", field: "4" },
    { title: "Tax", field: "5" },
    { title: "Interest", field: "6" },
    { title: "Penalty", field: "7" },
    { title: "Pre-Deposit", field: "8" },
    { title: "Provisions", field: "9" },
  ];

  return (
    <div className="">
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex fd-column">
          <h3>Tool Case Number:- 1245A67BXX</h3>
        </Grid>
        {/* <Grid Grid item xs={12} md={8} lg={9}>
          <StatusButtons />
        </Grid> */}
      </Grid>
      <Grid container spacing={2} className="mt-1">
        <Grid item xs={12}>
          <MaterialTable
            title="Appeals List"
            columns={columnsForm}
            data={dataForm}
            icons={tableIconsInputScreen}
            options={{
              actionsColumnIndex: -1,
              search: false,
              toolbar: false,
              pageSize: 10,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 30, 50],
            }}
            actions={[
              (rowData) => ({
                icon: VisibilityIcon,
                isFreeAction: true,
                tooltip: `View Toolcase Details`,
              }),
            ]}
            localization={{
              header: {
                actions: "View Details",
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
