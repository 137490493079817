import DateFnsUtils from "@date-io/date-fns";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import AddIcon from "@material-ui/icons/Add";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getMergedEntityList } from "../../Redux/API/api_master";
import AddRefundHistory from "../../Screens/Refund/AddRefundHistory";
import {
  getCompany,
  getGSTINList,
  getLocationList,
  getTaxManagerList,
  getYear,
} from "../../Redux/API/api_createEnquiry";
import { getEventsList } from "../../Redux/API/api_events";
import {
  IDTModuleId,
  IDTRefundID,
  baseAddressURL,
  baseApiURL,
  tableIconsInputScreen,
} from "../../Utilities/Utility";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from "@material-ui/core/Icon";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import "./Refund.scss";
import {
  GetNatureOfRefundList,
  GetReferenceToolCaseList,
  GetRefundEventGridList,
  GetRefundMetaDataDetails,
  GetRefundRegNoList,
  GetRefundSectionData,
  GetRefundSubModuleList,
  InsertRefundSection,
  SaveRefundMetaData,
  ChangeStatusOfRefundCase,
  updateStage,
  CheckRefundModuleProceedStatus,
  InsertRefundIssues,
  GetRefundIssueData,
  DeleteRefundEventById,
} from "../../Redux/API/api_refund";
import RefundSectionTemplate from "../../assets/template/UploadTemplate/RefundSectionUploadTemplate.xlsx";
import RefundIssueTemplate from "../../assets/template/UploadTemplate/RefundIssueUploadTemplate.xlsx";
import {
  saveEventToolCaseIdAction,
  saveFromDate,
  saveMetaData,
  storeRefundCaseStatus,
  storeRefundIssueDetailsAction,
  storeToolCaseNo,
} from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
  },
  rejectDiv: {
    color: `red`,
  },
  aTagTemplate: {
    color: `#00BCBC`,
    fontStyle: `italic`,
    borderBottom: `1px solid #00BCBC`,
    fontWeight: `bold`,
    width: `fit-content`,
    padding: `0`,
    margin: `0`,
  },
  errorTag: {
    color: `#FF5454`,
    fontStyle: `italic`,
    borderBottom: `1px solid #FF5454`,
    fontWeight: `bold`,
    width: `fit-content`,
    padding: `0`,
    margin: `0`,
  },
  p0: {
    paddingTop: `0 !important`,
    paddingBottom: `0 !important`,
  },
  m0: {
    marginTop: `0 !important`,
    marginBottom: `0 !important`,
  },
  mainDiv: {
    padding: `0 8px !important`,
  },

  deleteBtn: {
    color: `red`,
  },
  issueHistory: {
    padding: `0`,
    // margin: `0`,
    color: `#00BCBC`,
    fontStyle: `italic`,
    fontWeight: `bold`,
    width: `fit-content`,
  },
  cfrNo: {
    padding: `0`,
    margin: `0`,
    color: `#00BCBC`,
    fontWeight: `bold`,
    width: `fit-content`,
  },
  notificationBtn: {
    padding: `0`,
  },
  bellIcon: {
    color: `#FF5454`,
    height: `1.2em`,
    width: `1.2em`,
  },
  dialogHeader: {
    margin: `0`,
  },
  pTag: {
    margin: `8px 0`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    paddingBottom: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddRefund() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  //const a = "/AddRefundHistory";
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  //* Loader, Ids && Reject Hooks
  const [metaDataId, setMetaDataId] = useState(0);
  const [refundCaseId, setRefundCaseId] = useState("");
  const [rejectMsg, setRejectMsg] = useState("");
  let [isLoader, setIsLoader] = useState(false);
  var encryptedId = getURLParameter("id");
  const [approveStatus, setApproveStatus] = useState("");
  const [movingStatus, setMovingStatus] = useState("");
  const [currentStage, setCurrentStage] = useState("");
  const [issueUploadStatus, setIssueUploadStatus] = useState(false);
  const [isRefundCaseClosed, setIsRefundCaseClosed] = useState(false);
  const historyLink = "/AddRefundHistory?id=" + btoaSecure(refundCaseId); //toolcaseId
  //*  Meta Data Hooks
  const [entity, setEntity] = useState([]);
  const [entityValue, setEntityValue] = useState("");
  const [mergedEntity, setMergedEntity] = useState([]);
  const [mergedEntityValue, setMergedEntityValue] = useState("");
  const [referenceToolCase, setReferenceToolCase] = useState([]);
  const [referenceToolCaseValue, setReferenceToolCaseValue] = useState("");
  const [refundNo, setRefundNo] = useState("");
  const [draftRefundNo, setDraftRefundNo] = useState("");
  const [location, setLocation] = useState([]);
  const [locationValue, setLocationValue] = useState("");
  const [profValue, setProfValue] = useState(
    userDetails.firstName + " " + userDetails.lastName
  );
  const [profId, setProfId] = useState(userDetails.userId);
  const [manager, setManager] = useState([]);
  const [managerValue, setManagerValue] = useState("");
  const [gst, setGst] = useState([]);
  const [gstValue, setGstValue] = useState("");
  const [natureOfRefund, setNatureOfRefund] = useState([]);
  const [natureOfRefundValue, setNatureOfRefundValue] = useState("");
  const [natureOfTaxName, setNatureOfRefundName] = useState("");
  const [fromPeriod, setFromPeriod] = useState(null);
  const [toPeriod, setToPeriod] = useState(null);
  const [year, setYear] = useState([]);
  const [arnDate, setARNDate] = useState(null);
  const [arnNo, setARNNo] = useState("");
  const [issueWiseModule, setIssueWiseModule] = useState([]);
  const [issueWiseModuleValue, setIssueWiseModuleValue] = useState("");
  const [issueWiseModuleName, setIssueWiseModuleName] = useState("");
  //* Refund Section
  const [refundErrorFile, setRefundErrorFile] = useState("");
  const [refundSectionModule, setRefundSectionModule] = useState([]);
  const [refundSectionModuleValue, setRefundSectionModuleValue] = useState("");
  const [selectedRefundSectionFile, setSelectedRefundSectionFile] =
    useState(null);
  const [inputRefundSectionFile, setInputRefundSectionFile] = useState(
    Date.now
  );
  const [uploadedRefundSecFile, setUploadedRefundSecFile] = useState("");

  //* Refund Issue Upload
  const [refundIssueErrorFile, setRefundIssueErrorFile] = useState("");
  const [selectedRefundIssueFile, setSelectedRefundIssueFile] = useState(null);
  const [inputRefundIssueFile, setInputRefundIssueFile] = useState(Date.now);
    const [uploadedIssueFile, setUploadedIssueFile] = useState("");
  
  //* Table and Other Hooks
  const [events, setEvents] = useState([]);
  const [uploadedIssues, setUploadedIssues] = useState([]);
  const [uploadedRefund, setUploadedRefund] = useState([]);
  //* Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
    if (alertType === "success") {
      window.location.href = baseAddressURL + "/refundList";
        
    }
  };
  const [openSnackbarInternal, setOpenSnackbarInternal] = useState(false);
  const [snackMessageInternal, setSnackMessageInternal] = useState("");
  const [alertTypeInternal, setAlertTypeInternal] = useState("");
  const handleSnackOpenInternal = (text, type) => {
    setSnackMessageInternal(text);
    setAlertTypeInternal(type);
    setOpenSnackbarInternal(true);
  };
  const handleCloseSnackbarInternal = (e) => {
    setOpenSnackbarInternal(false);
  };

  //* Meta Data Handlers
  const handleChangeEntity = (event) => {
    setLocationValue("");
    setMergedEntityValue("");
    setEntityValue(event.target.value);
    getLocation(event.target.value);
    getMergedEntity(event.target.value);
  };
  const handleChangeMergedEntity = (event) => {
    setMergedEntityValue(event.target.value);
  };
  const handleReferenceToolCase = (event) => {
    setReferenceToolCaseValue(event.target.value);
  };
  const handleChangeAssessmentLocation = (event) => {
    setLocationValue(event.target.value);
  };
  //const handleManager = (event) => {
  //  setManagerValue(event.target.value);
  //};
  const handleGST = (event) => {
    setGstValue(event.target.value);
  };

  const handleNatureOfRefund = (e) => {
    setNatureOfRefundValue(e.target.value);
  };

  const handleChangeAssesmentLocation = (event) => {
    setLocationValue(event.target.value);
    setGstValue("");
    if (entityValue && !mergedEntityValue) {
      getRegNoList(event.target.value, entityValue);
    }
    if (mergedEntityValue) {
      getRegNoList(event.target.value, mergedEntityValue);
    }
  };
  const handleFromPeriod = (date) => {
    setYearString("");
    setSelectedOptionsYear([]);
    setFromPeriod(date.toISOString());
    if (toPeriod) {
      GetFinancialYears(date.toISOString(), toPeriod);
    }
  };
  const handleToPeriod = (date) => {
    setYearString("");
    setSelectedOptionsYear([]);
    setToPeriod(date.toISOString());
    if (fromPeriod) {
      GetFinancialYears(fromPeriod, date.toISOString());
    }
  };
  const handleARNNO = (event) => {
    setARNNo(event.target.value);
  };
  const handleARNDate = (date) => {
    setARNDate(date.toISOString());
  };

  const handleRefundSectionModule = (e) => {
    setRefundSectionModuleValue(e.target.value);
    // if (encryptedId) {
    //   getRefundSection(refundCaseId, e.target.value);
    // }
  };
  const handleIssueWiseModule = (e) => {
    setIssueWiseModuleValue(e.target.value);
    var name = refundSectionModule.filter(
      (c) => c.subModuleId === e.target.value
    );
    setIssueWiseModuleName(name[0].subModuleName);
    getRefundIssues(refundCaseId, e.target.value);
  };
  const handleChangeRefundSectionFile = (event) => {
    setSelectedRefundSectionFile(event.target.files[0]);
  };
  const handleChangeRefundIssueFile = (event) => {
    setSelectedRefundIssueFile(event.target.files[0]);
  };

  //atobSecure

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  //!Multi-select
  const [selectedOptionsYear, setSelectedOptionsYear] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [filterYear, setFilterYear] = useState("");
  function editYearByUser(filterYear) {
    var arrayYear = filterYear.split(",");
    setSelectedOptionsYear(year.filter((x) => arrayYear.includes(x.value)));
  }
  let yearArr = [];
  const [yearString, setYearString] = useState("");
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  function onChangeYear(value, event) {
    let yearValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      yearValue = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      yearValue = year;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      yearValue = [];
    } else {
      yearValue = value;
    }
    for (let i = 0; i < yearValue.length; i++) {
      if (yearValue[i].value !== undefined) yearArr.push(yearValue[i].value);
    }
    setYearString(yearArr.toString());
  }
  //* Grid Columns
  const refundColumns = [
    { title: "Refund Case Id", field: "refundCaseId", hidden: true },
    { title: "Module", field: "subModuleName" },
    { title: "Nature Of Refund", field: "natureOfRefund" },
    { title: "Refund Amount", field: "refundAmountSum" },
    { title: "Received Amount", field: "receivedAmountSum" },
    { title: "Pending Amount", field: "pendingAmountSum" },
    { title: "Re-Credited Amount", field: "recreditedAmountSum" },
    { title: "Rejected/Appeal Amount", field: "rejectedOrAppealAmountSum" },
    { title: "Amount Written Off", field: "amountWrittenOffSum" },
    { title: "Upload Date", field: "createdDateString" },
  ];
  const issueColumns = [
    { title: "Issue Id", field: "issueId", hidden: true },
    { title: "Id", field: "id", hidden: true },
    { title: "Upload Id", field: "uploadId", hidden: true },
    { title: "Sub Module Id", field: "subModuleId", hidden: true },
    { title: "Module", field: "subModuleName" },
    { title: "Issue Name", field: "issueName" },
    { title: "Issue Description", field: "issueDescriptionName" },
    { title: "IGST", field: "igst" },
    { title: "SGST", field: "sgst" },
    { title: "CGST", field: "cgst" },
    { title: "Total Sum", field: "totalSum" },
    {
      title: "Current Appeal Stage",
      field: "currentStageName",
      hidden: issueWiseModuleName === "Refund" ? true : false,
    },
    { title: "Status", field: "issueLevelStatusName" },
    {
      title: "Remand Id",
      field: "remandCounter",
      // render: (rowData) =>
      //   rowData.remandCounter === null
      //     ? ""
      //     : "Remand " + rowData.remandCounter || rowData.remandCounter === 0
      //     ? "Remand"
      //     : "Remand " + rowData.remandCounter,

      render: (rowData) =>
        rowData.remandCounter === null
          ? ""
          : rowData.remandCounter === 0
          ? "Remand "
          : "Remand " + rowData.remandCounter,
    },
    {
      title: "Destination",
      field: "destinationName",
      hidden: issueWiseModuleName === "Refund" ? true : false,
    },
  ];
  const eventColumns = [
    {
      title: "Event Id",
      field: "eventId",
      hidden: true,
    },
    {
      title: "Refund Case Id",
      field: "refundCaseId",
      hidden: true,
    },
    {
      title: "Edit/Delete",
      field: "subModuleName",
      render: (rowData) => rowData && getActionButtons(rowData),
      hidden: userDetails.roleId !== 1 ? true : false,
    },
    { title: "Event/Action Module", field: "subModuleName" },
    { title: "Event/Action Type", field: "eventOrActionTypeName" },
    {
      title: "Event/Action By",
      field: "eventOrActionByName",
    },
    {
      title: "Event/Action Date",
      field: "eventOrActionDateString",
    },
    { title: "Document Mode", field: "documentModeName" },
    { title: "Communication Type", field: "communicationTypeName" },
    { title: "Document No.", field: "documentNo" },
    {
      title: "Document Date",
      field: "documentDateString",
    },
    {
      title: "Download Document",
      field: "documentPath",
      render: (rowData) => rowData && getButtons(rowData),
    },
    {
      title: "Due date of event/action",
      field: "actionOrEventDueDateString",
    },
    { title: "Adjudicating Authority", field: "adjudicatingAuthorityName" },
    {
      title: "Remarks",
      field: "eventRemarks",
      align: "left",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.eventRemarks}>
            <span>{rowData.eventRemarks.slice(0, 10)}</span>
          </Tooltip>
        );
      },
    },
  ];

  //! Download EventGrid Files
  function getButtons(rowData) {
    return (
      <div>
        {rowData.documentPath ? (
          <IconButton
            className="activationButton"
            aria-label="activate"
            disabled={!rowData.documentPath}
            href={rowData.documentPath}
            target="_blank">
            <ArrowDownwardIcon />
          </IconButton>
        ) : (
          <NotInterestedIcon />
        )}
      </div>
    );
  }
  function GetRefundSectionSaveButton(rowData) {
    return (
      <Tooltip
        className="export-btn"
        title={
          uploadedRefundSecFile === ""
            ? "No File To Download"
            : "Download last Uploaded File"
        }
        placement="top">
        <span>
          <IconButton
            href={uploadedRefundSecFile}
            disabled={uploadedRefundSecFile === "" ? true : false}>
            <CloudDownloadIcon fontSize="small" className="attachFileIcon" />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
  function GetIssueSaveButton(rowData) {
    return (
      <Tooltip
        title={
          uploadedIssueFile === ""
            ? "No File To Download"
            : "Download last Uploaded File"
        }
        placement="top">
        <span>
          <IconButton
            href={uploadedIssueFile}
            disabled={uploadedIssueFile === "" ? true : false}>
            <CloudDownloadIcon fontSize="small" className="attachFileIcon" />
          </IconButton>
        </span>
      </Tooltip>
    );
  }
  //! Event Actions
  function getActionButtons(rowData) {
    return (
      <div>
        <IconButton
          className="editButton"
          aria-label="activate"
          onClick={() => {
            editEvents(rowData);
          }}>
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>

        <IconButton
          className={classes.deleteBtn}
          aria-label="delete"
          disabled={isRefundCaseClosed === true ? true : false}
          onClick={() => {
            deleteEvents(rowData);
          }}>
          <Tooltip title="Delete">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      </div>
    );
  }

  function deleteEvents(rowData) {
    DeleteRefundEventById(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, rowData.eventId)
      .then((response) => {
        if (response.status === 1) {
          handleSnackOpenInternal("Event Deleted Successfully.", "success");
          getRefundEvents(refundCaseId);
        } else {
          handleSnackOpenInternal("Error While Deleting Event.", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function editEvents(rowData) {
    dispatch(saveEventToolCaseIdAction(refundCaseId));
    dispatch(saveFromDate(fromPeriod));
    dispatch(saveMetaData(metaDataId));
    let id = rowData.eventId;
    let idEncrypted = btoaSecure(id);
    window.location.href = "/addRefundEvents?id=" + idEncrypted;
  }

  //! Dropdown Functions
  function getEntity() {
    getCompany(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setEntity(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getMergedEntity(companyId) {
    getMergedEntityList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,companyId)
      .then((response) => {
        setMergedEntity(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getReferenceToolCase() {
    GetReferenceToolCaseList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setReferenceToolCase(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRefundSubModule() {
    GetRefundSubModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setRefundSectionModule(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getLocation(companyId) {
    getLocationList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, companyId)
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getTaxManager(id) {
    getTaxManagerList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, IDTModuleId, id)
        .then((response) => {
            
          setManager(response);
          setManagerValue(response[0].userId);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRegNoList(locationId, companyId) {
    GetRefundRegNoList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, locationId, companyId)
      .then((response) => {
        setGst(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function GetFinancialYears(fromdate, toDate) {
    getYear(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, fromdate, toDate)
      .then((response) => {
        setYear(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function NavigateEventsPage(id) {
    dispatch(saveEventToolCaseIdAction(id));
    dispatch(saveFromDate(fromPeriod));
    dispatch(saveMetaData(metaDataId));
    window.location.href = "/addRefundEvents";
  }
  function getNatureOfRefund() {
    GetNatureOfRefundList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, IDTRefundID)
      .then((response) => {
        setNatureOfRefund(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getRefundEvents(id) {
    GetRefundEventGridList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id)
      .then((response) => {
        setEvents(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getRefundIssues(id, subModuleId) {
    setIsLoader(true);
    GetRefundIssueData(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id, subModuleId)
      .then((response) => {
        setUploadedIssues(response);
        if (response.length > 0) {
          setUploadedIssueFile(response[0].excelFilePath);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //! Navigate to Issue Details Page
  function viewIssueDetails(rowData) {
    let issueDetails = {
      issueId: rowData.id,
      metaDataId: metaDataId,
      briefDescName: rowData.issueDescriptionName,
      subModuleId: issueWiseModuleValue,
      statusName: rowData.issueLevelStatusName,
      subModuleName: rowData.subModuleName,
    };
    dispatch(storeRefundIssueDetailsAction(issueDetails));
    let id = rowData.id;
    let idEncrypted = btoaSecure(id);
    window.location.href = baseAddressURL + "/refundIssueDetails";
  }
  function resetIssueDetails() {
    let issueDetails = {
      issueId: 0,
      metaDataId: 0,
      subModuleId: 0,
      statusName: "",
      subModuleName: "",
    };
    dispatch(storeRefundIssueDetailsAction(issueDetails));
  }

  //! Save Meta data
  function toolcaseGenerated(result) {
    let idEncrypted = btoaSecure(result);
    window.location.href =
      baseAddressURL + "/ToolCaseRegistration?id=" + idEncrypted;
    }

    //save refund case data
    function refundCaseGenerated(result) {
        let idEncrypted = btoaSecure(result);
        window.location.href =
            baseAddressURL + "/addRefund?id=" + idEncrypted;
    }
    
  var formdata = {
    entityId: entityValue,
    mergedEntityId: mergedEntityValue ? mergedEntityValue : 0,
    referenceToolCaseId: referenceToolCaseValue ? referenceToolCaseValue : 0,
    assessmentLocationId: locationValue,
    taxProfessionId: profId,
    taxManagerId: managerValue,
    registrationNoId: gstValue,
    natureOfRefundId: natureOfRefundValue,
    fromPeriodInvolved: fromPeriod,
    toPeriodInvolved: toPeriod,
    assesmentYear: yearString,
    arnno: arnNo,
    arndate: arnDate,
    createdBy: userDetails.userId,
  };
  function submitRefundMetaData() {
    setIsLoader(true);
    SaveRefundMetaData(formdata, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
        .then((response) => {
           
        if (response.status == 1) {
          handleSnackOpen("Refund Details Added Successfully.", "success");
            refundCaseGenerated(response.result);
            setIsLoader(false);
            
        } else if (response.status === 4) {
          setIsLoader(false);
          handleSnackOpen(response.result, "error");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Refund Details.", "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //! Change Status
  function changeStatus() {
    setIsLoader(true);
    ChangeStatusOfRefundCase(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, refundCaseId)
      .then((response) => {
        if (response === 1) {
          handleSnackOpen("Refund Case Sent For Approval.", "success");
          getRefundCaseData(atobSecure(encryptedId));
          setIsLoader(false);
        } else {
          setIsLoader(false);
          handleSnackOpen(
            "Error While Sending Refund Case For Approval.",
            "error"
          );
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }

  //! Change Module
  function checkModuleStatus(id, subModuleId) {
    CheckRefundModuleProceedStatus(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id, subModuleId)
      .then((response) => {
        setMovingStatus(response.status);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function moveToNextStage() {
    updateStage(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, refundCaseId, userDetails.userId)
      .then((response) => {
        if (response.status === 1) {
          handleSnackOpenInternal(
            "Refund Case Moved To Next Stage.",
            "success"
          );
          getRefundCaseData(atobSecure(encryptedId));
        } else {
          handleSnackOpenInternal(
            "Error While Moving Refund Case To Next Stage.",
            "error"
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  //? Dynamic Grid Data

  function getNatureOfRefundName(id) {
    var regName = natureOfRefund.filter((c) => c.hardCodeId === id);
    if (regName.length > 0) {
      setNatureOfRefundName(regName[0].name);
    }
  }

  //! Decrypt Methods
  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
    encryptedId = getURLParameter("id");
   

  function getRefundCaseData(metaDataId) {
    setIsLoader(true);
    GetRefundMetaDataDetails(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, metaDataId)
      .then((response) => {
        setMetaDataId(response.metaDataId);
        setRefundCaseId(response.refundCaseID);
        setApproveStatus(response.refundCaseStatus);
        setRejectMsg(response.rejectedReason);
        setEntityValue(response.entityId);
        getMergedEntity(response.entityId);
        getLocation(response.entityId);
        setMergedEntityValue(response.mergedEntityId);
        setRefundNo(response.refundCaseNo);

        setDraftRefundNo(response.draftRefundCaseNo);
        let toolCaseDetails = {
          draft: response.draftRefundCaseNo,
          approved: response.refundCaseNo,
        };
        dispatch(storeToolCaseNo(toolCaseDetails));
        setLocationValue(response.assessmentLocationId);
        if (!response.mergedEntityId) {
          getRegNoList(response.assessmentLocationId, response.entityId);
        } else {
          getRegNoList(response.assessmentLocationId, response.mergedEntityId);
        }
        setProfValue(response.taxProfessionName);
        setProfId(response.taxProfessionId);
        getTaxManager(response.taxProfessionId);
        setManagerValue(response.taxManagerId);
        setGstValue(response.registrationNoId);
        setFromPeriod(response.fromPeriodInvolved);
        setToPeriod(response.toPeriodInvolved);
        GetFinancialYears(
          response.fromPeriodInvolved,
          response.toPeriodInvolved
        );
        editYearByUser(response.assesmentYear);
        setFilterYear(response.assesmentYear);
        setYearString(response.assesmentYear);
        setRefundSectionModuleValue(response.currentStageId);
        setCurrentStage(response.currentStageId);
        setIssueWiseModuleValue(response.currentStageId);
        setARNNo(response.arnno);
        setARNDate(response.arndate);
        setNatureOfRefundValue(response.natureOfRefundId);
        setReferenceToolCaseValue(response.referenceToolCaseId);
        setIsRefundCaseClosed(response.isRefundClosed);
        dispatch(storeRefundCaseStatus(response.isRefundClosed));
        if (response.currentStageCode === "RPL") {
          setIssueUploadStatus(true);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
      });
  }
  function getRefundSection(id) {
    GetRefundSectionData(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id)
      .then((response) => {
        setUploadedRefund(response);
        setUploadedRefundSecFile(response[0].excelFilePath);
      })
      .catch((error) => {
        console.log("error", error);
      });
    }
  

  //! Refund Section Upload
  function submitRefundSection() {
    setIsLoader(true);
    var formdataPart1 = new FormData();
    formdataPart1.append("excelFile", selectedRefundSectionFile);
    InsertRefundSection(
      formdataPart1,
      siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,
      refundCaseId,
      userDetails.userId,
      currentStage
    )
      .then((response) => {
        setInputRefundSectionFile(Date.now);
        setSelectedRefundSectionFile(null);
        if (response.status === 1) {
          handleSnackOpenInternal(
            "Refund Section Uploaded Successfully.",
            "success"
          );
          setRefundErrorFile("");

          getRefundSection(refundCaseId);
          checkModuleStatus(refundCaseId, currentStage);
          setIsLoader(false);
        } else if (response.status === 4) {
          setRefundErrorFile(response.result);
          setIsLoader(false);
          setInputRefundSectionFile(Date.now);
          setSelectedRefundSectionFile(null);
          handleSnackOpenInternal("Error While Uploading Issues.", "error");
        } else {
          setIsLoader(false);
          setRefundErrorFile("");
          handleSnackOpenInternal(response.result, "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  function submitRefundIssue() {
    setIsLoader(true);
    var formdataPart1 = new FormData();
    formdataPart1.append("excelFile", selectedRefundIssueFile);
    InsertRefundIssues(
      formdataPart1,
      siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,
      refundCaseId,
      userDetails.userId,
      issueWiseModuleValue
    )
        .then((response) => {

            
           
        if (response.status === 1) {
          handleSnackOpenInternal("Issues Uploaded Successfully.", "success");
            setIsLoader(false);
            setInputRefundIssueFile(Date.now);
            setSelectedRefundIssueFile(null);
         
          getRefundIssues(refundCaseId, issueWiseModuleValue);
          checkModuleStatus(refundCaseId, currentStage);
          setRefundIssueErrorFile("");
        } else if (response.status === 4) {
          setRefundIssueErrorFile(response.result);
          setIsLoader(false);
          
            setInputRefundIssueFile(Date.now);
            setSelectedRefundIssueFile(null);
          handleSnackOpenInternal("Error While Uploading Issues.", "error");
        } else {
          setIsLoader(false);
          setRefundIssueErrorFile("");
            handleSnackOpenInternal(response.result, "error");
            setInputRefundIssueFile(Date.now);
            setSelectedRefundIssueFile(null);
           
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
    }

    useEffect(() => {
        atobSecure(encryptedId);
    },[])

  useEffect(() => {
    getEntity();
    getReferenceToolCase();
    getNatureOfRefund();
    getRefundSubModule();
    getTaxManager(userDetails.userId);
    dispatch(saveEventToolCaseIdAction(0));
    dispatch(saveFromDate(0));
    dispatch(saveMetaData(0));
    resetIssueDetails();
    if (encryptedId) {
      getRefundCaseData(atobSecure(encryptedId));
    }
    if (refundCaseId && currentStage) {
      checkModuleStatus(refundCaseId, currentStage);
    }
  }, [siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, encryptedId, refundCaseId, currentStage]);

  useEffect(() => {
    if (refundCaseId && refundSectionModuleValue && issueWiseModuleValue) {
      getRefundSection(refundCaseId);
      getRefundEvents(refundCaseId);
      getRefundIssues(refundCaseId, issueWiseModuleValue);
    }
  }, [refundCaseId, refundSectionModuleValue, issueWiseModuleValue]);

  useEffect(() => {
    editYearByUser(filterYear);
  }, [year, filterYear]);

  return (
    <div className={classes.mainDiv}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} className="d-flex jc-space-bt">
          <h3 className={classes.sectionHeader}>Add Refund Form</h3>
        </Grid>
        {rejectMsg && approveStatus === "Rejected" ? (
          <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start m-0">
            <h4 className={classes.rejectDiv}>
              Rejection Reason:- {rejectMsg}
            </h4>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid container spacing={2} className={classes.subGrid}>
        <Grid item xs={12} md={3}>
          <TextField
            select
            label="Entity Name"
            id=""
            name=""
            className=""
            fullWidth
            value={entityValue}
            onChange={handleChangeEntity}
            variant="filled">
            {entity.map((item) => (
              <MenuItem key={item.companyId} value={item.companyId}>
                {item.companyName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            label="Merged Entity Name"
            id=""
            name=""
            className=""
            fullWidth
            value={mergedEntityValue}
            onChange={handleChangeMergedEntity}
            variant="filled">
            <MenuItem key={0} value={0}>
              None
            </MenuItem>
            {mergedEntity.map((item) => (
              <MenuItem key={item.companyId} value={item.companyId}>
                {item.companyName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            label="Reference Tool Case"
            id=""
            name=""
            className=""
            fullWidth
            value={referenceToolCaseValue}
            onChange={handleReferenceToolCase}
            variant="filled">
            <MenuItem key={0} value={0}>
              None
            </MenuItem>
            {referenceToolCase.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Refund Case No"
            id="toolcaseno"
            name="desiredOPInKW"
            className=""
            fullWidth
            disabled
            //onChange={handleInputChange}
            value={approveStatus === "Approved" ? refundNo : draftRefundNo}
            variant="filled"
            InputLabelProps={{
              shrink: draftRefundNo ? true : false,
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            select
            label="Assesment Location"
            id=""
            name=""
            className=""
            fullWidth
            value={locationValue}
            onChange={handleChangeAssesmentLocation}
            variant="filled">
            {location.map((item) => (
              <MenuItem key={item.stateId} value={item.stateId}>
                {item.state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="IDT - Tax Professional"
            id=""
            name=""
            className=""
            fullWidth
            disabled
            value={profValue}
            InputLabelProps={{ shrink: true }}
            variant="filled"></TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            label="IDT - Tax Manager"
            id=""
            name=""
            className=""
            fullWidth
            value={managerValue}
           // onChange={handleManager}
            variant="filled">
            {manager.map((item) => (
              <MenuItem key={item.userId} value={item.userId}>
                {item.firstName} {item.lastName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            select
            label="Nature of Refund"
            id=""
            name=""
            className=""
            fullWidth
            disabled={encryptedId ? true : false}
            value={natureOfRefundValue}
            onChange={handleNatureOfRefund}
            variant="filled">
            {natureOfRefund.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            select
            label="Registration No"
            id=""
            name=""
            className=""
            fullWidth
            value={gstValue}
            onChange={handleGST}
            variant="filled">
            <MenuItem key="" value="">
              Select
            </MenuItem>
            {gst.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
                          label="From Period Involved"
                          disableFuture
              format="dd/MM/yyyy"
              fullWidth
              value={fromPeriod}
              onChange={handleFromPeriod}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="toPeriod"
              className="datepicker"
              label="To Period Involved"
              format="dd/MM/yyyy"
                          fullWidth
                          disableFuture
                          minDate={fromPeriod}
              value={toPeriod}
              onChange={handleToPeriod}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3}>
          <ReactMultiSelectCheckboxes
            options={[{ label: "All", value: "*" }, ...year]}
            placeholderButtonLabel="Assessment Year"
            getDropdownButtonLabel={getDropdownButtonLabel}
            value={selectedOptionsYear}
            onChange={onChangeYear}
            setState={setSelectedOptionsYear}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="ARN No."
            id=""
            name=""
            className=""
            fullWidth
            value={arnNo}
            onChange={handleARNNO}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="arnDate"
              className="datepicker"
              label="ARN Date"
              format="dd/MM/yyyy"
              fullWidth
              value={arnDate}
              onChange={handleARNDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      {encryptedId ? (
        <>
          <Grid container spacing={2} className={classes.subGrid}>
            <Grid item xs={12}>
              <h3 className={classes.m0}>Upload Refund</h3>
            </Grid>
            <Grid item container xs={12} spacing={2} className="">
              <Grid item xs={12} md={4}>
                <a href={RefundSectionTemplate}>
                  <p className={classes.aTagTemplate}>
                    Click to download Refund Upload Template
                  </p>
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <TextField
                label="Upload Updated Refund Template"
                id=""
                name=""
                className=""
                fullWidth
                variant="filled"
                type="file"
                //disabled={issueUploadStatus === true ? true : false}
                key={inputRefundSectionFile}
                onChange={handleChangeRefundSectionFile}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
              <TextField
                label="Upload Updated Refund Template"
                id="businessUnitName"
                name=""
                className=""
                fullWidth
                type="file"
                key={inputRefundSectionFile}
                onChange={handleChangeRefundSectionFile}
                variant="filled"
                InputLabelProps={{ shrink: true }}
                //helperText="All File Types Are Allowed"
                // InputProps={{
                //   endAdornment: (
                //     <InputAdornment position="end">
                //       <IconButton
                //         aria-label="toggle password visibility"
                //         className="p-0"
                //         target="_blank"
                //         // href={downloadPath}
                //         //</InputAdornment>disabled={downloadPath ? false : true}
                //       >
                //         <CloudDownloadIcon />
                //       </IconButton>
                //     </InputAdornment>
                //   ),
                // }}
              />
            </Grid>
            <Grid item xs={6} md={1} className="align-self">
              {userDetails.roleId === 2 || userDetails.roleId === 1 ? (
                <Button
                  type="submit"
                  fullWidth
                  className="pt-button--primary"
                  disabled={
                    !selectedRefundSectionFile || isRefundCaseClosed === true
                  }
                  onClick={submitRefundSection}
                  id="SupplierLoginBtn">
                  Upload
                </Button>
              ) : (
                <></>
              )}
            </Grid>
            {refundErrorFile ? (
              <Grid item xs={12} className="d-flex jc-center">
                <a
                  href={
                    baseApiURL +
                    "/Common/DownloadLog?fileName=" +
                    refundErrorFile
                  }>
                  <p className={classes.errorTag}>
                    Click to download Error Log File
                  </p>
                </a>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12}>
              <MaterialTable
                title="Refund List"
                columns={refundColumns}
                data={uploadedRefund}
                icons={tableIconsInputScreen}
                options={{
                  emptyRowsWhenPaging: false,
                  actionsColumnIndex: -1,
                  exportButton: {
                    csv: true,
                    pdf: false,
                  },
                  exportAllData: true,
                  exportFileName: "UploadedRefundList",
                  rowStyle: (x) => {
                    if (x.tableData.id % 2) {
                      return { backgroundColor: "#F2FCFC" };
                    } else {
                      return { backgroundColor: "white" };
                    }
                  },
                }}
                actions={[
                  {
                    icon: GetRefundSectionSaveButton,
                    isFreeAction: true,
                  },
                ]}
                localization={{
                  header: {
                    actions: "View Details",
                  },
                }}
              />
            </Grid>
          </Grid>
          {issueUploadStatus === true ? (
            <Grid container spacing={2} className={classes.subGrid}>
              <Grid item xs={12}>
                <h3 className={classes.sectionHeader}>Upload Issues</h3>
              </Grid>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={4}>
                  <a href={RefundIssueTemplate}>
                    <p className={classes.aTagTemplate}>
                      Click to download Issue Upload Template
                    </p>
                  </a>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  label="Module Name"
                  id=""
                  name=""
                  className=""
                  fullWidth
                  value={issueWiseModuleValue}
                  onChange={handleIssueWiseModule}
                  variant="filled">
                  {refundSectionModule.map((item) => (
                    <MenuItem key={item.subModuleId} value={item.subModuleId}>
                      {item.subModuleName}
                    </MenuItem>
                  ))}
                </TextField>
                          </Grid>
                         
              <Grid item xs={12} md={4}>
                <TextField
                  label="Upload Updated Issue Template"
                  id=""
                  name=""
                  className=""
                  fullWidth
                  variant="filled"
                  type="file"
                  key={inputRefundIssueFile}
                  onChange={handleChangeRefundIssueFile}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} md={1} className="align-self">
                {userDetails.roleId === 2 || userDetails.roleId === 1 ? (
                  <Button
                    type="submit"
                    fullWidth
                    className="pt-button--primary"
                    disabled={
                      !selectedRefundIssueFile || isRefundCaseClosed === true
                    }
                    onClick={submitRefundIssue}
                    id="SupplierLoginBtn">
                    Upload
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
              {refundIssueErrorFile ? (
                <Grid item xs={12} className="d-flex jc-center">
                  <a
                    href={
                      baseApiURL +
                      "/Common/DownloadLog?fileName=" +
                      refundIssueErrorFile
                    }>
                    <p className={classes.errorTag}>
                      Click to download Error Log File
                    </p>
                  </a>
                </Grid>
              ) : (
                <></>
              )}

              {issueUploadStatus == true ? (
                <Grid item xs={12} className="new">
                  <a href={historyLink} className={classes.issueHistory}>
                    Issues Workflow History &gt;&gt;
                  </a>
                </Grid>
              ) : (
                <></>
              )}

              <Grid item xs={12}>
                <MaterialTable
                  title="Issue List"
                  columns={issueColumns}
                  data={uploadedIssues}
                  icons={tableIconsInputScreen}
                  options={{
                    emptyRowsWhenPaging: false,
                    actionsColumnIndex: -1,
                    exportButton: {
                      csv: true,
                      pdf: false,
                    },
                    exportAllData: true,
                    exportFileName: "UploadedIssues",
                    rowStyle: (x) => {
                      if (x.tableData.id % 2) {
                        return { backgroundColor: "#F2FCFC" };
                      } else {
                        return { backgroundColor: "white" };
                      }
                    },
                  }}
                  actions={[
                    (rowData) => ({
                      icon: VisibilityIcon,
                      isFreeAction: true,
                      tooltip: `Add Issue Level Details`,
                      onClick: (event, rowData) => viewIssueDetails(rowData),
                    }),
                    {
                      icon: GetIssueSaveButton,
                      isFreeAction: true,
                    },
                  ]}
                  localization={{
                    header: {
                      actions: "View Details",
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      {encryptedId ? (
        <>
          <Grid container spacing={2} className="mt-1">
            <Grid item xs={12} className="mt-1">
              <MaterialTable
                title="Event Details"
                columns={eventColumns}
                data={events}
                icons={tableIconsInputScreen}
                options={{
                  search: true,
                  emptyRowsWhenPaging: false,
                  exportButton: {
                    csv: true,
                    pdf: false,
                  },
                  exportFileName: "EventsList",
                  exportAllData: true,
                  rowStyle: (x) => {
                    if (x.tableData.id % 2) {
                      return { backgroundColor: "#F2FCFC" };
                    } else {
                      return { backgroundColor: "white" };
                    }
                  },
                }}
                actions={[
                  {
                    icon: AddIcon,
                    isFreeAction: true,
                    tooltip: "Add Events",
                    hidden:
                      userDetails.roleId !== 1 && userDetails.roleId !== 2
                        ? true
                        : false,
                    onClick: () => {
                      NavigateEventsPage(refundCaseId);
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid container spacing={3} className="mt-1 d-flex jc-center">
        <Grid item xs={6} md={2}>
          <Link to={"/refundList"}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--secondary"
              id="SupplierLoginBtn">
              Back
            </Button>
          </Link>
        </Grid>
        {!encryptedId && userDetails.roleId === 2 ? (
          <Grid item xs={6} md={2}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--primary"
              id="SupplierLoginBtn"
              disabled={
                !entityValue ||
                !locationValue ||
                !managerValue ||
                !natureOfRefundValue ||
                !gstValue ||
                !fromPeriod ||
                !toPeriod ||
                yearString === "" ||
                !arnNo ||
                !arnDate ||
                isRefundCaseClosed === true
                  ? true
                  : false
              }
              onClick={submitRefundMetaData}>
              Submit
            </Button>
          </Grid>
        ) : (
          <></>
        )}

        {(encryptedId &&
          approveStatus === "Draft" &&
          userDetails.roleId === 2) ||
        (encryptedId &&
          approveStatus === "Rejected" &&
          userDetails.roleId === 2) ? (
          <Grid item xs={6} md={2}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--primary"
              id="SupplierLoginBtn"
              disabled={
                events.length === 0 ||
                isRefundCaseClosed === true ||
                uploadedRefund.length === 0
                  ? true
                  : false
              }
              onClick={changeStatus}>
              Send for Approval
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        {movingStatus === 1 &&
        approveStatus === "Approved" &&
        userDetails.roleId === 2 ? (
          <Grid item xs={6} md={2}>
            <Button
              type="submit"
              fullWidth
              className="pt-button--primary"
              id="SupplierLoginBtn"
              disabled={isRefundCaseClosed === true ? true : false}
              onClick={moveToNextStage}
              endIcon={<Icon>send</Icon>}>
              Move to Next Stage
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <>
        <SnackbarCustom
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleCloseSnackbar}
        />
      </>
      <>
        <SnackbarCustom
          open={openSnackbarInternal}
          message={snackMessageInternal}
          alertType={alertTypeInternal}
          handleClose={handleCloseSnackbarInternal}
        />
      </>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}
