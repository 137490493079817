import { baseApiURL } from "../../Utilities/Utility";

//? get apis

export function getNatureOfTaxList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstNatureOfTaxList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export function getScnDrcList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstScnDrcList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getAssesmentList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetScnAssessmentModuleList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getScnIssuedList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstScnIssuedList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getProfitCenterList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  companyId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetProfitCenterList?CompanyId=" + companyId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getPPRList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetPPRList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getAdjudicationStatusList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstScnAdjudicationStatusList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getOrderSummaryList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetOrderSummaryList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getAppealPreferredList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetAppealPreferredList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getCaseStatusList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetMstScnCaseStatusList?moduleId=" +
      moduleId +
      "&subModuleId=" +
      subModuleId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//? Form Get Methods
export function getSCNFormDetailsPart1(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNGridDetailPart1?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSCNGridDetailPart2(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNGridDetailPart2?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSCNGridDetailPart3(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNGridDetailPart3?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSCNFormDetailPart3(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNFormDataPart3?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSCNFormDetailPart4(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNFormDataPart4?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function getSCNFormDetailPart5(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/IdtSCN/GetIdtSCNFormDataPart5?ToolCaseId=" + toolcaseId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//? Post Methods
export function SaveSCNpart1Data(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtSCN/SaveIdtSCNFormDetails_Part1", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveSCNpart2Data(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtSCN/SaveIdtSCNGridDetails_Part2", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveSCNpart3Data(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtSCN/SaveIdtSCNGridDetails_Part3", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveSCNpart3FormData(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtSCN/SaveIdtSCNFormDetails_Part3", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveSCNpart4Data(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtSCN/SaveIdtSCNFormDetails_Part4", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
export function SaveSCNpart5Data(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  return fetch(baseApiURL + "/IdtSCN/SaveIdtSCNFormDetails_Part5", options)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
