export const authUserAction = (gid) => {
  return {
    type: "myIDLogin",
    payLoad: gid,
  };
};
export const saveAccessTokenAction = (token) => {
  return {
    type: "saveAccessToken",
    payLoad: token,
  };
};
export const storeUserDetailsAction = (userData) => {
  return {
    type: "storeUserData",
    payLoad: userData,
  };
};
export const storeUpdatePotentialResponse = (response) => {
  return {
    type: "updatePotential",
    payLoad: 1,
  };
};
export const storeUpdateBudgetResponse = (response) => {
  return {
    type: "updateBudget",
    payLoad: 2,
  };
};
export const storeUpdateManualIndirectResponse = (response) => {
  return {
    type: "updateManualIndirect",
    payLoad: 3,
  };
};
export const errorMessageAction = (errorMessage) => {
  return {
    type: "errorMessage",
    payLoad: errorMessage,
  };
};
export const saveVatCstIdAction = (id) => {
  return {
    type: "vatcstId",
    payLoad: id,
  };
};
export const saveAssesmentStatusValueAction = (value) => {
  return {
    type: "assesmentStatusValue",
    payLoad: value,
  };
};

export const storeIssueDetailsAction = (issueData) => {
  return {
    type: "storeIssueData",
    payLoad: issueData,
  };
};

export const saveEventToolCaseIdAction = (value) => {
  return {
    type: "eventToolCaseId",
    payLoad: value,
  };
};

export const saveFromDate = (date) => {
  return {
    type: "fromDate",
    payLoad: date,
  };
};

export const saveMetaData = (id) => {
  return {
    type: "metadataId",
    payLoad: id,
  };
};

export const storeRefundIssueDetailsAction = (issueData) => {
  return {
    type: "storeRefundIssueData",
    payLoad: issueData,
  };
};

export const storeIsRefundCase = (value) => {
  return {
    type: "refundCaseValue",
    payLoad: value,
  };
};

export const storeToolCaseNo = (value) => {
  return {
    type: "toolCaseNo",
    payLoad: value,
  };
};

export const storeRefundCaseStatus = (value) => {
  return {
    type: "isRefundCaseClosed",
    payLoad: value,
  };
};

export const saveBearerTokenAction = (token) => {
  return {
    type: "saveBearerToken",
    payLoad: token,
  };
};
export const saveUIDAction = (token) => {
  return {
    type: "saveUID",
    payLoad: token,
  };
};

export const storeLandingMenuItemsAction = (menuItems) => {
  return {
    type: "saveLandingMenuItems",
    payLoad: menuItems,
  };
};
