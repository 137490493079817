import { StayCurrentLandscape } from "@material-ui/icons";
import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

//! Get Methods
export async function getEventActionList(
  siteToken,
  bearerToken,email,gid,roleID,roleName,uid,
  moduleId,
  subModuleId,
  toolcaseId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken, },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Event/GetEventActionTypes?moduleID=" +
        moduleId +
        "&subModuleID=" +
        subModuleId +
        "&toolCaseID=" +
        toolcaseId,

      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getEventActionByList(siteToken,bearerToken,email,gid,roleID,roleName,uid,subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken},
  };
  try {
    const response = await fetch(
      baseApiURL + "/Event/GetEventActionBy?subModuleID=" + subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getEventsList(siteToken,bearerToken,email,gid,roleID,roleName,uid, toolcaseId, eventID) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Event/GetEventDetails?toolCaseID=" +
        toolcaseId +
        "&eventId=" +
        eventID,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getEnqIssuesList(siteToken, id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/IdtEnquiry/GetEnquiryIssuePart1?toolCaseId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getDocumentModeList(siteToken,
  bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Event/GetEventDocumentMode",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getDocumentTemplateList(siteToken,
  bearerToken,email,gid,roleID,roleName,uid, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Event/GetEventCommunicationType?subModuleID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getDependantSubModuleList(
  siteToken,
  bearerToken,email,gid,roleID,roleName,uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Master/GetSubModuleList?moduleId=" +
        moduleId +
        "&subModuleId=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getIssueLevelAllData(siteToken,
  bearerToken,email,gid,roleID,roleName,uid,id,subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Common/GetIssueLevelDetails?id=" +
        id +
        "&subModuleId=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function updateToScnModule(siteToken,
  bearerToken,email,gid,roleID,roleName,uid,toolcaseId, subModuleId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken},
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/IdtEnquiry/GetEnquiryIssueToSCN?toolCaseId=" +
        toolcaseId +
        "&subModuleId=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function CheckModuleProceedStatus(
  siteToken,
  bearerToken,email,gid,roleID,roleName,uid,
  toolcaseId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken, 
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken
  },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/IdtEnquiry/GetEnquiryIssue?toolCaseId=" +
        toolcaseId +
        "&subModuleId=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getModuleWiseIssuesList(
  siteToken,
  bearerToken,email,gid,roleID,roleName,uid,
  toolcaseId,
  submoduleId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Common/GetUploadIssues?toolCaseId=" +
        toolcaseId +
        "&subModuleId=" +
        submoduleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//GetRefundIssuesWorkflow

export async function getModuleWiseIssuesListInRefund(
  siteToken,
  bearerToken,email,gid,roleID,roleName,uid,
    refundCaseId,
    
) {
    const options = {
        method: "get",
        headers: { Authorization: "Bearer " + bearerToken ,
        UserEmail: email,
        Gid: gid,
        Roleid: roleID,
        RoleName: roleName,
        userId: uid,
        AzureToken: siteToken},
    };
    try {
        const response = await fetch(
            baseApiURL +
            "/Refund/GetRefundIssuesWorkflow?refundCaseId=" +
            refundCaseId,
           
            options
        );
        const data = await response.json();
        return data.result;
    } catch (error) {
        console.log("Error : ", error);
    }
}

export async function getSectionTypeList(siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Event/GetSectionDocumentType",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getAdjudicatingAuthorityList(
  siteToken,bearerToken,email,gid,roleID,roleName,uid,
  moduleID,
  subModuleId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken ,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Master/GetAdjudicatingAuthorityList?moduleId=" +
        moduleID +
        "&subModuleID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getRemandAuthorityList(
  siteToken,bearerToken,email,gid,roleID,roleName,uid,
  moduleID,
  subModuleId,
  originId,
  currentStageId,
  issueID
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Master/GetRemandAuthorityList?moduleId=" +
        moduleID +
        "&subModuleID=" +
        subModuleId +
        "&caseOrigionId=" +
        originId +
        "&currentStageId=" +
        currentStageId +
        "&IssueId=" +
        issueID,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getAppealStageList(
  siteToken,bearerToken,email,gid,roleID,roleName,uid,
  moduleID,
  subModuleId,
  statusId,
  currentStageId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Master/GetAppealStageList?moduleId=" +
        moduleID +
        "&subModuleID=" +
        subModuleId +
        "&issueStatusId=" +
        statusId +
        "&currentStageId=" +
        currentStageId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getIssueHistoryList( siteToken,bearerToken,email,gid,roleID,roleName,uid,toolcaseId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Common/GetIssueHistory?toolCaseID=" + toolcaseId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getIssueDetailList(siteToken,bearerToken,email,gid,roleID,roleName,uid, toolcaseId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Common/GetIssueDetail?toolCaseId=" + toolcaseId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetSectionUnderDocumentIssued(siteToken,bearerToken,email,gid,roleID,roleName,uid, toolcaseId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken  },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Event/GetSectionUnderDocumentIssued?toolCaseId=" +
        toolcaseId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetLiabilityAcceptedList(
  siteToken,bearerToken,email,gid,roleID,roleName,uid,
  moduleId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken},
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Master/GetLiabilityAccepted?moduleId=" +
        moduleId +
        "&subModuleId=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getDocTypeList(siteToken,bearerToken,email,gid,roleID,roleName,uid, subModuleId, actionId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken ,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken},
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Event/GetEventDocumentType?subModuleID=" +
        subModuleId +
        "&actionTypeID=" +
        actionId,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//! Post Methods

export async function InsertEventDetails(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL + "/Event/InsertEventDetails",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function UploadEnquiryIssues(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL + "/IdtEnquiry/SaveEnquiryIssuePart1",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function addIssueLevelDetails(formData, siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Common/SaveOrUpdateIssueLevelDetails",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function UploadModuleWiseIssues(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(baseApiURL + "/Common/UploadIssues", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export function addCfrNumber(siteToken,bearerToken,email,gid,roleID,roleName,uid, id, cfrNo) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
  };
  return fetch(
    baseApiURL +
      "/Common/SaveOrUpdateCFRNumber?metaDataID=" +
      id +
      "&cfRNumber=" +
      cfrNo,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

//! Delete and Update events api
export async function UpdateEvents(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid,id,) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL + "/Event/UpdateEventDetails",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function DeleteEventById(siteToken,bearerToken,email,gid,roleID,roleName,uid, id) {
  const options = {
    method: "delete",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Event/DeleteEventDetails?eventID=" + id,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function UploadFormAdjustmentsData(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL + "/Appeal/SaveFormAdjustmentData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//! Download Report

export async function getLastUploadedIssues(
  siteToken,bearerToken,email,gid,roleID,roleName,uid,
  toolcaseId,
  subModuleId
) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Common/GetUploadTemplateUrl?toolCaseID=" +
        toolcaseId +
        "&subModuleID=" +
        subModuleId,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//! Form Adjustments

export async function InsertFormAdjustment(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(
      baseApiURL + "/Appeal/SaveFormAdjustmentData",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function GetFormAdjustmentList(siteToken,bearerToken,email,gid,roleID,roleName,uid, issueId) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Appeal/GetFormAdjustmentData?issueID=" + issueId,

      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export function RunFormAdjustmentCalculation(siteToken,bearerToken,email,gid,roleID,roleName,uid, date) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken 
    },
  };
  return fetch(
    baseApiURL + "/Appeal/RunFormAdjustmentCalculation?quarterEndDate=" + date,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.status;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}