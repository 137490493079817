import { baseApiURL } from "../../Utilities/Utility";
import axios from "axios";

//! Get Methods
export async function GetReportModuleList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetReportModule?reportCategory=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function getCFRReportList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  companyId,
  startDate,
  endDate,
  value
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Common/GetCFaRReport?companyId=" +
        companyId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&cFarReport=" +
        value,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//* Company
export function getCompanyListByUserId(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  userId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetReportCompanyList?userId=" + userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//* Division
export function getDivisionListByUserId(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  userId,
  companyId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetReportCompanyDivision?userId=" +
      userId +
      "&companyIds=" +
      companyId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//* BU
export function getBUListByUserId(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  userId,
  companyId,
  divisionId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL +
      "/Master/GetReportDivisionBU?userId=" +
      userId +
      "&companyIds=" +
      companyId +
      "&divisionIds=" +
      divisionId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//* Location
export function getReportLocationList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  companyId
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  return fetch(
    baseApiURL + "/Master/GetReportLocation?&companyId=" + companyId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export async function getIssueReportFile(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Common/GetIssueDetailReport",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getRefundIssueReportFile(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  formData
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(
      baseApiURL + "/Refund/GetRefundIssueReport",
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export function getSummaryReportFile(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  formData
) {
  const options = {
    responseType: "arraybuffer",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  return axios
    .post(baseApiURL + "/Common/GetSummaryReport", formData, options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}

export async function getRefundSummaryReportFile(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  companyId,
  cutOffMonth,
  cutOffYear,
  fromDate,
  toDate,
  location
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL +
        "/Refund/GetRefundSummaryReport?companyID=" +
        companyId +
        "&cutoffMonth=" +
        cutOffMonth +
        "&cuttofyear=" +
        cutOffYear +
        "&fromDate=" +
        fromDate +
        "&toDate=" +
        toDate +
        "&stateId=" +
        location,
      options
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
