import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
//import { baseAddressURL } from "../../../Utilities/Utility";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getSubModuleList } from "../../Redux/API/api_admin";
import {
  GetSectionUnderDocumentIssued,
  InsertEventDetails,
  UpdateEvents,
  getAdjudicatingAuthorityList,
  getDependantSubModuleList,
  getDocTypeList,
  getDocumentModeList,
  getDocumentTemplateList,
  getEventActionByList,
  getEventActionList,
  getEventsList,
  getSectionTypeList,
} from "../../Redux/API/api_events";
import { GetGeneralEnqFormDetails } from "../../Redux/API/api_createEnquiry";
import { IDTModuleId } from "../../Utilities/Utility";
import { saveEventToolCaseIdAction } from "./../../Redux/Actions/actions";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import "./toolcase.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1rem`,
    marginBottom: `0.2em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    paddingBottom: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
  mandatoryNote: {
    color: `red !important`,
    fontSize: `14px`,
    fontStyle: `italic`,
    display: `flex`,
    justifyContent: `flex-end`,
    marginBottom: `0`,
  },
}));

export default function AddEvents() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add Events");
  //! min date
  const fromdate = useSelector((state) => state.saveDateValidatorReducer.value);
  const [minDate, setMinDate] = useState(new Date(fromdate));

  let [isLoader, setIsLoader] = useState(false);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  const toolCaseId = useSelector(
    (state) => state.saveEventToolCaseIdReducer.value
  );
  const toolCaseDetails = useSelector(
    (state) => state.saveToolCaseNo.toolCaseNo
  );
  const [draftNo, setDraftNo] = useState("");
  const [toolCaseNo, setToolCaseNo] = useState("");
  var encryptedId = getURLParameter("id");
  const [eventId, setEventId] = useState("");
  const [subModuleValue, setSubModuleValue] = useState("");
  const [depSubModule, setDepSubModule] = useState([]);
  const [depSubModuleValue, setDepSubModuleValue] = useState("");
  //* Input Hooks
  const [eventModule, setEventModule] = useState([]);
  const [eventModuleValue, setEventModuleValue] = useState("");
  const [eventAction, setEventAction] = useState([]);
  const [eventActionValue, setEventActionValue] = useState("");
  const [eventUser, setEventUser] = useState([]);
  const [eventUserValue, setEventUserValue] = useState("");
  const [eventActionDate, setEventActionDate] = useState(null);
  const [eventActionDateValue, setEventActionDateValue] = useState(null);
  const [docMode, setDocMode] = useState([]);
  const [docModeValue, setDocModeValue] = useState("");
  const [docTemplate, setDocTemplate] = useState([]);
  const [docTemplateValue, setDocTemplateValue] = useState("");
  const [docType, setDocType] = useState([]);
  const [docTypeValue, setDocTypeValue] = useState("");
  const [docNumber, setDocNumber] = useState("");
  const [docDate, setDocDate] = useState(null);
  const [docDateValue, setDocDateValue] = useState(null);
  const [selectedDocFile, setSelectedDocFile] = useState(null);
  const [inputDocFile, setInputDocFile] = useState(Date.now);
  const [eventDueDate, setEventDueDate] = useState(null);
  const [eventDueDateValue, setEventDueDateValue] = useState(null);
  const [authority, setAuthority] = useState([]);
  const [authorityValue, setAuthorityValue] = useState("");
  const [remarks, setRemarks] = useState("");
  const [sectionType, setSectionType] = useState([]);
  const [sectionTypeValue, setSectionTypeValue] = useState("");
  const [downloadPath, setDownloadPath] = useState("");
  const [eventName, setEventName] = useState("");
  const [docAmount, setDocAmount] = useState("");
  const [docAmountHelper, setDocAmountHelper] = useState("");
  const [isSectionPresent, setIsSectionPresent] = useState(false);
  const [dueDateHelper, setDueDateHelper] = useState("");
  const [documentIssuedBy, setDocumentIssuedBy] = useState("");
  const [eventValidUpTo, setEventValidUpTo] = useState(null);
  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (alertType === "success") {
      window.location.href =
        "/ToolCaseRegistration?id=" + btoaSecure(toolCaseId);
    }
  };

  const handleEventModule = (e) => {
    setEventActionValue("");
    setDocTemplateValue("");
    //setSectionTypeValue("");
    setAuthorityValue("");
    setEventModuleValue(e.target.value);
    getEventActionType(IDTModuleId, e.target.value, toolCaseId);
    getDocumentTemplate(e.target.value);
    //getSectionList(e.target.value);
    getAdjudicatingAuthority(IDTModuleId, e.target.value);
    getEventUser(e.target.value);
  };
  const handleEventAction = (e) => {
    setDocumentIssuedBy("");
    setEventValidUpTo(null);
    setEventActionValue(e.target.value);
    getDocType(eventModuleValue, e.target.value);
    var eventName = eventAction.filter(
      (c) => c.actionTypeId === e.target.value
    );
    setEventName(eventName[0].actionTypeName);
    if (eventName[0].actionTypeName === "Personal Hearing") {
      setDocModeValue("");
    }
    if (
      eventName[0].actionTypeName.toLowerCase() ===
        "receive department communication" ||
      eventName[0].actionTypeName.toLowerCase() ===
        "reply to department communication" ||
      eventName[0].actionTypeName.toLowerCase() === "receive scn/drc-01" ||
      eventName[0].actionTypeName.toLowerCase() === "receive mov-06 form" ||
      eventName[0].actionTypeName.toLowerCase() === "receive mov-07 form" ||
      eventName[0].actionTypeName.toLowerCase() === "receive reminder notice" ||
      eventName[0].actionTypeName.toLowerCase() ===
        "receive assessment letter" ||
      eventName[0].actionTypeName.toLowerCase() === "personal hearing notice" ||
      eventName[0].actionTypeName.toLowerCase() === "receive order" ||
      eventName[0].actionTypeName.toLowerCase() === "receive order/drc-07" ||
      eventName[0].actionTypeName.toLowerCase() === "receive order/mov-09" ||
      eventName[0].actionTypeName === "File Extension Letter" ||
      eventName[0].actionTypeName === "Receive Reminder Notice"
    ) {
      setDueDateHelper("Please Select A Date");
    } else {
      setDueDateHelper("");
    }
    setDocAmount("");
  };
  const handleEventUser = (e) => {
    setEventUserValue(e.target.value);
  };
  const handleEventActionDate = (date) => {
    let newDate = JSON.stringify(date);
    setEventActionDate(date.toISOString());
    setEventActionDateValue(date);
  };
  const handleDocMode = (e) => {
    setDocModeValue(e.target.value);
  };
  const handleDocTemplate = (e) => {
    setDocTemplateValue(e.target.value);
  };
  const handleDocNumber = (e) => {
    setDocNumber(e.target.value);
  };
  const handleDocDate = (date) => {
    let newDate = JSON.stringify(date);
    setDocDate(newDate.slice(1, 11));
    setDocDateValue(date.toISOString());
  };
  const handleChangeDocFile = (event) => {
    setSelectedDocFile(event.target.files[0]);
  };
  const handleEventDueDate = (date) => {
    setEventDueDateValue(date.toISOString());
    if (date !== null) {
      var newDate = date.toString();
      if (newDate && newDate !== "Invalid Date") {
        setDueDateHelper("");
      }
    }
  };
  const handleRemarks = (e) => {
    setRemarks(e.target.value);
  };
  const handleAuthority = (e) => {
    setAuthorityValue(e.target.value);
  };
  const handleSectionType = (e) => {
    setSectionTypeValue(e.target.value);
  };
  const handleDocAmount = (e) => {
    let valid;
    setDocAmount(e.target.value);
    valid = /^((\d+(\.\d*)?)|(\.\d+))$/.test(e.target.value);
    if (e.target.value === "") {
      setDocAmountHelper("Cannot be left blank");
    } else if (!valid) {
      setDocAmountHelper("Numbers only");
    } else if (parseInt(e.target.value) === 0) {
      setDocAmountHelper("Cannot be 0");
    } else {
      setDocAmountHelper("");
    }
  };
  const handleDocType = (e) => {
    setDocTypeValue(e.target.value);
  };

  const handleDocumentIssuedBy = (e) => {
    setDocumentIssuedBy(e.target.value);
  };
  const handleEventValidUpTo = (date) => {
    setEventValidUpTo(date.toISOString());
  };
  //! Dropdown Functions
  function getEventModule(moduleID) {
    getSubModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleID)
      .then((response) => {
        setEventModule(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getEventUser(submoduleId) {
    getEventActionByList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,submoduleId)
      .then((response) => {
        setEventUser(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getEventActionType(moduleId, subModuleId, toolcaseId) {
    getEventActionList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,moduleId, subModuleId, toolcaseId)
      .then((response) => {
        setEventAction(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getDocumentMode() {
    getDocumentModeList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setDocMode(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getDependantSubModules(moduleId, subModuleId) {
    getDependantSubModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleId, subModuleId)
      .then((response) => {
        setDepSubModule(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getDocumentTemplate(subModuleId) {
    getDocumentTemplateList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,subModuleId)
      .then((response) => {
        setDocTemplate(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getSectionList() {
    getSectionTypeList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,)
      .then((response) => {
        setSectionType(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getEvents(toolcaseId, eventId) {
    getEventsList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, toolcaseId, eventId)
      .then((response) => {
        setEventModuleValue(
          response[0].subModuleId === null ? "" : response[0].subModuleId
        );
        getAdjudicatingAuthority(IDTModuleId, response[0].subModuleId);
        getEventActionType(IDTModuleId, response[0].subModuleId, toolCaseId);
        getDocumentTemplate(response[0].subModuleId);
        setEventActionValue(
          response[0].eventType === null ? "" : response[0].eventType
        );
        getEventUser(response[0].subModuleId);
        setEventUserValue(
          response[0].eventActionBy === 0 ? "" : response[0].eventActionBy
        );
        var eventActionDate = JSON.stringify(response[0].eventDate);
        setEventActionDate(
          eventActionDate !== "null" ? response[0].eventDate : null
        );
        setEventActionDateValue(
          response[0].eventDate === "null" ? null : response[0].eventDate
        );
        //getSectionList(response[0].subModuleId);
        setSectionTypeValue(
          response[0].sectionDocumentType === null
            ? ""
            : response[0].sectionDocumentType
        );
        setDocModeValue(
          response[0].documentMode === null ? "" : response[0].documentMode
        );
        getDocumentTemplate(response[0].subModuleId);

        setDocTypeValue(
          response[0].documentType === null ? "" : response[0].documentType
        );
        setDocNumber(
          response[0].documentNo === null ? "" : response[0].documentNo
        );
        var docDate = JSON.stringify(response[0].documentDate);
        setDocDateValue(
          response[0].documentDate === "null" ? null : response[0].documentDate
        );
        setDocDate(docDate !== "null" ? docDate.slice(1, 11) : null);

        var eventDueDate = JSON.stringify(response[0].dueDate);
        setEventDueDate(
          eventDueDate !== "null" ? eventDueDate.slice(1, 11) : null
        );
        setEventDueDateValue(
          response[0].dueDate === "null" ? null : response[0].dueDate
        );
        setAuthorityValue(
          response[0].adjudicatingAuthority === null
            ? ""
            : response[0].adjudicatingAuthority
        );
        setRemarks(
          response[0].eventRemarks === null ? "" : response[0].eventRemarks
        );
        setDownloadPath(
          response[0].documentPath === null ? "" : response[0].documentPath
        );
        setEventName(
          response[0].eventTypeName === null ? "" : response[0].eventTypeName
        );
        setDocAmount(
          response[0].documentAmount === null ? "" : response[0].documentAmount
        );
        setDocTemplateValue(
          response[0].communicationType === null
            ? ""
            : response[0].communicationType
        );
        setDocumentIssuedBy(
          response[0].documentIssuedBy === null
            ? ""
            : response[0].documentIssuedBy
        );
        setEventValidUpTo(
          response[0].eventValidUpTo === null ? "" : response[0].validUpTo
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getAdjudicatingAuthority(moduleId, subModuleId) {
    getAdjudicatingAuthorityList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleId, subModuleId)
      .then((response) => {
        setAuthority(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getSavedSection(id) {
    setIsLoader(true);
    GetSectionUnderDocumentIssued(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id
    )
      .then((response) => {
        setSectionTypeValue(response.sectionDocumentType);
        if (response.sectionDocumentType !== null) {
          setIsSectionPresent(true);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
      });
  }

  function getDocType(subModuleId, actionId) {
    getDocTypeList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, subModuleId, actionId)
      .then((response) => {
        setDocType(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //!Decrypt methods
  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }
  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }

  function getToolCaseData(id) {
    setIsLoader(true);
    GetGeneralEnqFormDetails(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id)
      .then((response) => {
        setSubModuleValue(response[0].subModuleId);
        getDependantSubModules(1, response[0].subModuleId);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoader(false);
      });
  }

  //! Post Method

  function submitEventDetails() {
    setIsLoader(true);
    var formdataPart1 = new FormData();
    formdataPart1.append("ToolCaseId", toolCaseId);
    formdataPart1.append(
      "SubModuleId",
      eventModuleValue ? eventModuleValue : ""
    );
    formdataPart1.append("EventType", eventActionValue ? eventActionValue : "");
    formdataPart1.append("EventActionBy", eventUserValue ? eventUserValue : "");
    formdataPart1.append("EventDate", eventActionDate ? eventActionDate : "");
    formdataPart1.append(
      "CommunicationType",
      docTemplateValue ? docTemplateValue : ""
    );
    formdataPart1.append("DocumentMode", docModeValue ? docModeValue : "");
    formdataPart1.append(
      "SectionDocumentType",
      sectionTypeValue ? sectionTypeValue : ""
    );
    formdataPart1.append("DocumentNo", docNumber ? docNumber : "");
    formdataPart1.append("DocumentDate", docDateValue ? docDateValue : "");
    formdataPart1.append("EventDocuments", selectedDocFile);
    formdataPart1.append("DueDate", eventDueDateValue ? eventDueDateValue : "");
    formdataPart1.append(
      "AdjudicatingAuthority",
      authorityValue ? authorityValue : ""
    );
    formdataPart1.append(
      "DocumentAmount",
      docAmount === "" ? 0 : parseInt(docAmount)
    );
    formdataPart1.append(
      "DocumentIssuedBy",
      documentIssuedBy === "" ? "" : documentIssuedBy
    );
    formdataPart1.append("ValidUpTo", eventValidUpTo ? eventValidUpTo : "");
    formdataPart1.append("EventRemarks", remarks ? remarks : "");
    formdataPart1.append("DocumentType", docTypeValue ? docTypeValue : "");
    formdataPart1.append("UserID", userDetails.userId);
    InsertEventDetails(formdataPart1, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status === 1) {
          handleSnackOpen("Event Details Added Successfully.", "success");
          setIsLoader(false);
        } else if (response.status === 4) {
          setIsLoader(false);
          handleSnackOpen(response.result, "error");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Event Details.", "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }

  function updateEventDetails() {
    setIsLoader(true);
    var formdataPart1 = new FormData();
    formdataPart1.append("ToolCaseId", toolCaseId);
    formdataPart1.append("EventId", eventId);
    formdataPart1.append(
      "SubModuleId",
      eventModuleValue ? eventModuleValue : ""
    );
    formdataPart1.append("EventType", eventActionValue ? eventActionValue : "");
    formdataPart1.append("EventActionBy", eventUserValue ? eventUserValue : "");
    formdataPart1.append("EventDate", eventActionDate ? eventActionDate : "");
    formdataPart1.append(
      "CommunicationType",
      docTemplateValue ? docTemplateValue : ""
    );
    formdataPart1.append("DocumentMode", docModeValue ? docModeValue : "");
    formdataPart1.append(
      "SectionDocumentType",
      sectionTypeValue ? sectionTypeValue : ""
    );
    formdataPart1.append("DocumentNo", docNumber ? docNumber : "");
    formdataPart1.append("DocumentDate", docDateValue ? docDateValue : "");
    formdataPart1.append("EventDocuments", selectedDocFile);
    formdataPart1.append("DueDate", eventDueDateValue ? eventDueDateValue : "");
    formdataPart1.append(
      "AdjudicatingAuthority",
      authorityValue ? authorityValue : ""
    );
    formdataPart1.append("EventRemarks", remarks ? remarks : "");
    formdataPart1.append("DocumentType", docTypeValue ? docTypeValue : "");
    formdataPart1.append(
      "DocumentAmount",
      docAmount === "" ? 0 : parseInt(docAmount)
    );
    formdataPart1.append(
      "DocumentIssuedBy",
      documentIssuedBy === "" ? "" : documentIssuedBy
    );
    formdataPart1.append("ValidUpTo", eventValidUpTo ? eventValidUpTo : "");
    formdataPart1.append("UserID", userDetails.userId);
    UpdateEvents(formdataPart1, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,)
      .then((response) => {
        if (response.status === 1) {
          handleSnackOpen("Event Details Updated Successfully.", "success");
          setIsLoader(false);
        } else if (response.status === 4) {
          setIsLoader(false);
          handleSnackOpen(response.result, "error");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Event Details.", "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }

  useEffect(() => {
    getDocumentMode();
    getSectionList();
    getSavedSection(toolCaseId);
    setToolCaseNo(toolCaseDetails.approved);
    setDraftNo(toolCaseDetails.draft);
    if (toolCaseId !== 0 && encryptedId) {
      getEvents(toolCaseId, atobSecure(encryptedId));
      setEventId(atobSecure(encryptedId));
    }
  }, [siteToken, encryptedId, toolCaseId !== 0]);

  useEffect(() => {
    if (toolCaseId !== 0) {
      getToolCaseData(toolCaseId);
    }
  }, [toolCaseId !== 0]);

  useEffect(() => {
    if (eventModuleValue && eventActionValue) {
      getDocType(eventModuleValue, eventActionValue);
    }
  }, [eventModuleValue, eventActionValue]);

  return (
    <div className="upload-screen">
      <Grid container spacing={2} direction="row" className={classes.subGrid}>
        <Grid item container xs={12} className="">
          <Grid item xs={12}>
            <h4 className="m-0">
              Tool Case No:- {toolCaseNo ? toolCaseNo : draftNo}
            </h4>
          </Grid>
          <Grid item xs={3} className="d-flex jc-flex-start">
            <h4 className={classes.sectionHeader}>{pageHeader}</h4>
          </Grid>

          <Grid item xs={9} className="d-flex jc-flex-end">
            {(eventModuleValue === 2 &&
              eventName === "Make Payment through Bank Guarantee/MOV-08" &&
              !docAmount) ||
            (eventName === "Final Payment" && !docAmount) ||
            (eventName === "Make Predeposit" && !docAmount) ||
            (eventName === "Request for Refund" && !docAmount) ? (
              <p className={classes.mandatoryNote}>Add Document Amount</p>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Event/Action Module"
            id="businessUnitCode"
            name="businessUnitCode"
            className=""
            fullWidth
            value={eventModuleValue}
            onChange={handleEventModule}
            select
            variant="filled">
            {depSubModule.map((item) => (
              <MenuItem key={item.subModuleId} value={item.subModuleId}>
                {item.subModuleName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="Event/Action Type"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={eventActionValue}
            onChange={handleEventAction}
            variant="filled">
            {eventAction.map((item) => (
              <MenuItem key={item.actionTypeId} value={item.actionTypeId}>
                {item.actionTypeName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Event/Action By"
            id="masterKey"
            name="Master Key"
            className=""
            select
            required
            value={eventUserValue}
            onChange={handleEventUser}
            fullWidth
            variant="filled">
            {eventUser.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Event/Action Date"
              format="dd/MM/yyyy"
              fullWidth
              required
              disableFuture={eventModuleValue === 2 ? true : false}
              value={eventActionDate}
              onChange={handleEventActionDate}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} md={3} className="">
          <TextField
            label="Section Under Which Document Issued"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={sectionTypeValue}
            onChange={handleSectionType}
            disabled={isSectionPresent}
            variant="filled">
            {sectionType.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {eventName !== "Personal Hearing" ? (
          <Grid item xs={12} md={3} className="">
            <TextField
              label="Document Mode"
              id="businessUnitName"
              name="businessUnitName"
              className=""
              fullWidth
              select
              value={docModeValue}
              onChange={handleDocMode}
              variant="filled">
              {docMode.map((item) => (
                <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : (
          <></>
        )}
        {eventModuleValue === 1 ? (
          <Grid item xs={12} md={3} className="">
            <TextField
              label="Communication Type"
              id="businessUnitName"
              name="businessUnitName"
              className=""
              fullWidth
              select
              value={docTemplateValue}
              onChange={handleDocTemplate}
              variant="filled">
              {docTemplate.map((item) => (
                <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : (
          <></>
        )}

        <Grid item xs={12} md={3} className="">
          <TextField
            label="Document Type"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={docTypeValue}
            onChange={handleDocType}
            variant="filled">
            {docType.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.documentType}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Document No"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            value={docNumber}
            onChange={handleDocNumber}
            variant="filled"
          />
        </Grid>
        {(eventModuleValue === 2 &&
          eventName === "Make Payment through Bank Guarantee/MOV-08") ||
        eventName === "Final Payment" ||
        eventName === "Make Predeposit" ||
        eventName === "Request for Refund" ||
        eventName.toLocaleLowerCase() === "make payment through pmt/drc-03" ? (
          <Grid item xs={12} md={3}>
            <TextField
              label="Document Amount"
              id=""
              name=""
              className=""
              fullWidth
              variant="filled"
              value={docAmount}
              helperText={docAmountHelper}
              error={docAmountHelper ? true : false}
              onChange={handleDocAmount}
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={3} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Document Date"
              format="dd/MM/yyyy"
              fullWidth
              disableFuture={eventModuleValue === 2 ? true : false}
              value={docDateValue}
              onChange={handleDocDate}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Upload Document"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            type="file"
            key={inputDocFile}
            onChange={handleChangeDocFile}
            variant="filled"
            InputLabelProps={{ shrink: true }}
            helperText="All File Types Are Allowed"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    className="p-0"
                    target="_blank"
                    href={downloadPath}
                    disabled={downloadPath ? false : true}>
                    <CloudDownloadIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="fromPeriod"
              className="datepicker"
              label="Due Date of Event Action"
              format="dd/MM/yyyy"
              fullWidth
              value={eventDueDateValue}
              onChange={handleEventDueDate}
              helperText={dueDateHelper}
              error={dueDateHelper ? true : false}
              minDate={minDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{ readOnly: true }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        {eventName === "Make Payment through Bank Guarantee/MOV-08" ? (
          <>
            <Grid item xs={12} md={3}>
              <TextField
                label="BG Issued By (Bank Name)"
                id=""
                name=""
                className=""
                fullWidth
                variant="filled"
                value={documentIssuedBy}
                onChange={handleDocumentIssuedBy}
              />
            </Grid>
            <Grid item xs={12} md={3} className="">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  id="fromPeriod"
                  className="datepicker"
                  label="Document Valid UpTo"
                  format="dd/MM/yyyy"
                  fullWidth
                  value={eventValidUpTo}
                  onChange={handleEventValidUpTo}
                  minDate={minDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputProps={{ readOnly: true }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={3} className="">
          <TextField
            label="Adjudicating Authority"
            id="businessUnitName"
            name="businessUnitName"
            className=""
            fullWidth
            select
            value={authorityValue}
            onChange={handleAuthority}
            variant="filled"
            disabled={
              eventModuleValue !== 3 &&
              eventModuleValue !== 5 &&
              eventModuleValue !== 2
            }>
            {authority.map((item) => (
              <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Remarks"
            id=""
            name=""
            className=""
            fullWidth
            variant="filled"
            value={remarks}
            onChange={handleRemarks}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        className="upload-block d-flex jc-center pb-2 mt-1">
        <Grid item xs={12} md={1} className="align-center width-100">
          {encryptedId ? (
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={updateEventDetails}
              disabled={
                (eventModuleValue === 2 &&
                  eventName === "Make Payment through Bank Guarantee/MOV-08" &&
                  !docAmount) ||
                docAmountHelper ||
                dueDateHelper ||
                !eventUserValue ||
                !eventActionDate
                  ? true
                  : false
              }
              fullWidth>
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={submitEventDetails}
              disabled={
                (eventModuleValue === 2 &&
                  eventName === "Make Payment through Bank Guarantee/MOV-08" &&
                  !docAmount) ||
                (eventName === "Final Payment" && !docAmount) ||
                (eventName === "Make Predeposit" && !docAmount) ||
                (eventName === "Request for Refund" && !docAmount) ||
                docAmountHelper ||
                dueDateHelper ||
                !eventUserValue ||
                !eventActionDate
                  ? true
                  : false
              }
              fullWidth>
              Submit
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/ToolCaseRegistration?id=" + btoaSecure(toolCaseId)}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
