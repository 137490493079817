import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, MenuItem, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import SnackbarCustom from "../../../Components/Snackbar/Snackbar";
import Loader from "../../../Components/Loader/Loader";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  addPOMaster,
  editPOMaster,
  getConsultantNameList,
  getPOMasterList,
} from "../../../Redux/API/api_payment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: `90%`,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  header: {
    display: `flex`,
    justifyContent: `center`,
  },
  sectionHeader: {
    marginTop: `1em`,
    marginBottom: `0.8em`,
  },
  sectionHeader2: {
    marginTop: `0.8em`,
    marginBottom: `0.8em`,
    borderBottom: `2px solid #00BCBC`,
  },
  subGrid: {
    boxShadow: `1px 2px 5px 3px #ccc`,
    marginTop: `1rem`,
    padding: `8px`,
    marginBottom: `1rem`,
    borderRadius: `8px`,
  },
}));

export default function AddPO() {
  const classes = useStyles();
  const [pageHeader, setPageHeader] = useState("Add PO Master");
  const [poId, setPoId] = useState(0);
  const [poNo, setPoNo] = useState("");
  const [poNoHelper, setPoNoHelper] = useState("");
  const [poDate, setPODate] = useState(null);
  const [poDateValue, setPODateValue] = useState(null);
  const [consultantName, setConsultantName] = useState([]);
  const [consultantNameValue, setConsultantNameValue] = useState("");
  const [name, setName] = useState("");
  const [vendorCode, setVendorCode] = useState("");
  const [poValue, setPOValue] = useState("");
  const [poValueHelper, setPOValueHelper] = useState("");
  const [poCreatorID, setPOCreatorID] = useState(0);
  const [poCreatorName, setPoCreatorName] = useState("");
  const [statusValue, setStatusValue] = useState(true);
  let [isLoader, setIsLoader] = useState(false);

  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);

  //Snackbar
  const [open, setOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
    if (!encryptedId && alertType === "success") {
      window.location.href = "/poList";
    }
    if (encryptedId && alertType === "success") {
      setPageHeader("Edit PO Master");
      getPO();
    }
  };

  //! Dropdown functions
  const handleChangePoNo = (e) => {
    setPoNo(e.target.value);
    let valid = /^[a-zA-Z0-9 _-]*$/.test(e.target.value);
    if (!valid) {
      setPoNoHelper("Please enter valid values.");
    } else if (e.target.value.length < 1) {
      setPoNoHelper("Minimum 2 characters required");
    } else {
      setPoNoHelper("");
    }
  };

  const handleChangeStatus = (e) => {
    setStatusValue(e.target.value);
  };
  const handlePoDate = (date) => {
    let newDate = JSON.stringify(date);
    setPODate(newDate.slice(1, 11));
    setPODateValue(date);
  };
  const handleChangeConsultant = (e) => {
    setConsultantNameValue(e.target.value);
    var vendorCode = consultantName.filter(
      (c) => c.consultantId === e.target.value
    );
    setVendorCode(vendorCode[0].vendorCode);
    setName(vendorCode[0].consultantName);
  };
  const handlePOValue = (e) => {
    let valid;
    setPOValue(e.target.value);
    valid = /^((\d+(\.\d*)?)|(\.\d+))$/.test(e.target.value);
    if (e.target.value === "") {
      setPOValueHelper("Cannot be left blank");
    } else if (!valid) {
      setPOValueHelper("Numbers only");
    } else if (parseInt(e.target.value) === 0) {
      setPOValueHelper("Cannot be 0");
    } else {
      setPOValueHelper("");
    }
  };
  //! Dropdown Get Methods

  function getConsultant(id) {
    getConsultantNameList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
      .then((response) => {
        setConsultantName(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  var formdata = {
    poNumber: poNo,
    poDate: poDate,
    consultantId: consultantNameValue,
    consultantName: name,
    vendorCode: vendorCode,
    poValue: poValue,
    poCreaterId: poCreatorID,
    poCreaterName: poCreatorName,
    status: statusValue,
    createdBy: userDetails.userId,
  };

  var formdataEdit = {
    poId: poId,
    poNumber: poNo,
    poDate: poDate,
    consultantId: consultantNameValue,
    consultantName: name,
    vendorCode: vendorCode,
    poValue: poValue,
    poCreaterId: poCreatorID,
    poCreaterName: poCreatorName,
    status: statusValue,
    modifiedBy: userDetails.userId,
  };

  //! Post Methods
  function savePO() {
    setIsLoader(true);
    addPOMaster(formdata,siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status === 1) {
          handleSnackOpen("PO Added Successfully.", "success");
          setIsLoader(false);
        } else if (response.status === 5) {
          setIsLoader(false);
          handleSnackOpen("PO Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding PO.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  function editPO() {
    setIsLoader(true);
    editPOMaster(formdataEdit, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        if (response.status === 1) {
          handleSnackOpen("PO Edited Successfully.", "success");
          setIsLoader(false);
        } else if (response.status === 5) {
          setIsLoader(false);
          handleSnackOpen("PO Already Exists.", "warning");
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Editing PO.", "error");
        }
      })
      .catch((error) => {
        alert("error", error);
        console.log("error", error);
      });
  }

  //! Get Method - Master data
  function atobSecure(value) {
    const { atob } = require("abab");
    let arrValue = atob(atob(value)).split(" ");
    let valueActual = arrValue[0];
    return valueActual;
  }

  function getURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split("&");
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split("=");
      var value = "";
      for (var j = 0; j < sParameterName.length; j++) {
        if (sParameterName[j] == sParam) {
          for (var k = j + 1; k < sParameterName.length; k++) {
            value = value + sParameterName[k];
          }
          break;
        }
        //return sParameterName[1];
      }
      return value;
    }
  }
  var encryptedId = getURLParameter("id");

  function getPO() {
    setIsLoader(true);
    getPOMasterList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,atobSecure(encryptedId))
      .then((response) => {
        setPoId(response[0].poId);
        setPoNo(response[0].poNumber);
        setPODate(response[0].poDate);
        setPODateValue(response[0].poDate);
        setConsultantNameValue(response[0].consultantId);
        setName(response[0].consultantName);
        setVendorCode(response[0].vendorCode);
        setPOValue(response[0].poValue);
        setPOCreatorID(response[0].poCreaterId);
        setPoCreatorName(response[0].poCreaterName);
        setStatusValue(response[0].status);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    getConsultant(0);
    if (!encryptedId) {
      setPoCreatorName(userDetails.firstName + " " + userDetails.lastName);
      setPOCreatorID(userDetails.userId);
    }
    if (encryptedId) {
      setPageHeader("Edit PO Master");
      getPO();
    }
  }, [siteToken, encryptedId]);

  return (
    <div className={classes.subGrid}>
      <Grid container className="d-flex jc-space-bt">
        <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start">
          <h3 className={classes.sectionHeader}>{pageHeader}</h3>
        </Grid>
      </Grid>

      <Grid container spacing={2} direction="row" className="upload-block pt-1">
        <Grid item xs={12} md={4} className="">
          <TextField
            label="PO Number"
            id="poNumber"
            name="poNumber"
            className=""
            value={poNo}
            onChange={handleChangePoNo}
            helperText={poNoHelper}
            error={poNoHelper ? true : false}
            fullWidth
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={4} className="">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="poDate"
              className="datepicker"
              label="PO Date"
              format="dd/MM/yyyy"
              fullWidth
              value={poDateValue}
              onChange={handlePoDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={4} className="">
          <TextField
            label="Consultant Name"
            id=""
            name=""
            className=""
            fullWidth
            select
            variant="filled"
            value={consultantNameValue}
            onChange={handleChangeConsultant}>
            {consultantName.map((item) => (
              <MenuItem key={item.consultantId} value={item.consultantId}>
                {item.consultantName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Vendor Code"
            id="masterKey"
            name="Master Key"
            className=""
            value={vendorCode}
            //onChange={handleChangeBUCode}
            fullWidth
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="PO Value"
            id="masterKey"
            name="Master Key"
            className=""
            value={poValue}
            onChange={handlePOValue}
            helperText={poValueHelper}
            error={poValueHelper ? true : false}
            fullWidth
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="PO Creator"
            id="masterKey"
            name="Master Key"
            className=""
            value={poCreatorName}
            //onChange={handleChangeBUCode}
            fullWidth
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={4} className="">
          <TextField
            select
            label="Status"
            id="status"
            name="Status"
            className=""
            fullWidth
            value={statusValue}
            onChange={handleChangeStatus}
            variant="filled">
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        className="upload-block d-flex jc-center pb-2">
        {encryptedId ? (
          <Grid item xs={12} md={1} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={editPO}
              disabled={
                !poNo ||
                !poValue ||
                !consultantNameValue ||
                !poDate ||
                poValueHelper ||
                poNoHelper
              }
              fullWidth>
              Update
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        {!encryptedId ? (
          <Grid item xs={12} md={1} className="align-center width-100">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={savePO}
              disabled={
                !poNo ||
                !poValue ||
                !consultantNameValue ||
                !poDate ||
                poValueHelper ||
                poNoHelper
              }
              fullWidth>
              Submit
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} md={1} className="align-center width-100">
          <Link to={"/poList"}>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              fullWidth>
              Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}

      <>
        <SnackbarCustom
          open={open}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleClose}
        />
      </>
    </div>
  );
}
