import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Grid,
    MenuItem,
    TextField,
    Button,
    IconButton,
    Tooltip,
    InputAdornment,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
    UpdatePaymentDetails,
    getConsultantNameList,
    getNatureOfServiceList,
    getPOBasedOnConsultantList,
    getPOMasterList,
    getPaymentList,
    getRatioTypeList,
    getToolCaseBasedData,
    getToolCaseNoList,
    uploadPaymentDetails,
} from "../../Redux/API/api_payment";
import {
    IDTModuleId,
    baseApiURL,
    tableIconsInputScreen,
} from "../../Utilities/Utility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ViewListIcon from "@material-ui/icons/ViewList";
import RatioUploadTemplate from "../../assets/template/UploadTemplate/RatioUploadTemplate.xlsx";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: `90%`,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        display: `flex`,
        justifyContent: `center`,
    },
    sectionHeader: {
        marginTop: `1em`,
        marginBottom: `0.8em`,
        // borderBottom: `2px solid #00BCBC`,
    },
    sectionHeader2: {
        marginTop: `0.8em`,
        marginBottom: `0.8em`,
        paddingLeft: `0.8rem`,
        //borderBottom: `2px solid #00BCBC`,
    },
    header: {
        margin: `0`,
    },
    rejectDiv: {
        color: `red`,
    },
    m0: {
        marginTop: `0 !important`,
        marginBottom: `0 !important`,
    },
    errorMsg: {
        color: `red !important`,
        marginTop: `4px`,
        marginBottom: `4px`,
        borderBottom: `2px solid red`,
    },
    mandatoryNote: {
        color: `red !important`,
        fontSize: `14px`,
        fontStyle: `italic`,
        display: `flex`,
        justifyContent: `flex-end`,
        marginBottom: `0`,
        marginTop: `0`,
    },
    allMandatoryNotes:{
    color: `red !important`,
    fontSize: `14px`,
    fontStyle: `italic`,
    display: `flex`,
    justifyContent: `flex-end`,
    marginBottom: `5px`,
    marginTop: `0`,
    },
    subGrid: {
        boxShadow: `1px 2px 5px 3px #ccc`,
        marginTop: `1rem`,
        paddingBottom: `8px`,
        marginBottom: `1rem`,
        borderRadius: `8px`,
        padding: `1rem`,
    },
    viewList: {
        color: `#008AA6;`,
        height: `1.2em`,
        width: `1.2em`,
    },
    viewBtn: {
        padding: `0`,
    },
}));

export default function AddPaymentDetails() {
    const classes = useStyles();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    // var encryptedId = getURLParameter("id");
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    const [pageHeader, setPageHeader] = useState("Add Payment Details");
    const [paymentId, setPaymentId] = useState(0);
    //? Input Field Hooks
    const [currentStageId, setCurrentStageId] = useState(0);
    const [consultantName, setConsultantName] = useState([]);
    const [consultantNameValue, setConsultantNameValue] = useState("");
    const [nature, setNature] = useState([]);
    const [natureValue, setNatureValue] = useState("");
    const [natureName, setNatureName] = useState("");
    const [toolcaseNoData, setToolcaseNoData] = useState([]);
    const [toolcaseNo, setToolcaseNo] = useState({});
    const [inputToolcaseNo, setInputToolcaseNo] = useState("");
    const [consultantPoNo, setConsultantPoNo] = useState([]);
    const [consultantPoNoValue, setConsultantPoNoValue] = useState("");
    const [consultantPoDate, setConsultantPoDate] = useState(null);
    const [consultantPoDateValue, setConsultantPoDateValue] = useState(null);
    const [consultantPoValue, setConsultantPoValue] = useState("");
    const [consultantPoValueHelper, setConsultantPoValueHelper] = useState("");
    const [consultantInvNo, setConsultantInvNo] = useState("");
    const [consultantInvDate, setConsultantInvDate] = useState(null);
    const [consultantInvDateValue, setConsultantInvDateValue] = useState(null);
    const [consultantInvValue, setConsultantInvValue] = useState("");
    const [consultantInvValueHelper, setConsultantInvValueHelper] = useState("");
    const [ratio, setRatio] = useState([]);
    const [ratioValue, setRatioValue] = useState("");
    const [ratioName, setRatioName] = useState("");
    const [selectedFileUploadRatio, setSelectedFileUploadRatio] = useState(null);
    const [inputFileUploadRatio, setInputFileUploadRatio] = useState(Date.now);
    const [downloadRatioFile, setDownloadRatioFile] = useState("");
    const [grNumber, setGrNumber] = useState("");
    const [grDate, setGRDate] = useState(null);
    const [grDateValue, setGRDateValue] = useState(null);
    const [sapBookingDocNo, setSapBookingDocNo] = useState("");
    const [paymentReleaseDate, setPaymentReleaseDate] = useState(null);
    const [paymentReleaseDateValue, setPaymentReleaseValue] = useState(null);
    const [poCreatorName, setPoCreatorName] = useState("");
    const [fileRatioName,setFileRatioName] = useState("");
    const [poCreatorId, setPoCreatorId] = useState("");
    const [sapNumberHelper, setSapNumberHelper] = useState("");
    const [remarks, setRemarks] = useState("");
    const [errorFileName, setErrorFileName] = useState("");
    //? Dialog Hooks
    const [openRatioDialog, setOpenRatioDialog] = useState(false);
    const [ratioDialogData, setRatioDialogData] = useState([]);
    //? Snackbar & Loaders
    let [isLoader, setIsLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    const handleClose = (e) => {
        setOpen(false);
        if (!encryptedId && alertType === "success") {
            window.location.href = "/payment";
        }
        if (encryptedId && alertType === "success") {
            getPayment();
        }
    };
    //* Ratio Dialog and Columns
    const handleOpenRatioDialog = () => {
        setOpenRatioDialog(true);
    };
    const handleCloseRatioDialog = () => {
        //setRatioDialogData([]);
        setOpenRatioDialog(false);
    };
    const ratioColumns = [
        { title: "Profit Center", field: "profitCenterName" },
        { title: "Ratio", field: "ratio" },
        { title: "Ratio Amount", field: "ratioAmount" },
    ];

    //* Input Handlers
    const onChange = (event) => {
        let valid;

        switch (event.target.id) {
            case "ConsultantPOValue":
                setConsultantPoValue(event.target.value);
                valid = /^((\d+(\.\d*)?)|(\.\d+))$/.test(event.target.value);
                if (event.target.value === "") {
                    setConsultantPoValueHelper("Cannot be left blank");
                } else if (!valid) {
                    setConsultantPoValueHelper("Numbers only");
                } else if (parseInt(event.target.value) === 0) {
                    setConsultantPoValueHelper("Cannot be 0");
                } 

                else {
                    setConsultantPoValueHelper("");
                }
                break;
            case "consultantInvoiceNo":
                setConsultantInvNo(event.target.value);
                break;
            case "consultantInvoiceValue":
                setConsultantInvValue(event.target.value);
                valid = /^((\d+(\.\d*)?)|(\.\d+))$/.test(event.target.value);
                if (event.target.value === "") {
                    setConsultantInvValueHelper("Cannot be left blank");
                } else if (!valid) {
                    setConsultantInvValueHelper("Numbers only");
                } else if (parseInt(event.target.value) === 0) {
                    setConsultantInvValueHelper("Cannot be 0");
                } else {
                    setConsultantInvValueHelper("");
                }
                break;
            case "grNumber":
                setGrNumber(event.target.value);
                break;
            case "sapBookingDocNo":
                setSapBookingDocNo(event.target.value);
               
                if (event.target.value.length !== 10) {
                    setSapNumberHelper("Length should be 10 Digits");
                    //setSapBookingDocNo("");
                    
                }
                
                    
               
                
                    
                
                break;
            case "remarks":
                setRemarks(event.target.value);
                break;
            default:
                break;
        }
    };
    const handleConsultantName = (e) => {
        setConsultantNameValue(e.target.value);
        getPOList(e.target.value);
    };
    const handleNature = (e) => {
        setNatureValue(e.target.value);
        console.log("naturevalue", e.target.value);
        getRatioType(e.target.value);
        var name = nature.filter((c) => c.hardCodeId === e.target.value);
        setNatureName(name[0].name.toLowerCase());
    };
    const handleNPoNo = (e) => {
        setConsultantPoNoValue(e.target.value);
        getPO(e.target.value);
    };
    const handlePODate = (date) => {
        let newDate = JSON.stringify(date);
        setConsultantPoDate(newDate.slice(1, 11));
        setConsultantPoDateValue(date);
    };
    const handleInvDate = (date) => {
        let newDate = JSON.stringify(date);
        setConsultantInvDate(newDate.slice(1, 11));
        setConsultantInvDateValue(date);
    };
    const handleRatio = (e) => {
        console.log("ratio", e.target.value)
        setRatioValue(e.target.value);
        var name = ratio.filter((c) => c.hardCodeId === e.target.value);
        console.log("name", name[0].name.toLowerCase());
        setRatioName(name[0].name.toLowerCase());
    };
    
    const handleChangeUploadRatio = (event) => {
        setSelectedFileUploadRatio(event.target.files[0]);
    };
    const handleGRDate = (date) => {
        let newDate = JSON.stringify(date);
        setGRDate(newDate.slice(1, 11));
        setGRDateValue(date);
    };
    const handlePaymentReleaseDate = (date) => {
        let newDate = JSON.stringify(date);
        setPaymentReleaseDate(newDate.slice(1, 11));
        setPaymentReleaseValue(date);
    };

    //* Dropdown get functions
    function getConsultant(id) {
        getConsultantNameList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
            .then((response) => {
                setConsultantName(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
   
    function getRatioType(moduleID) {
        getRatioTypeList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleID)
            .then((response) => {
                setRatio(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getNature(moduleID) {
        getNatureOfServiceList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleID)
            .then((response) => {
                console.log("nature", response);
                setNature(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getToolCaseNo() {
        getToolCaseNoList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
            .then((response) => {
                setToolcaseNoData(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    //* AutoComplete
    const handleChangeToolCaseNo = (event, newValue) => {
        setToolcaseNo(newValue !== null ? newValue : "");
        setInputToolcaseNo(newValue !== null ? newValue.toolCaseId : "");
    };

    //* Get PO
    function getPOList(id) {
        getPOBasedOnConsultantList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id)
            .then((response) => {
                setConsultantPoNo(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    //* Toolcase Data
    function getToolData(id, invoiceValue, paymentId, type) {
      debugger;
      setCurrentStageId(0);
      getToolCaseBasedData(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id, invoiceValue, paymentId, type)
        .then((response) => {
          setRatioDialogData(response);
          if (response.length > 0) {
            setCurrentStageId(response[0].currentStageId);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    function LogData() {
      setOpenRatioDialog(true);
      getToolData(inputToolcaseNo, consultantInvValue, paymentId, ratioName);
    }
    //* PO Based Data
    function getPO(id) {
        setIsLoader(true);
        getPOMasterList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,id)
            .then((response) => {
                setConsultantPoDate(JSON.stringify(response[0].poDate).slice(1, 11));
                setConsultantPoDateValue(response[0].poDate);
                setConsultantPoValue(response[0].poValue);
                setPoCreatorId(response[0].poCreaterId);
                setPoCreatorName(response[0].poCreaterName);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //! Payment Details Based On Id
    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }

    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            var value = "";
            for (var j = 0; j < sParameterName.length; j++) {
                if (sParameterName[j] == sParam) {
                    for (var k = j + 1; k < sParameterName.length; k++) {
                        value = value + sParameterName[k];
                    }
                    break;
                }
            }
            return value;
        }
    }
    var encryptedId = getURLParameter("id");
    function getPayment() {
        setIsLoader(true);
        getPaymentList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, atobSecure(encryptedId))
            .then((response) => {
                setPaymentId(response[0].paymentId);
                setConsultantNameValue(response[0].consultantId);
                setNatureValue(response[0].natureOfService);
                getRatioType(response[0].natureOfService);
                setNatureName(response[0].natureOfServiceName.toLowerCase());
                setInputToolcaseNo(response[0].toolCaseId);
                getPOList(response[0].consultantId);
                setConsultantPoNoValue(response[0].consultantPoid);
                setConsultantPoDate(response[0].consultantPODate);
                setConsultantPoDateValue(response[0].consultantPODate);
                setConsultantPoValue(response[0].consultantPOValue);
                setConsultantInvNo(response[0].consultantInvNo);
                setConsultantInvDate(response[0].consultantInvDate);
                setConsultantInvDateValue(response[0].consultantInvDate);
                setConsultantInvValue(response[0].consultantInvAmt);
                setRatioName(response[0].ratioTypeName.toLowerCase());
                setRatioValue(response[0].ratioType);
                setGrNumber(response[0].grNo);
                setGRDate(response[0].grDate);
                setGRDateValue(response[0].grDate);
                setSapBookingDocNo(response[0].sapBookingDocNo);
                setRemarks(response[0].remarks);
                setPaymentReleaseDate(response[0].paymentReleaseDate);
                setPaymentReleaseValue(response[0].paymentReleaseDate);
                setPoCreatorId(response[0].poCreaterId);
                setPoCreatorName(response[0].poCreaterName);
                setFileRatioName(response[0].ratioFileName);
                setDownloadRatioFile(response[0].ratioFilePath);
                setCurrentStageId(response[0].currentStageId);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //* Save Payment Data
    function submitPaymentDetails() {
        
      setIsLoader(true);
        var formdataPart1 = new FormData();

       
      formdataPart1.append("ToolCaseId", inputToolcaseNo);
      formdataPart1.append("RatioExcelFile", selectedFileUploadRatio);
      formdataPart1.append("CurrentStageId", currentStageId);
      formdataPart1.append("ConsultantInvAmt", parseFloat(consultantInvValue));
      formdataPart1.append("ConsultantInvDate", consultantInvDate);
      formdataPart1.append("ConsultantInvNo", consultantInvNo);
      formdataPart1.append("ConsultantId", consultantNameValue);
      formdataPart1.append("ConsultantPoDate", consultantPoDate);
      formdataPart1.append("ConsultantPoId", consultantPoNoValue);
      formdataPart1.append("ConsultantPoValue", consultantPoValue);
      formdataPart1.append("UserID", userDetails.userId);
      formdataPart1.append("GrDate", grDate);
      formdataPart1.append("GrNo", grNumber);
      formdataPart1.append("PoCreaterName", poCreatorName);
      formdataPart1.append("Remarks", remarks);
      formdataPart1.append("RatioType", ratioValue);
      formdataPart1.append("NatureOfService", natureValue);
      formdataPart1.append("SapBookingDocNo", sapBookingDocNo);
      formdataPart1.append("PaymentReleaseDate", paymentReleaseDate);
      formdataPart1.append("POCreaterId", poCreatorId);
        formdataPart1.append("CreatedBy", userDetails.userId);
        
      uploadPaymentDetails(formdataPart1, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
          .then((response) => {
          if (response.status === 1) {
              handleSnackOpen("Payment Details Added Successfully.", "success");
              
              setInputFileUploadRatio(Date.now);
              setSelectedFileUploadRatio(null);
              setErrorFileName("");
              setIsLoader(false);
           
          } 
           else if (response.status === 4) {

                  handleSnackOpen("Error While Adding Payment.", "error");
                  
              setInputFileUploadRatio(Date.now);
              setSelectedFileUploadRatio(null);
                  setErrorFileName(response.result);
                  setIsLoader(false);
              
           }
             else if (response.status === 0) {
             
              handleSnackOpen(response.result, "error");
              setInputFileUploadRatio(Date.now);
              setSelectedFileUploadRatio(null);
                 
                  setErrorFileName("");
                  setIsLoader(false);
           }
           else if (response.status === 5) {
              handleSnackOpen(response.result, "error");
                  
              setInputFileUploadRatio(Date.now);
              setSelectedFileUploadRatio(null);
              setErrorFileName("");
                  setIsLoader(false);
          }
          else if (response.status === -1) {
                 
              handleSnackOpen(response.result, "error");
                  
              setInputFileUploadRatio(Date.now);
              setSelectedFileUploadRatio(null);
              setErrorFileName("");
                  setIsLoader(false);
          } else {
            setIsLoader(false);
              setErrorFileName("");
              handleSnackOpen(response.result, "error");
              setInputFileUploadRatio(Date.now);
              setSelectedFileUploadRatio(null);
             
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.log("error", error);
        });
    }
    //* Edit Payment Details
    function editPaymentDetails() {
      setIsLoader(true);
      var formdataPart1 = new FormData();
      formdataPart1.append("PaymentId", paymentId);
      formdataPart1.append("ToolCaseId", inputToolcaseNo);
      formdataPart1.append("RatioExcelFile", selectedFileUploadRatio);
      formdataPart1.append("CurrentStageId", currentStageId);
      formdataPart1.append("ConsultantInvAmt", parseFloat(consultantInvValue));
      formdataPart1.append("ConsultantInvDate", consultantInvDate);
      formdataPart1.append("ConsultantInvNo", consultantInvNo);
      formdataPart1.append("ConsultantId", consultantNameValue);
      formdataPart1.append("ConsultantPoDate", consultantPoDate);
      formdataPart1.append("ConsultantPoId", consultantPoNoValue);
      formdataPart1.append("ConsultantPoValue", consultantPoValue);
      formdataPart1.append("UserID", userDetails.userId);
      formdataPart1.append("GrDate", grDate);
      formdataPart1.append("GrNo", grNumber);
      formdataPart1.append("PoCreaterName", poCreatorName);
      formdataPart1.append("Remarks", remarks);
      formdataPart1.append("RatioType", ratioValue);
      formdataPart1.append("NatureOfService", natureValue);
      formdataPart1.append("SapBookingDocNo", sapBookingDocNo);
      formdataPart1.append("PaymentReleaseDate", paymentReleaseDate);
      formdataPart1.append("POCreaterId", poCreatorId);
      formdataPart1.append("ModifiedBy", userDetails.userId);
      UpdatePaymentDetails(formdataPart1, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
        .then((response) => {
          if (response.status === 1) {
              handleSnackOpen("Payment Details Updated Successfully.", "success");
              setSelectedFileUploadRatio(null);
            setErrorFileName("");
            setIsLoader(false);
          } else if (response.status === 4) {
            setIsLoader(false);
            setErrorFileName(response.result);
              setSelectedFileUploadRatio(null);
            setInputFileUploadRatio(Date.now);
            
            handleSnackOpen("Error While Updating Payment.", "error");
          } else if (response.status === 5) {
            setIsLoader(false);
            setErrorFileName("");
              handleSnackOpen(response.result, "error");
              setSelectedFileUploadRatio(null);
          } else {
            setIsLoader(false);
            setErrorFileName("");
              handleSnackOpen(response.result, "error");
              setSelectedFileUploadRatio(null);
          }
        })
        .catch((error) => {
          setIsLoader(false);
          console.log("error", error);
        });
    }

    useEffect(() => {
        //getRatioType(IDTModuleId);
        getNature(IDTModuleId);
        getConsultant(0);
        getToolCaseNo();
        if (encryptedId) {
            setPageHeader("Edit Payment Details");
            getPayment();
        }
    }, [siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, encryptedId]);
    useEffect(() => {
        var array = toolcaseNoData.filter((c) => c.toolCaseId === inputToolcaseNo);
        setToolcaseNo(array[0]);
    }, [toolcaseNoData, inputToolcaseNo]);

    return (
      <div className={classes.subGrid}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className="d-flex jc-flex-start">
            <h4 className={classes.sectionHeader2}>{pageHeader}</h4>
          </Grid>
          <Grid item xs={12} md={6} className="d-flex jc-flex-end">
            <IconButton
              color="secondary"
              aria-label="View Ratio Split"
              href={RatioUploadTemplate}
              className={classes.viewBtn}>
              <Tooltip title="Download Ratio Upload Template">
                <CloudDownloadIcon className={classes.viewList} />
              </Tooltip>
            </IconButton>
            {inputToolcaseNo !== "" && consultantInvValue ? (
              <IconButton
                color="secondary"
                aria-label="View Ratio Split"
                //disabled={inputToolcaseNo ? false : true}
                className={classes.viewBtn}>
                <Tooltip title="View Ratio Split">
                  <ViewListIcon
                    className={classes.viewList}
                    onClick={LogData}
                  />
                </Tooltip>
              </IconButton>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
                <p className={classes.allMandatoryNotes}>
            All Fields are Mandatory,Except Remark
          </p>
        </Grid>
        <Grid container spacing={2} className="d-flex jc-flex-end">
            {!encryptedId &&
                ((currentStageId === 1 && ratioName !== "equally") ||
                    natureName == "advisory" ||
                    (currentStageId !== 1 &&
                        (ratioName === "bu specific ratio" ||
                            ratioName === "turnover"))) ? (
                <Grid item xs={12}>
                    <p className={classes.mandatoryNote}>
                        Please Upload PC Wise Invoice Amount Split.
                    </p>
                </Grid>
            ) : (
                <></>
            )}
            {natureName === "case specific" ? (
                <Grid item xs={12}>
                    <p className={classes.mandatoryNote}>
                        Please Select Tool Case Number.
                    </p>
                </Grid>
            ) : (
                <></>
            )}
        
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              select
              label="Consultant Name"
              id=""
              name=""
              className=""
              fullWidth
              value={consultantNameValue}
              onChange={handleConsultantName}
              variant="filled">
              {consultantName.map((item) => (
                <MenuItem key={item.consultantId} value={item.consultantId}>
                  {item.consultantName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Nature"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={natureValue}
                        onChange={handleNature}
                        variant="filled">
                        {nature.map((item) => (
                            <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={toolcaseNoData}
              getOptionLabel={(option) =>
                option.toolCaseNo ? option.toolCaseNo : ""
              }
              id="controlled-demo"
              value={toolcaseNo}
              // required
              disabled={natureName === "advisory" ? true : false}
              onChange={handleChangeToolCaseNo}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tool Case Number"
                  margin="normal"
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              select
              label="Consultant PO No"
              id=""
              name=""
              className=""
              fullWidth
              value={consultantPoNoValue}
              onChange={handleNPoNo}
              variant="filled">
              {consultantPoNo.map((item) => (
                <MenuItem key={item.poId} value={item.poId}>
                  {item.poNumber}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3} className="">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="consultantPoDate"
                className="datepicker"
                label="Consultant PO Date"
                format="dd/MM/yyyy"
                fullWidth
                disabled
                value={consultantPoDateValue}
                //onChange={handlePODate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Consultant PO Value"
              id="ConsultantPOValue"
              name=""
              className=""
              fullWidth
              disabled
              value={consultantPoValue}
              // onChange={onChange}
              helperText={consultantPoValueHelper}
              error={consultantPoValueHelper ? true : false}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Consultant Invoice No"
              id="consultantInvoiceNo"
              name=""
              className=""
              fullWidth
              value={consultantInvNo}
              onChange={onChange}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3} className="">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="consultantInvoiceDate"
                className="datepicker"
                InputProps={{ readOnly: true }}
                label="Consultant Invoice Date"
                format="dd/MM/yyyy"
                fullWidth
                value={consultantInvDateValue}
                onChange={handleInvDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Consultant Invoice Value"
              id="consultantInvoiceValue"
              name=""
              className=""
              fullWidth
              value={consultantInvValue}
              onChange={onChange}
              helperText={consultantInvValueHelper}
              error={consultantInvValueHelper ? true : false}
              variant="filled"
            />
                </Grid>
              
                 <Grid item xs={12} md={3}>
                    <TextField
                        label="Ratio"
                        id="ratio"
                        name=""
                        className=""
                        fullWidth
                        select
                        value={ratioValue}
                        onChange={handleRatio}
                        variant="filled">
                        <MenuItem value={0}>
                            <em>Select</em>
                        </MenuItem>
                        {ratio.map((item) => (
                            <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
               
                
               
          <Grid item xs={12} md={3}>
            <TextField
              label="Upload Ratio"
              id="uploadRatio"
              name="uploadRatio"
              className=""
              fullWidth
              type="file"
              key={inputFileUploadRatio}
              onChange={handleChangeUploadRatio}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      className="p-0"
                      target="_blank"
                      href={downloadRatioFile}
                      disabled={downloadRatioFile ? false : true}>
                      <CloudDownloadIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {fileRatioName ? <p className="file-name">{fileRatioName}</p> : ""}
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="GR Number"
              id="grNumber"
              name=""
              className=""
              fullWidth
              value={grNumber}
              onChange={onChange}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3} className="">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="grDate"
                className="datepicker"
                label="GR Date"
                format="dd/MM/yyyy"
                fullWidth
                InputProps={{ readOnly: true }}
                value={grDateValue}
                onChange={handleGRDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="SAP Booking Doc No"
              id="sapBookingDocNo"
                        name=""
                        inputProps={{ maxLength: 10 }}
              className=""         
              fullWidth
              value={sapBookingDocNo}
                        onChange={onChange}
                        helperText={sapNumberHelper}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3} className="">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="paymentReleaseDate"
                className="datepicker"
                label="Payment Release Date"
                format="dd/MM/yyyy"
                InputProps={{ readOnly: true }}
                fullWidth
                value={paymentReleaseDateValue}
                onChange={handlePaymentReleaseDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="PO Creator"
              id="poCreator"
              name=""
              className=""
              fullWidth
              value={poCreatorName}
              //onChange={handleChangeSubModule}
              variant="filled"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Remarks"
              id="remarks"
              name=""
              className=""
              fullWidth
              value={remarks}
              onChange={onChange}
              variant="filled"
            />
          </Grid>
          {errorFileName ? (
            <Grid item xs={12} className="d-flex jc-center">
              <a
                href={
                  baseApiURL + "/Common/DownloadLog?fileName=" + errorFileName
                }>
                <p className={classes.errorMsg}>
                  Click to download Error Log File
                </p>
              </a>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid container spacing={2} className="d0flex jc-center mt-1 mb-1">
          {encryptedId ? (
            <Grid item xs={12} md={1} className="align-self">
              <Button
                type="submit"
                fullWidth
                className="pt-button--primary"
                onClick={editPaymentDetails}
                disabled={
                  (natureName === "advisory" &&
                    !selectedFileUploadRatio &&
                    !fileRatioName) ||
                  ((ratioName === "bu specific ratio" ||
                    ratioName === "turnover") &&
                    !selectedFileUploadRatio &&
                    !fileRatioName) ||
                  !consultantNameValue ||
                  !natureValue ||
                  !consultantPoNoValue ||
                  !consultantInvDate ||
                  !consultantInvNo ||
                  !consultantInvValue ||
                  !ratioValue ||
                  (natureName === "case specific" && !inputToolcaseNo)
                    ? true
                    : false
                }
                id="SupplierLoginBtn">
                Update
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} md={1} className="align-self">
              <Button
                type="submit"
                fullWidth
                className="pt-button--primary"
                onClick={submitPaymentDetails}
                disabled={
                  (natureName === "advisory" && !selectedFileUploadRatio) ||
                  ((ratioName === "bu specific ratio" ||
                    ratioName === "turnover") &&
                    !selectedFileUploadRatio) ||
                  !consultantNameValue ||
                  !natureValue ||
                  !consultantPoNoValue ||
                  !consultantInvDate ||
                  !consultantInvNo ||
                  !consultantInvValue ||
                  !ratioValue ||
                  !grDate ||
                  !grNumber ||
                  !sapBookingDocNo ||
                  !paymentReleaseDate ||
                  !poCreatorId ||
                  (natureName === "case specific" && !inputToolcaseNo)
                    ? true
                    : false
                }
                id="SupplierLoginBtn">
                Submit
              </Button>
            </Grid>
          )}

          <Grid item xs={12} md={1} className="align-self">
            <Link to={"/payment"}>
              <Button
                type="submit"
                fullWidth
                className="pt-button--secondary"
                id="SupplierLoginBtn">
                Back
              </Button>
            </Link>
          </Grid>
        </Grid>
        {isLoader ? <Loader loaderText="Loading...." /> : <></>}
        <>
          <SnackbarCustom
            open={open}
            message={snackMessage}
            alertType={alertType}
            handleClose={handleClose}
          />
        </>
        <Dialog
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleCloseRatioDialog(event, reason);
            }
          }}
          aria-labelledby="litigation-form--registration"
          open={openRatioDialog}
          maxWidth={"md"}>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h5 className={classes.m0}>
                    Profit Center Wise Ratio Split :-
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <MaterialTable
                    title=""
                    columns={ratioColumns}
                    data={ratioDialogData}
                    icons={tableIconsInputScreen}
                    options={{
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },
                      search: true,
                      pageSize: 10,
                      exportAllData: true,
                      emptyRowsWhenPaging: false,
                      exportFileName: "RatioSplitList",
                      rowStyle: (x) => {
                        if (x.tableData.id % 2) {
                          return { backgroundColor: "#F2FCFC" };
                        } else {
                          return { backgroundColor: "white" };
                        }
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              onClick={handleCloseRatioDialog}>
              close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}
