import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
  TextField,
  MenuItem,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Tooltip,
  TextareaAutosize,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { getCompanyListByUserId } from "../../Redux/API/api_reports";
import {
  IDTModuleId,
  baseAddressURL,
  tableIconsInputScreen,
} from "../../Utilities/Utility";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import HistoryIcon from "@material-ui/icons/History";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {
  approveToolCaseData,
  getAllLocationList,
  getAllTagList,
  getSummaryReportLog,
  getSummaryReportRemarks,
  getTagBasedOnToolCase,
  getToolCaseList,
  rejectToolCaseData,
  saveTag,
} from "../../Redux/API/api_toolCaseList";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { getAllSubModuleList } from "../../Redux/API/api_admin";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "./ToolCaseList.scss";
import {
  storeIsRefundCase,
  storeToolCaseNo,
} from "../../Redux/Actions/actions";
import { getSummaryReportFile } from "../../Redux/API/api_reports";
import * as FileSaver from "file-saver";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    margin: `0 0 0.8rem 0`,
    fontFamily: `SiemensSans-Bold`,
    fontWeight: `bold`,
    color: `#000028`,
  },
  tagDialogFocus: {
    minHeight: `420px`,
  },
  tagDialogNormal: {
    minHeight: `150px`,
  },
  alItemCenter: {
    alignItems: `center`,
  },
  idtRemarks: {
    width: `100%`,
    border: `0`,
    background: `#EBF7F8`,
  },
  MuiTypography: {
    body1: {
      fontSize: `14px !important`,
      fontWeight: `bold !important`,
    },
  },
}));

export default function ViewToolCase() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toolCaseData, setToolCaseData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [logData, setLogData] = useState([]);
  const [typeValue, setTypeValue] = useState(0);
  const [toolcaseNo, setToolcaseNo] = useState("");

  const [location, setLocation] = useState([]);
  const [locationValue, setLocationValue] = useState(0);
  const [currentStage, setCurrentStage] = useState([]);
  const [currentStageValue, setCurrentStageValue] = useState(0);
  const [tagList, setTagList] = useState([]);
  const [tagListValue, setTagListValue] = useState(0);
  const siteToken = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector(
    (state) => state.saveBearerTokenReducer.bearerToken
  );
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [statusValue, setStatusValue] = useState(4);
  const [tag, setTag] = useState([]);
  const [tagToolCaseNo, setTagToolCaseNo] = useState("");
  const [idtRemark, setIdtRemark] = useState("");
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const handleSnackOpen = (text, type) => {
    setSnackMessage(text);
    setAlertType(type);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (e) => {
    setOpenSnackbar(false);
  };
  const handleTypeChange = (e) => {
    setTypeValue(e.target.value);
  };
  const handleLocationChange = (e) => {
    setLocationValue(e.target.value);
  };
  const handleCurrentStageChange = (e) => {
    setCurrentStageValue(e.target.value);
  };
  const handleStatus = (e) => {
    setStatusValue(e.target.value);
  };
  const handleTagDropdown = (e) => {
    setTagListValue(parseInt(e.target.value));
  };
  const handleIdtRemark = (e) => {
    setIdtRemark(e.target.value);
  };
  const handleShowLog = (e) => {
    setShowLog(e.target.checked);
  };
  //!Multi-select
  const [selectedOptionsTag, setSelectedOptionsTag] = useState([]);
  const [filterTag, setFilterTag] = useState("");
  function editTagByUser(filterTag) {
    var arrayTag = filterTag.split(",");
    setSelectedOptionsTag(tag.filter((x) => arrayTag.includes(x.id)));
  }
  let tagArr = [];
  const [tagString, setTagString] = useState("");
  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }
  function onChangeTag(value, event) {
    let tagValue = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
    if (event.action === "select-option" && event.option.value === "*") {
      tagValue = tag;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      tagValue = [];
    } else {
      tagValue = value;
    }
    for (let i = 0; i < tagValue.length; i++) {
      if (tagValue[i].id !== undefined) tagArr.push(tagValue[i].id);
    }
    setTagString(tagArr.toString());
  }
  //! Approve Dialog
  const [openFormApprove, setOpenFormApprove] = useState(false);
  const handleClickOpenFormApprove = () => {
    setOpenFormApprove(true);
  };
  const handleCloseFormApprove = () => {
    setOpenFormApprove(false);
  };
  //! reject Dialog
  const [rejectReason, setRejectReason] = useState("");
  const [toolcaseId, setToolcaseID] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const handleClickOpenForm = () => {
    setOpenForm(true);
  };
  const handleCloseForm = () => {
    setRejectReason("");
    setOpenForm(false);
  };
  const handleRejectReason = (event) => {
    setRejectReason(event.target.value);
  };
  const handleToolcaseNo = (event) => {
    setToolcaseNo(event.target.value);
  };

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

  function viewToolCaseData(row) {
    let id = row.toolCaseId;
    let initiatedID = row.initiatedFromSubModuleId;
    let idEncrypted = btoaSecure(id);
    window.location.href =
      baseAddressURL + "/ToolCaseRegistration?id=" + idEncrypted;
  }
  function viewWorkflow(row) {
    let id = row.toolCaseId;
    let idEncrypted = btoaSecure(id);
    dispatch(storeIsRefundCase(false));
    window.location.href =
      baseAddressURL + "/workflowHistory?id=" + idEncrypted;
  }
  function GetEditButton(rowData, userdata) {
    return (
      <Tooltip title="View ToolCase Data" placement="top">
        <span>
          <Button>
            <span>
              <VisibilityIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function handleApproveToolCase() {
    setIsLoader(true);
    approveToolCaseData(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      toolcaseId,
      userDetails.userId
    )
      .then((response) => {
        if (response === 1) {
          handleSnackOpen("Tool Case Approved Successfully.", "success");
          setIsLoader(false);
          getToolCase();
          handleCloseFormApprove();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Approving Tool Case.", "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  function ApproveDocumentButton(rowData) {
    function handleApprovePopUp() {
      setOpenFormApprove(true);
      setToolcaseID(rowData.toolCaseId);
    }
    function handleReject() {
      setToolcaseID(rowData.toolCaseId);
      setOpenForm(true);
    }
    return (
      <div>
        <IconButton
          className="activationButton"
          aria-label="Approve"
          onClick={handleApprovePopUp}
          style={{ left: `-12px` }}>
          <Tooltip title="Approve">
            <CheckIcon style={{ color: `green` }} />
          </Tooltip>
        </IconButton>
        <IconButton
          className="activationButton"
          aria-label="reject"
          onClick={handleReject}>
          <Tooltip title="Reject">
            <CloseIcon style={{ color: `red` }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  }

  function handleRejectToolCase() {
    setIsLoader(true);
    rejectToolCaseData(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      toolcaseId,
      rejectReason,
      userDetails.userId
    )
      .then((response) => {
        if (response === 1) {
          handleSnackOpen("Tool Case Rejected Successfully.", "success");
          setIsLoader(false);
          handleCloseForm();
          getToolCase();
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Rejecting Tool Case.", "error");
          handleCloseForm();
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  //company change
  const [companyValue, setCompanyValue] = useState(0);
  const handleCompanyChange = (e) => {
    setCompanyValue(e.target.value);
  };

  //! Tag Dialog
  const [openFormTag, setOpenFormTag] = useState(false);
  const handleClickOpenFormTag = (rowData) => {
    setTagToolCaseNo(
      rowData.toolCaseNo ? rowData.toolCaseNo : rowData.draftToolCaseNo
    );
    setToolcaseID(rowData.toolCaseId);
    setOpenFormTag(true);
    getSelectedTag(rowData.toolCaseId);
  };
  const handleCloseFormTag = () => {
    setOpenFormTag(false);
    setTagToolCaseNo("");
  };
  //! Report Dialog
  const [selectedToolcaseNo, setSelectedToolcaseNo] = useState("");
  const [selectedToolCaseId, setSelectedToolCaseId] = useState(0);
  const [openFormReport, setOpenFormReport] = useState(false);
  const handleClickOpenFormReport = (rowData) => {
    setSelectedToolCaseId(rowData.toolCaseId);
    getSummaryRemark(rowData.toolCaseId);
    getSummaryRemarkLog(rowData.toolCaseId);
    setSelectedToolcaseNo(
      rowData.toolCaseNo ? rowData.toolCaseNo : rowData.draftToolCaseNo
    );
    setOpenFormReport(true);
  };
  const handleCloseFormReport = () => {
    setSelectedToolCaseId(0);
    setSelectedToolcaseNo("");
    setIdtRemark("");
    setOpenFormReport(false);
  };

  function generateReport() {
    setIsLoader(true);
    var formdata = {
      toolCaseId: selectedToolCaseId,
      userId: userDetails.userId,
      toolCaseRemark: idtRemark,
    };
    getSummaryReportFile(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      formdata
    )
      .then((response) => {
        if (response) {
          var blob = new Blob([response], {
            type: "application/msword",
          });
          FileSaver.saveAs(blob, "SummaryReport");
          handleSnackOpen("Summary Report Successfully Downloaded.", "success");
          setOpenFormReport(false);
          setIsLoader(false);
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Downloading Summary Report.", "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  //! Tag Related Function Calls
  function getTagList() {
    getAllTagList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId
    )
      .then((response) => {
        setTag(response);
        setTagList(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function handleSaveTag() {
    setIsLoader(true);
    saveTag(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      tagString,
      toolcaseId,
      userDetails.userId
    )
      .then((response) => {
        if (response === 1) {
          handleSnackOpen("Tag Added Successfully.", "success");
          setIsLoader(false);
          handleCloseFormTag();
          setTagString("");
          setSelectedOptionsTag([]);
        } else {
          setIsLoader(false);
          handleSnackOpen("Error While Adding Tag.", "error");
        }
      })
      .catch((error) => {
        setIsLoader(false);
        console.log("error", error);
      });
  }
  function getSelectedTag(id) {
    getTagBasedOnToolCase(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id
    )
      .then((response) => {
        setTagString(response.tagIds);
        setFilterTag(response.tagIds);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  //! Drop-Down function calls
  function getSubModule(id) {
    getAllSubModuleList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id,
      false
    )
      .then((response) => {
        setCurrentStage(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getAllLocation() {
    getAllLocationList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId
    )
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getToolCase() {
    setIsLoader(true);
    getToolCaseList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      0,
      "",
      0,
      0,
      4,
      0,
      0
    )
      .then((response) => {
        setToolCaseData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function getFilterToolCase() {
    setIsLoader(true);
    getToolCaseList(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      typeValue,
      toolcaseNo,
      locationValue ? locationValue : 0,
      currentStageValue ? currentStageValue : 0,
      statusValue,
      tagListValue,
      companyValue
    )
      .then((response) => {
        setToolCaseData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function reset() {
    setTypeValue(0);
    setToolcaseNo("");
    setLocationValue("");
    setCurrentStageValue("");
    setStatusValue(4);
    setTagListValue("");
    setCompanyValue(0);
    getToolCase();
  }

  function clickEditDocument(rowData) {
    let id = rowData.toolCaseId;
    let idEncrypted = btoaSecure(id);

    if (
      rowData.taxProfessionalId !== userDetails.userId &&
      userDetails.roleId === 2
    ) {
      return (
        <Tooltip title="No-Access">
          <span className="template-text">
            {rowData.toolCaseNo ? rowData.toolCaseNo : rowData.draftToolCaseNo}
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="View ToolCase">
          <Link to={"/ToolCaseRegistration?id=" + idEncrypted}>
            <span className="template-text">
              {rowData.toolCaseNo
                ? rowData.toolCaseNo
                : rowData.draftToolCaseNo}
            </span>
          </Link>
        </Tooltip>
      );
    }
  }
  function getSummaryRemark(id) {
    getSummaryReportRemarks(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id
    )
      .then((response) => {
        setIdtRemark(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function getSummaryRemarkLog(id) {
    getSummaryReportLog(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      id
    )
      .then((response) => {
        setLogData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  const [companyData, setCompanyData] = useState([]);
  function getCompany() {
    getCompanyListByUserId(
      siteToken,
      bearerToken,
      userDetails.emailId,
      userDetails.gid,
      userDetails.roleId,
      userDetails.roleName,
      uId,
      userDetails.userId
    )
      .then((response) => {
        setCompanyData(response);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const columns = [
    {
      title: "Tool Case Id",
      field: "toolCaseId",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Tool Case No",
      field: "toolCaseNo",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
      export: true,
    },
    {
      title: "Draft Tool Case No",
      field: "draftToolCaseNo",
      hidden: true,
      cellStyle: {
        textAlign: "left",
      },
      export: true,
    },
    {
      title: "Tool Case No",
      field: "toolCaseNo",
      align: "left",
      width: "10%",
      export: false,
      // render: (rowData) =>
      //   rowData.toolCaseNo ? rowData.toolCaseNo : rowData.draftToolCaseNo,
      render: (rowData) => rowData && clickEditDocument(rowData),
    },
    {
      title: "IDT Incharge",
      field: "taxProfessionalName",
      align: "left",
      width: "10%",
    },
    { title: "Entity", field: "entityName", align: "left", width: "12%" },
    {
      title: "Current Stage",
      field: "currentStageName",
      align: "left",
    },
    { title: "Location", field: "locationName", align: "left" },
    {
      title: "Status",
      field: "approveStatus",
      align: "left",
      width: "13%",
      render: (rowData) => (
        <div>
          {(rowData.approveStatus === "Pending For Approval" &&
            (userDetails.roleId === 1 ||
              userDetails.roleId === 4 ||
              userDetails.roleId === 5)) ||
          (userDetails.roleId === 3 &&
            rowData.approveStatus === "Pending For Approval")
            ? ApproveDocumentButton(rowData)
            : rowData.approveStatus}
        </div>
      ),
    },
  ];
  const logColumns = [
    { title: "Remarks", field: "toolCaseRemark", align: "left" },
    { title: "Created By", field: "createdBy", align: "left" },
    {
      title: "Created Date",
      field: "createdDate",
      align: "left",
      type: "date",
      dateSetting: { locale: "en-GB" },
    },
  ];

  useEffect(() => {
    getCompany();
    getToolCase();
    getSubModule(IDTModuleId);
    getAllLocation();
    getTagList();
    let toolCaseDetails = {
      draft: "",
      approved: "",
    };
    dispatch(storeToolCaseNo(toolCaseDetails));
  }, []);
  useEffect(() => {
    editTagByUser(filterTag);
  }, [tag, filterTag]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} className="d-flex jc-space-bt">
          <Grid item xs={9} className="text-left ml-1">
            <h3 className="m-0">View Tool Cases</h3>
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2} className="">
          <Grid item xs={12} md={2}>
            <TextField
              select
              label="Tool Case Type"
              id=""
              name=""
              className=""
              fullWidth
              value={typeValue}
              onChange={handleTypeChange}
              variant="filled">
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Draft ToolCase No</MenuItem>
              <MenuItem value={2}>ToolCase No</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="ToolCase No"
              id=""
              name=""
              className=""
              fullWidth
              value={toolcaseNo}
              onChange={handleToolcaseNo}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} md={2}>
            <TextField
              select
              label="Entity"
              id=""
              name=""
              className=""
              fullWidth
              value={companyValue}
              onChange={handleCompanyChange}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {companyData.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={2}>
            <TextField
              select
              label="Location"
              id=""
              name=""
              className=""
              fullWidth
              value={locationValue}
              onChange={handleLocationChange}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {location.map((item) => (
                <MenuItem key={item.stateId} value={item.stateId}>
                  {item.state}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              label="Stage"
              id=""
              name=""
              className=""
              fullWidth
              value={currentStageValue}
              onChange={handleCurrentStageChange}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {currentStage.map((item) => (
                <MenuItem key={item.subModuleId} value={item.subModuleId}>
                  {item.subModuleName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              label="Status"
              id=""
              name=""
              className=""
              fullWidth
              value={statusValue}
              onChange={handleStatus}
              variant="filled">
              <MenuItem key={4} value={4}>
                All
              </MenuItem>
              <MenuItem key={0} value={0}>
                Draft
              </MenuItem>
              <MenuItem key={1} value={1}>
                Pending for Approval
              </MenuItem>
              <MenuItem key={2} value={2}>
                Rejected
              </MenuItem>
              <MenuItem key={3} value={3}>
                Approved
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              select
              label="Tags"
              id=""
              name=""
              className=""
              fullWidth
              value={tagListValue}
              onChange={handleTagDropdown}
              variant="filled">
              <MenuItem key={0} value={0}>
                All
              </MenuItem>
              {tagList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={1} spacing={2} className="rightside-align">
            <Button
              type="submit"
              className="pt-button--primary"
              id="SupplierLoginBtn"
              onClick={getFilterToolCase}
              fullWidth>
              Search
            </Button>
          </Grid>

          <Grid item xs={12} md={1} spacing={2} className="rightside-align">
            <Button
              type="submit"
              className="pt-button--secondary"
              id="SupplierLoginBtn"
              onClick={reset}
              fullWidth>
              Reset
            </Button>
          </Grid>
        </Grid>
        {/*< Grid container item xs={2}  >*/}

        {/*    <Grid item xs={1} md={6} spacing={2} className="">*/}
        {/*        <Button*/}
        {/*            type="submit"*/}
        {/*            className="pt-button--primary"*/}
        {/*            id="SupplierLoginBtn"*/}
        {/*            onClick={getFilterToolCase}*/}
        {/*            fullWidth>*/}
        {/*            Search*/}
        {/*        </Button>*/}
        {/*    </Grid>*/}

        {/*    <Grid item xs={1} md={6} spacing={2} className="">*/}
        {/*        <Button*/}
        {/*            type="submit"*/}
        {/*            className="pt-button--secondary"*/}
        {/*            id="SupplierLoginBtn"*/}
        {/*            onClick={reset}*/}
        {/*            fullWidth>*/}
        {/*            Reset*/}
        {/*        </Button>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <Grid item xs={12} className="mt-1 toolcase-table mb-1">
          <MaterialTable
            title=""
            columns={columns}
            data={toolCaseData}
            icons={tableIconsInputScreen}
            className="toolcase-table"
            //isLoading={isLoader}
            options={{
              actionsColumnIndex: -1,
              search: false,
              pageSize: 10,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportAllData: true,
              emptyRowsWhenPaging: false,
              exportFileName: "ToolCaseList",
              pageSizeOptions: [10, 20, 30, 50],
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
            actions={[
              (rowData) => ({
                icon: VisibilityIcon,
                isFreeAction: true,
                tooltip:
                  rowData.taxProfessionalId !== userDetails.userId &&
                  userDetails.roleId === 2
                    ? `No Access`
                    : `View Toolcase Details`,
                onClick: (event, rowData) => viewToolCaseData(rowData),
                disabled:
                  rowData.taxProfessionalId !== userDetails.userId &&
                  userDetails.roleId === 2,
              }),
              (rowData) => ({
                icon: HistoryIcon,
                isFreeAction: true,
                tooltip:
                  rowData.taxProfessionalId !== userDetails.userId &&
                  userDetails.roleId === 2
                    ? `No Access`
                    : `View Workflow History`,
                onClick: (event, rowData) => viewWorkflow(rowData),
                disabled:
                  rowData.taxProfessionalId !== userDetails.userId &&
                  userDetails.roleId === 2,
              }),
              (rowData) => ({
                icon: LocalOfferIcon,
                className: "tag-icon",
                isFreeAction: true,
                tooltip: `Add Tag`,
                onClick: (event, rowData) => handleClickOpenFormTag(rowData),
              }),
              (rowData) => ({
                icon: AssessmentIcon,
                className: "tag-icon",
                isFreeAction: true,
                tooltip: `Generate Report`,
                onClick: (event, rowData) => handleClickOpenFormReport(rowData),
              }),
            ]}
            localization={{
              header: {
                actions: "View Details",
              },
            }}
          />
        </Grid>
      </Grid>
      {/* Reject dialog */}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseForm(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openForm}
        maxWidth={"md"}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              <h5>Please provide reason to reject the Tool Case.</h5>
              <Grid item xs={12}>
                <TextField
                  label="Reason"
                  id=""
                  name=""
                  className=""
                  fullWidth
                  value={rejectReason}
                  onChange={handleRejectReason}
                  variant="filled"></TextField>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseForm}>
            close
          </Button>
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            disabled={!rejectReason}
            onClick={handleRejectToolCase}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseFormApprove(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openFormApprove}
        maxWidth={"md"}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h5>Do You Want To Approve Tool Case?</h5>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseFormApprove}>
            close
          </Button>
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            onClick={handleApproveToolCase}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseFormTag(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openFormTag}
        className={classes.tagDialogFocus}
        maxWidth={"md"}>
        <DialogContent className={classes.tagDialogFocus}>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.dialogHeader}>
            Link Tag With {tagToolCaseNo}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} className="multi-select">
              <ReactMultiSelectCheckboxes
                options={[{ label: "All", value: "*" }, ...tag]}
                placeholderButtonLabel="Tags List"
                getDropdownButtonLabel={getDropdownButtonLabel}
                value={selectedOptionsTag}
                onChange={onChangeTag}
                setState={setSelectedOptionsTag}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseFormTag}>
            close
          </Button>
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            onClick={handleSaveTag}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseFormReport(event, reason);
          }
        }}
        aria-labelledby="litigation-form--registration"
        open={openFormReport}
        fullWidth
        maxWidth={"md"}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <h4 className={classes.dialogHeader}>
              Generate Tool Case Wise Summary Report
            </h4>
            <h4 className={classes.dialogHeader}>{selectedToolcaseNo}</h4>
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextareaAutosize
                className={classes.idtRemarks}
                aria-label="minimum height"
                minRows={2}
                value={idtRemark}
                onChange={handleIdtRemark}
                placeholder="Summary Remarks"
              />
            </Grid>
            <Grid item xs={6} className="p-0">
              <Switch
                checked={showLog}
                onChange={handleShowLog}
                name="checkedB"
                color="primary"
              />
              <span className="template-text">
                {showLog === true ? "Hide Log" : "Show Log"}
              </span>
            </Grid>
            {showLog === true ? (
              <Grid item xs={12} className="mt-1 toolcase-table mb-1">
                <MaterialTable
                  title=""
                  columns={logColumns}
                  data={logData}
                  icons={tableIconsInputScreen}
                  className="toolcase-table"
                  options={{
                    actionsColumnIndex: -1,
                    search: false,
                    pageSize: 5,
                    exportButton: {
                      csv: true,
                      pdf: false,
                    },
                    exportAllData: true,
                    emptyRowsWhenPaging: false,
                    exportFileName: "LogList",
                    pageSizeOptions: [5, 10, 20, 50],
                    rowStyle: (x) => {
                      if (x.tableData.id % 2) {
                        return { backgroundColor: "#F2FCFC" };
                      } else {
                        return { backgroundColor: "white" };
                      }
                    },
                  }}
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            className="pt-button--secondary"
            id="SupplierLoginBtn"
            onClick={handleCloseFormReport}>
            close
          </Button>
          <Button
            type="submit"
            className="pt-button--primary"
            id="SupplierLoginBtn"
            onClick={generateReport}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
      <>
        <SnackbarCustom
          open={openSnackbar}
          message={snackMessage}
          alertType={alertType}
          handleClose={handleCloseSnackbar}
        />
      </>
    </div>
  );
}
