import { Switch, Route, Redirect } from "react-router-dom";
import LoginRoot from "../Screens/LoginRegister/LoginRoot";
import NoAccess from "../Screens/UnAuthorizeAccess/UnauthorizedAccess";
import Error from "../Components/Error/Error404";
import Loader from "../Components/Loader/Loader";

export const LoginRoute = (
  <Switch>
    <Route exact path="/" render={() => <LoginRoot />} />
    <Route exact path="/signin-oidc*" render={() => <Loader />} />
    <Route component={Error} />
  </Switch>
);

export const NoAccessRoute = (
  <Switch>
    <Route exact path="/NoAccess" render={() => <NoAccess />} />
    <Route component={Error} />
  </Switch>
);
