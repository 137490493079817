import { baseApiURL } from "../../Utilities/Utility";

//USER MANAGEMENT APIs
export function getUserList(token,bearerToken,email,gid,roleID,roleName,uid,userId) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: token,
  },
  };
  return fetch(
    baseApiURL + "/UserManagement/GetAllUserList?UserId=" + userId,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Role
export function getRoleList(siteToken,bearerToken,email,gid,roleID,roleName,uid,id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken, 
    UserEmail: email,
    userId: uid,
    RoleName: roleName,
    Gid: gid,
    Roleid: roleID,
    AzureToken: siteToken
  },
  };
  return fetch(baseApiURL + "/Master/GetMstRoleList?moduleId=" + id, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Manager
export function getDefaultManagerList(siteToken,bearerToken,email,gid,roleID,roleName,uid,id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken},
  };
  return fetch(
    baseApiURL + "/UserManagement/GetDefaultMngrList?ModuleId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Company
export function getCompanyList(siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    userId: uid,
    RoleName: roleName,
    Gid: gid,
    Roleid: roleID,
    AzureToken: siteToken},
  };
  return fetch(baseApiURL + "/UserManagement/GetCompanyList", options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Division
export function getDivisionList(siteToken,bearerToken,email,gid,roleID,roleName,uid,id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken, },
  };
  return fetch(
    baseApiURL + "/UserManagement/GetDivisionByCompanyId?CompanyId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//BU
export function getBUList(siteToken,bearerToken,email,gid,roleID,roleName,uid,id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken},
  };
  return fetch(
    baseApiURL + "/UserManagement/GetBUListByDivision?DivisionId=" + id,
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//BS
export function getBSList(siteToken,bearerToken,email,gid,roleID,roleName,uid,id) {
  const options = {
    method: "get",
    headers: { Authorization: "Bearer " + bearerToken,
    UserEmail: email,
    Gid: gid,
    Roleid: roleID,
    RoleName: roleName,
    userId: uid,
    AzureToken: siteToken },
  };
  return fetch(baseApiURL + "/UserManagement/GetBSListByBU?BuId=" + id, options)
    .then((response) => response.json())
    .then((data) => {
      return data.result;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
//Add User Details
export function addUserData(formData,siteToken,bearerToken,email,gid,roleID,roleName,uid) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken 
    },
    body: JSON.stringify(formData),
  };
  return fetch(
    baseApiURL + "/UserManagement/SaveUserManagementDetails",
    options
  )
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log("Error : ", error);
    });
}
