import React, { useState, useEffect } from "react";
import { Grid, Button, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import { tableIconsInputScreen } from "../../../Utilities/Utility";
import AddIcon from "@material-ui/icons/Add";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { baseAddressURL } from "../../../Utilities/Utility";
import Loader from "../../../Components/Loader/Loader";
import { getUserList } from "../../../Redux/API/api_userManagement";

export default function UserManagement() {
  const [masterData, setMasterData] = useState([]);
  let [isLoader, setIsLoader] = useState(false);
  const token = useSelector((state) => state.saveTokenReducer.token);
  const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
  const uId = useSelector((state) => state.saveUIDReducer.uid);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );

  function GetEditButton(rowData) {
    return (
      <Tooltip title="Edit User" placement="bottom">
        <span>
          <Button href="javascript:void(0)">
            <span>
              <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
            </span>
          </Button>
        </span>
      </Tooltip>
    );
  }

  function btoaSecure(value) {
    const { btoa } = require("abab");
    let currDate = new Date().toDateString();
    let valueModified = value + " " + currDate;
    let valueSecured = btoa(btoa(valueModified));
    return valueSecured;
  }

  function clickEditHardCodeMaster(rowData) {
    let id = rowData.userId;
    let idEncrypted = btoaSecure(id);
    window.location.href = baseAddressURL + "/addUser?id=" + idEncrypted;
  }

  function getUserData() {
    setIsLoader(true);
    getUserList(token,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
      .then((response) => {
        setMasterData(response);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  const columns = [
    { title: "User Id", field: "userId", hidden: true },
    { title: "First Name", field: "firstName", align: "left" },
    { title: "Last Name", field: "lastName", align: "left" },
    { title: "Email", field: "emailId", align: "left" },
    {
      title: "GID",
      field: "gid",
      align: "left",
    },
    { title: "Module", field: "moduleName", align: "left" },
    { title: "Default Manager", field: "defaultManagerName", align: "left" },
    { title: "Role", field: "roleName", align: "left" },
    {
      title: "Company",
      field: "companyName",
      align: "left",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.companyName}>
            <span>{rowData.companyName.slice(0, 10)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "Division",
      field: "divisionName",
      align: "left",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.divisionName}>
            <span>{rowData.divisionName.slice(0, 10)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "BU",
      field: "buname",
      align: "left",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.buname}>
            <span>{rowData.buname.slice(0, 10)}</span>
          </Tooltip>
        );
      },
    },
    {
      title: "BS",
      field: "bsname",
      align: "left",
      render: (rowData) => {
        return (
          <Tooltip title={rowData.bsname}>
            <span>{rowData.bsname.slice(0, 10)}</span>
          </Tooltip>
        );
      },
    },
    { title: "Status", field: "fullStatus", align: "left" },
  ];

  function clickAddUser() {
    window.location.href = baseAddressURL + "/addUser";
  }

  useEffect(() => {
    if (token) {
      getUserData();
    }
  }, [token]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} className="d-flex jc-space-bt">
          <Grid item xs={9} className="text-left ml-1">
            <h2>User Management</h2>
          </Grid>
          <Grid item xs={3} className="text-right"></Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title=""
            columns={columns}
            data={masterData}
            icons={tableIconsInputScreen}
            options={{
              exportButton: {
                csv: true,
                pdf: false,
              },
              search: true,
              exportAllData: true,
              exportFileName: "UserList",
              pageSize: 10,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [10, 20, 30, 50],
              rowStyle: (x) => {
                if (x.tableData.id % 2) {
                  return { backgroundColor: "#F2FCFC" };
                } else {
                  return { backgroundColor: "white" };
                }
              },
            }}
            actions={[
              {
                icon: AddIcon,
                isFreeAction: true,
                tooltip: "Add New User",
                onClick: () => {
                  clickAddUser();
                },
              },
              {
                icon: GetEditButton,
                tisFreeAction: true,

                onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={1} className="mb-1">
          <Link to={"/admin"}>
            <Button
              type="submit"
              className="pt-button--tertiary"
              id="SupplierLoginBtn"
              fullWidth>
              &lt; &lt; Back
            </Button>
          </Link>
        </Grid>
      </Grid>
      {isLoader ? <Loader loaderText="Loading...." /> : <></>}
    </div>
  );
}
