import { baseApiURL } from "../../Utilities/Utility";

//? Get Apis - Dropdown
export async function getRatioTypeList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetRatioType?natureID=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getNatureOfServiceList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Master/GetNatureOfService?moduleId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getPOMasterList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Payment/GetPOMaster?poId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getConsultantNameList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Payment/GetConsultant?ConsultantId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getToolCaseNoList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Payment/GetToolCaseIdNoList",
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getPOBasedOnConsultantList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Payment/GetPOMasterList?consultantId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getToolCaseBasedData(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  toolcaseId,
  invoiceValue,
  paymentId,
  ratioType
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    debugger;
    const response = await fetch(
      baseApiURL +
        "/Payment/GetPaymentRatioDetail?toolCaseId=" +
        toolcaseId +
        "&consultantInvoiceValue=" +
        invoiceValue +
        "&paymentId=" +
        paymentId +
        "&ratioType=" +
        ratioType,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function getPaymentList(
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid,
  id
) {
  const options = {
    method: "get",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
  };
  try {
    const response = await fetch(
      baseApiURL + "/Payment/GetPayment?paymentId=" + id,
      options
    );
    const data = await response.json();
    return data.result;
  } catch (error) {
    console.log("Error : ", error);
  }
}
//? Post Methods
export async function addPOMaster(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Payment/SavePOMaster", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}
export async function uploadPaymentDetails(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "post",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(baseApiURL + "/Payment/SavePayment", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

//? Edit Methods
export async function editPOMaster(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      Gid: gid,
      Roleid: roleID,
      RoleName: roleName,
      userId: uid,
      AzureToken: siteToken,
    },
    body: JSON.stringify(formData),
  };
  try {
    const response = await fetch(baseApiURL + "/Payment/EditPOMaster", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}

export async function UpdatePaymentDetails(
  formData,
  siteToken,
  bearerToken,
  email,
  gid,
  roleID,
  roleName,
  uid
) {
  const options = {
    method: "put",
    headers: {
      Authorization: "Bearer " + bearerToken,
      UserEmail: email,
      userId: uid,
      RoleName: roleName,
      Gid: gid,
      Roleid: roleID,
      AzureToken: siteToken,
    },
    processData: false,
    mimeType: "multipart/form-data",
    contentType: false,
    body: formData,
  };
  try {
    const response = await fetch(baseApiURL + "/Payment/EditPayment", options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error : ", error);
  }
}