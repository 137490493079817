import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./assets/breakpoint.scss";
import "./App.css";
import { LoginRoute } from "./Routes/LoginRoute";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import {
  authUserAction,
  saveAccessTokenAction,
  storeUserDetailsAction,
} from "../src/Redux/Actions/actions";
import { useMsal } from "@azure/msal-react";
import {
  MasterRoute,
  TaxAdminRouteIDT,
  TaxHeadIDT,
  TaxManagerRouteIDT,
  TaxProfessionalRouteIDT,
} from "./Routes/MasterRoute";
import Header from "./Components/Header/Header";
import Loader from "./Components/Loader/Loader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import UnauthorizedAccess from "./Components/UnauthorizedAccess/UnauthorizedAccess";
import IdleTimer from "react-idle-timer";
import { authenticateUserBasedOnValidToken } from "./Redux/API/api_Login";

export default function App({ msalInstance }) {
  const auth = useAuth();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.saveTokenReducer.token);
  const userDetails = useSelector(
    (state) => state.storeUserDetailsReducer.userData
  );
  const [isLoader, setIsLoader] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { instance, accounts } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setDialogOpen(false);
  };

  function sessionExpire(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    if (auth !== undefined) {
      dispatch(storeUserDetailsAction(""));

      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    window.location.href = "/";
  }
  function authenticateUserWithGID() {
    authenticateUserBasedOnValidToken(dispatch, activeAccount.idToken)
      .then((response) => {
        if (response) {
          setIsLoader(false);
        } else {
          setIsLoader(false);
          setDialogOpen(true);
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if (activeAccount) {
      setIsLoader(true);

      let gid = accounts[0].idTokenClaims.gid;
      dispatch(authUserAction(window.btoa(gid)));
      dispatch(saveAccessTokenAction(activeAccount.idToken));
      authenticateUserWithGID();
    } else {
      setIsLoader(false);
    }
  }, [accounts]);

  return (
    <BrowserRouter>
      <div className="ssiat-master-block">
        {isLoader ? (
          <Loader />
        ) : userDetails && userDetails.roleId > 0 ? (
          <div className="potts-master-block">
            <Header>Header Component</Header>
            <main className="main-component">
              {userDetails.roleId === 1 ? (
                MasterRoute
              ) : userDetails.roleId === 2 ? (
                TaxProfessionalRouteIDT
              ) : userDetails.roleId === 3 ? (
                TaxManagerRouteIDT
              ) : userDetails.roleId === 4 ? (
                TaxHeadIDT
              ) : userDetails.roleId === 5 ? (
                TaxAdminRouteIDT
              ) : (
                <Loader />
              )}
            </main>
          </div>
        ) : (
          LoginRoute
        )}
      </div>

      <div>
        <IdleTimer
          timeout={100000 * 60 * 15}
          onIdle={sessionExpire}
          debounce={250}
        />
      </div>
      <>
        <Dialog
          disableEscapeKeyDown
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {auth.error ? "Un-Authorized" : "Un-Authorized"}
          </DialogTitle>
          <DialogContent>
            <UnauthorizedAccess />
          </DialogContent>
        </Dialog>
      </>
    </BrowserRouter>
  );
}
