import DateFnsUtils from "@date-io/date-fns";
import { Link } from "react-router-dom";
import {
    Button,
    Grid,
    MenuItem,
    TextField,
    Tooltip,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import AddIcon from "@material-ui/icons/Add";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import {
    GetGeneralEnqFormDetails,
    SaveMetaData,
    getCompany,
    getEnquiryList,
    getGSTINList,
    getLocationList,
    getTaxManagerList,
    getYear,
} from "../../Redux/API/api_createEnquiry";
import {
    baseAddressURL,
    baseApiURL,
    CreateEnqModuleId,
    IDTModuleId,
    tableIconsInputScreen,
} from "../../Utilities/Utility";
import {
    getMergedEntityList,
    getTypeOfRegList,
} from "../../Redux/API/api_master";
import {
    CheckModuleProceedStatus,
    DeleteEventById,
    UploadModuleWiseIssues,
    addCfrNumber,
    getDependantSubModuleList,
    getEventsList,
    getIssueDetailList,
    getLastUploadedIssues,
    getLastUploadedIssuesList,
    getModuleWiseIssuesList,
    updateToScnModule,
} from "../../Redux/API/api_events";
import { getSubModuleList } from "../../Redux/API/api_admin";
import IDTENQTemplate from "../../assets/template/UploadTemplate/IDTEnquiryTemplate.xlsx";
import ScnGstinTemplate from "../../assets/template/UploadTemplate/SCN-GST-Template.xlsx";
import ScnOthersTemplate from "../../assets/template/UploadTemplate/SCN-NONGST-Template.xlsx";
import AppealGSTINTemplate from "../../assets/template/UploadTemplate/AppealGSTTemplate.xlsx";
import AppealExciseTemplate from "../../assets/template/UploadTemplate/AppealExciseTemplate.xlsx";
import AppealCstTemplate from "../../assets/template/UploadTemplate/AppealCSTTemplate.xlsx";
import AppealVatTemplate from "../../assets/template/UploadTemplate/AppealVATTemplate.xlsx";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {
    saveEventToolCaseIdAction,
    saveFromDate,
    storeIssueDetailsAction,
    storeToolCaseNo,
    storeIssueDesc
} from "../../Redux/Actions/actions";
import Icon from "@material-ui/core/Icon";
import { ChangeStatusOfToolCase } from "./../../Redux/API/api_toolCaseList";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BellIcon from "@material-ui/icons/NotificationImportant";
import VatCstCSTTemplate from "../../assets/template/UploadTemplate/VATCST-CST.xlsx";
import VatCstVATTemplate from "../../assets/template/UploadTemplate/VATCST-VAT.xlsx";
import VdTemplate from "../../assets/template/UploadTemplate/VehicleDetention.xlsx";
import VdAppealTemplate from "../../assets/template/UploadTemplate/AppealVDTemplate.xlsx";

import "./toolcase.scss";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: `90%`,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    header: {
        display: `flex`,
        justifyContent: `center`,
    },
    sectionHeader: {
        marginTop: `1em`,
        marginBottom: `0.8em`,
        //borderBottom: `2px solid #00BCBC`,
    },
    sectionHeader2: {
        marginTop: `0.8em`,
        marginBottom: `0.8em`,
    },
    rejectDiv: {
        color: `red`,
    },
    aTagTemplate: {
        color: `#00BCBC`,
        fontStyle: `italic`,
        borderBottom: `1px solid #00BCBC`,
        fontWeight: `bold`,
        width: `fit-content`,
        padding: `0`,
        margin: `0`,
    },
    errorTag: {
        color: `#FF5454`,
        fontStyle: `italic`,
        borderBottom: `1px solid #FF5454`,
        fontWeight: `bold`,
        width: `fit-content`,
        padding: `0`,
        margin: `0`,
    },
    p0: {
        paddingTop: `0 !important`,
        paddingBottom: `0 !important`,
    },
    m0: {
        marginTop: `0 !important`,
        marginBottom: `0 !important`,
    },
    mainDiv: {
        padding: `0 8px !important`,
    },
    deleteBtn: {
        color: `red`,
    },
    issueHistory: {
        padding: `0`,
        margin: `0`,
        color: `#00BCBC`,
        fontStyle: `italic`,
        fontWeight: `bold`,
        width: `fit-content`,
    },
    cfrNo: {
        padding: `0`,
        margin: `0`,
        color: `#00BCBC`,
        fontWeight: `bold`,
        width: `fit-content`,
    },
    notificationBtn: {
        padding: `0`,
    },
    bellIcon: {
        color: `#FF5454`,
        height: `1.2em`,
        width: `1.2em`,
    },
    dialogHeader: {
        margin: `0`,
    },
    pTag: {
        margin: `8px 0`,
    },
    subGrid: {
        boxShadow: `1px 2px 5px 3px #ccc`,
        marginTop: `1rem`,
        paddingBottom: `8px`,
        marginBottom: `1rem`,
        borderRadius: `8px`,
    },
}));

export default function ToolCaseRegistration() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveTokenReducer.token);
    const bearerToken = useSelector((state) => state.saveBearerTokenReducer.bearerToken);
    const uId = useSelector((state) => state.saveUIDReducer.uid);
    //* Loader, Ids && Reject Hooks
    const [rejectMsg, setRejectMsg] = useState("");
    let [isLoader, setIsLoader] = useState(false);
    const [enqId, setEnqId] = useState("");
    var encryptedId = getURLParameter("id");
    const [approveStatus, setApproveStatus] = useState("");
    const [draftNo, setDraftNo] = useState("");
    const [toolcaseId, setToolcaseId] = useState("");
    const [toolcaseNo, setToolcaseNo] = useState("");
    const [metaDataId, setMetaDataId] = useState("");
    const [currentStageId, setCurrentStageId] = useState("");
    const [currentStageName, setCurrentStageName] = useState("");
    const [movingStatus, setMovingStatus] = useState("");
    const [issueDataLength, setIssueDataLength] = useState(0);
    const [selectedModule, setSelectedModule] = useState("");
    //* CFR No
    const [openCFRDialog, setOpenCFRDialog] = useState(false);
    const [cfrNo, setCfrNo] = useState("");
    const [cfrData, setCfrData] = useState("");
    const [latestExcelLInk, setLatestExcelLink] = useState("");
    //*  Alert Dialog
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [notificationData, setNotificationData] = useState([]);
    //*  Meta Data Hooks
    const [subModule, setSubModule] = useState([]);
    const [subModuleValue, setSubModuleValue] = useState("");
    const [depSubModule, setDepSubModule] = useState([]);
    const [depSubModuleValue, setDepSubModuleValue] = useState("");
    const [depSubModuleName, setDepSubModuleName] = useState("");
    const [entity, setEntity] = useState([]);
    const [entityValue, setEntityValue] = useState("");
    const [mergedEntity, setMergedEntity] = useState([]);
    const [mergedEntityValue, setMergedEntityValue] = useState("");
    const [enquiry, setEnquiry] = useState([]);
    const [enquiryValue, setEnquiryValue] = useState("");
    const [location, setLocation] = useState([]);
    const [locationValue, setLocationValue] = useState("");
    const [profValue, setProfValue] = useState(
        userDetails.firstName + " " + userDetails.lastName
    );
    const [profId, setProfId] = useState(userDetails.userId);
    const [manager, setManager] = useState([]);
    const [managerValue, setManagerValue] = useState("");
    const [scn, setScn] = useState([]);
    const [scnValue, setScnValue] = useState("");
    const [gst, setGst] = useState([]);
    const [gstValue, setGstValue] = useState("");
    const [natureOfTax, setNatureOfTax] = useState([]);
    const [natureOfTaxValue, setNatureOfTaxValue] = useState("");
    const [natureOfTaxName, setNatureOfTaxName] = useState("");
    const [fromPeriod, setFromPeriod] = useState(null);
    const [fromPeriodValue, setFromPeriodValue] = useState(null);
    const [toPeriod, setToPeriod] = useState(null);
    const [toPeriodValue, setToPeriodValue] = useState(null);
    const [year, setYear] = useState([]);
    const [interestStartDate, setInterestStartDate] = useState(null);
    const [interestStartDateValue, setInterestStartDateValue] = useState(null);
    const [monthlyInterest, setMonthlyInterest] = useState("");
    const [monthlyInterestHelper, setMonthlyInterestHelper] = useState("");
    //* Table and Other Hooks
    const [events, setEvents] = useState([]);
    const [enqIssuesLists, setEnqIssuesLists] = useState([]);
    const [scnIssuesLists, setSCNIssuesLists] = useState([]);
    const [downloadPath, setDownloadPath] = useState("");
    const [errorFileName, setErrorFileName] = useState("");
    const [reloadIssueData, setReloadIssueData] = useState(false);
    const [selectedFileUploadIssues, setSelectedFileUploadIssues] =
        useState(null);
    const [inputFileValueUploadIssues, setInputFileValueUploadIssues] = useState(
        Date.now
    );
    const historyLink = "/issueWiseCompleteList?id=" + btoaSecure(toolcaseId);
    //* Snackbar
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (e) => {
        setOpenSnackbar(false);
        if (alertType === "success") {
            window.location.href = baseAddressURL + "/ViewToolCase";
        }
    };
    const [openSnackbarInternal, setOpenSnackbarInternal] = useState(false);
    const [snackMessageInternal, setSnackMessageInternal] = useState("");
    const [alertTypeInternal, setAlertTypeInternal] = useState("");
    const handleSnackOpenInternal = (text, type) => {
        setSnackMessageInternal(text);
        setAlertTypeInternal(type);
        setOpenSnackbarInternal(true);
    };

    //? CFR Dialog
    const handleClickOpenCFRDialog = () => {
        setOpenCFRDialog(true);
    };
    const handleCloseCFRDialog = () => {
        setCfrNo("");
        setOpenCFRDialog(false);
    };
    const handleCfrNo = (event) => {
        setCfrNo(event.target.value);
    };
    //? Alert Dialog
    const handleClickOpenAlertDialog = () => {
        setIsLoader(false);
        getIssueDetail();
        setAlertDialogOpen(true);
    };
    const handleCloseAlertDialog = () => {
        setAlertDialogOpen(false);
        setNotificationData([]);
    };

    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;
    }
    const handleCloseSnackbarInternal = (e) => {
        setOpenSnackbarInternal(false);
        if (
            alertTypeInternal === "success" &&
            snackMessageInternal === "Issues Uploaded Successfully."
        ) {
            getIssuesList(toolcaseId, selectedModule);
            setSelectedFileUploadIssues(null);
            setInputFileValueUploadIssues(Date.now);
        } else if (
            snackMessageInternal === "CFR Number Added Successfully." &&
            alertTypeInternal === "success"
        ) {
            getToolCaseData(atobSecure(encryptedId));
        }
        // getToolCaseData(atobSecure(encryptedId));
        // getIssuesList(toolcaseId, selectedModule);
    };

    //* Meta Data Handlers
    const handleChangeEntity = (event) => {
        setEntityValue(event.target.value);
        setLocationValue("");
        setMergedEntityValue("");
        setGstValue("");
        getLocation(event.target.value);
        getMergedEntity(event.target.value);
        if (locationValue && natureOfTaxValue) {
            getGSTIN(locationValue, event.target.value, natureOfTaxValue);
        }
    };
    const handleChangeMergedEntity = (event) => {
        setMergedEntityValue(event.target.value);
        setLocationValue("");
        setGstValue("");
        if (event.target.value !== 0) {
            getLocation(event.target.value);
        } else {
            getLocation(entityValue);
        }
        if (locationValue) {
            getGSTIN(locationValue, event.target.value, natureOfTaxValue);
        }
    };
    const handleChangeEnquiry = (event) => {
        setEnquiryValue(event.target.value);
    };
    const handleChangeAssessmentLocation = (event) => {
        setLocationValue(event.target.value);
        setGstValue("");
        if (entityValue && !mergedEntityValue) {
            getGSTIN(event.target.value, entityValue, natureOfTaxValue);
        }
        if (!entityValue && mergedEntityValue) {
            getGSTIN(event.target.value, mergedEntityValue, natureOfTaxValue);
        }
    };
    //const handleManager = (event) => {
    //  setManagerValue(event.target.value);
    //};
    const handleGST = (event) => {
        setGstValue(event.target.value.toString());
    };
    const handleNatureOfTax = (event) => {
        setNatureOfTaxValue(event.target.value);
        getNatureOfTaxName(event.target.value);
        if (entityValue && !mergedEntityValue) {
            getGSTIN(locationValue, entityValue, event.target.value);
        }
        if (mergedEntityValue) {
            getGSTIN(locationValue, mergedEntityValue, event.target.value);
        }
    };
    const handleChangeAssesmentLocation = (event) => {
        setLocationValue(event.target.value);
        setGstValue("");
        if (entityValue && !mergedEntityValue) {
            getGSTIN(event.target.value, entityValue, natureOfTaxValue);
        }
        if (mergedEntityValue) {
            getGSTIN(event.target.value, mergedEntityValue, natureOfTaxValue);
        }
    };
    const handleFromPeriod = (date) => {
        setYearString("");
        setSelectedOptionsYear([]);
        setFromPeriodValue(date);
        if (date !== null) {
            var newDate = date.toString();
            if (toPeriodValue && newDate !== "Invalid Date") {
                GetFinancialYears(date, toPeriodValue);
            }
        }
    };
    const handleToPeriod = (date) => {
        setYearString("");
        setSelectedOptionsYear([]);
        setToPeriodValue(date);
        if (date !== null) {
            var newDate = date.toString();
            if (fromPeriodValue && newDate !== "Invalid Date") {
                GetFinancialYears(fromPeriodValue, date);
            }
        }
    };
    // const handleAnnualReturnDate = (date) => {
    //   let newDate = JSON.stringify(date);
    //   setInterestStartDate(newDate.slice(1, 11));
    //   setInterestStartDateValue(newDate);
    // };
    const handleAnnualReturnDate = (date) => {
        let newDate = JSON.stringify(date);
        setInterestStartDate(newDate.slice(1, 11));
        setInterestStartDateValue(date);
    };

    const handleMonthlyInterest = (e) => {
        setMonthlyInterest(e.target.value);
        let valid = /^((\d+(\.\d*)?)|(\.\d+))$/.test(e.target.value);
        if (e.target.value === "") {
            setMonthlyInterestHelper("Cannot be left blank");
        } else if (!valid) {
            setMonthlyInterestHelper("Numbers only");
        } else if (parseFloat(e.target.value) === 0) {
            setMonthlyInterestHelper("Cannot be 0");
        } else if (parseFloat(e.target.value) > 100) {
            setMonthlyInterestHelper("Cannot be greater than 100");
        } else {
            setMonthlyInterestHelper("");
        }
    };

    const handleChangeScn = (event) => {
        setScnValue(event.target.value);
    };
    const handleChangeSubModule = (event) => {
        setNatureOfTaxValue("");
        setDepSubModuleValue("");
        setSubModuleValue(event.target.value);
        getTypeOfReg(event.target.value);
        if (!depSubModuleValue && encryptedId) {
            getDependantSubModules(1, event.target.value);
        }
    };
    const handleChangeFileDept = (event) => {
        setSelectedFileUploadIssues(event.target.files[0]);
    };
    const handleDependantSubModule = (event) => {
        setDepSubModuleValue(event.target.value);
    };

    const handleFilterSubModule = (event) => {
        setSelectedModule(event.target.value);
        getLatestIssueUploaded(toolcaseId, event.target.value);
        getIssuesList(atobSecure(encryptedId), event.target.value);
    };
    //!Multi-select
    const [selectedOptionsYear, setSelectedOptionsYear] = useState([]);
    const [yearData, setYearData] = useState([]);
    const [filterYear, setFilterYear] = useState("");
    function editYearByUser(filterYear) {
        var arrayYear = filterYear.split(",");
        setSelectedOptionsYear(year.filter((x) => arrayYear.includes(x.value)));
    }
    let yearArr = [];
    const [yearString, setYearString] = useState("");
    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }
    function onChangeYear(value, event) {
        let yearValue = [];
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            this.setState([]);
            yearValue = [];
        } else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        } else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        } else {
            this.setState(value);
        }
        if (event.action === "select-option" && event.option.value === "*") {
            yearValue = year;
        } else if (
            event.action === "deselect-option" &&
            event.option.value === "*"
        ) {
            yearValue = [];
        } else {
            yearValue = value;
        }
        for (let i = 0; i < yearValue.length; i++) {
            if (yearValue[i].value !== undefined) yearArr.push(yearValue[i].value);
        }
        setYearString(yearArr.toString());
    }

    //* Issue Grid Columns
    const liabilityColumnsGSTIN = [
        {
            title: "Module",
            field: "subModuleName",
            render: (rowData) =>
                rowData.issueName === "Total" ? "" : rowData.subModuleName,
        },
        { title: "Issue", field: "issueName" },
        { title: "Brief Description", field: "briefDescName" },
        {
            title: "Issue Remark",
            field: "issueRemark",
            render: (rowData) => {
                return (
                    <Tooltip title={rowData.issueRemark}>
                        <span>{rowData.issueRemark ? rowData.issueRemark.slice(0, 20) : ""}</span>
                    </Tooltip>
                );
            },
        },
        {
            title: "CGST",
            field: "cgst",
            hidden: natureOfTaxName !== "GST",
        },
        {
            title: "SGST",
            field: "sgst",
            hidden: natureOfTaxName !== "GST",
        },
        {
            title: "IGST",
            field: "igst",
            hidden: natureOfTaxName !== "GST",
        },
        {
            title: "CGST",
            field: "cgst",
            hidden: natureOfTaxName !== "Temporary GST",
        },
        {
            title: "SGST",
            field: "sgst",
            hidden: natureOfTaxName !== "Temporary GST",
        },
        {
            title: "IGST",
            field: "igst",
            hidden: natureOfTaxName !== "Temporary GST",
        },
        {
            title: "Basic",
            field: "basic",
            hidden:
                natureOfTaxName === "GST" ||
                natureOfTaxName === "Temporary GST" ||
                natureOfTaxName === "VAT" ||
                natureOfTaxName === "CST",
        },
        {
            title: "Ecess/SBC",
            field: "ecessOrSbc",
            hidden:
                natureOfTaxName === "GST" ||
                natureOfTaxName === "Temporary GST" ||
                natureOfTaxName === "VAT" ||
                natureOfTaxName === "CST",
        },
        {
            title: "SHEcess/KKC",
            field: "shecessOrKkc",
            hidden:
                natureOfTaxName === "GST" ||
                natureOfTaxName === "Temporary GST" ||
                natureOfTaxName === "VAT" ||
                natureOfTaxName === "CST",
        },
        {
            title: "Tax",
            field: "tax",
            hidden: natureOfTaxName !== "VAT" && natureOfTaxName !== "CST",
        },
        {
            title: "Refund Adjusted Against VAT",
            field: "refundAdjustedAgainstVat",
            hidden: natureOfTaxName !== "CST",
        },
        {
            title: "Refund Adjusted Against CST",
            field: "refundAdjustedAgainstCst",
            hidden: natureOfTaxName !== "VAT",
        },
        {
            title: "Net Tax Liability",
            field: "netTaxLiability",
            hidden: natureOfTaxName !== "VAT" && natureOfTaxName !== "CST",
        },
        { title: "Interest", field: "interest" },
        {
            title: "Penalty",
            field: "penalty",
            hidden: subModuleValue === 2 ? true : false,
        },
        {
            title: "Others",
            field: "penalty",
            hidden: subModuleValue !== 2 ? true : false,
        },
        { title: "Total Sum", field: "totalSum" },
        { title: "Pre-Deposit", field: "predeposit" },
        { title: "Provisions", field: "provision" },
        {
            title: "Current Appeal Stage",
            field: "currentStageName",
            hidden: selectedModule !== 5 ? true : false,
        },
        { title: "Status", field: "issueLevelStatusName" },
        {
            title: "Remand Id",
            field: "remandCounter",
            hidden: selectedModule === 1 ? true : false,
            render: (rowData) =>
                rowData.isRemanded === true ? (rowData.remandCounter === null || rowData.remandCounter === 0 ? "Remand " : "Remand " + rowData.remandCounter) : "",
        },
        {
            title: "Destination",
            field: "remandHigherStageName",
            hidden: selectedModule !== 5 ? true : false,
        },
    ];

    const eventColumns = [
        {
            title: "Edit/Delete",
            field: "subModuleName",
            render: (rowData) => rowData && getActionButtons(rowData),
            hidden: userDetails.roleId !== 1 ? true : false,
        },
        { title: "Event/Action Module", field: "subModuleName" },
        { title: "Event/Action Type", field: "eventTypeName" },
        {
            title: "Event/Action By",
            field: "eventActionByName",
        },
        {
            title: "Event/Action Date",
            field: "eventDateString",
        },
        { title: "Document Mode", field: "documentModeName" },
        {
            title: "Communication Type",
            field: "communicationTypeName",
            hidden: selectedModule !== 1 ? true : false,
        },
        { title: "Document No.", field: "documentNo" },
        {
            title: "Document Date",
            field: "documentDateString",
        },
        {
            title: "Download Document",
            field: "documentPath",
            render: (rowData) => rowData && getButtons(rowData),
        },
        {
            title: "Due date of event/action",
            field: "dueDateString",
        },
        { title: "Adjudicating Authority", field: "adjudicatingAuthorityName" },
        { title: "Created By", field: "createdByName" },
        {
            title: "Remarks",
            field: "eventRemarks",
            align: "left",
            render: (rowData) => {
                return (
                    <Tooltip title={rowData.eventRemarks}>
                        <span>{rowData.eventRemarks.slice(0, 10)}</span>
                    </Tooltip>
                );
            },
        },
    ];

    //! Download EventGrid Files
    function getButtons(rowData) {
        return (
            <div>
                {rowData.documentPath ? (
                    <IconButton
                        className="activationButton"
                        aria-label="activate"
                        disabled={!rowData.documentPath}
                        href={rowData.documentPath}
                        target="_blank">
                        <ArrowDownwardIcon />
                    </IconButton>
                ) : (
                    <NotInterestedIcon />
                )}
            </div>
        );
    }
    //! Event Actions
    function getActionButtons(rowData) {
        return (
            <div>
                <IconButton
                    className="editButton"
                    aria-label="activate"
                    onClick={() => {
                        editEvents(rowData);
                    }}>
                    <Tooltip title="Edit">
                        <EditIcon />
                    </Tooltip>
                </IconButton>

                <IconButton
                    className={classes.deleteBtn}
                    aria-label="delete"
                    onClick={() => {
                        deleteEvents(rowData);
                    }}>
                    <Tooltip title="Delete">
                        <DeleteIcon />
                    </Tooltip>
                </IconButton>
            </div>
        );
    }

    function deleteEvents(rowData) {
        DeleteEventById(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,rowData.eventId)
            .then((response) => {
                if (response.status === 1) {
                    handleSnackOpenInternal("Event Deleted Successfully.", "success");
                    getEvents(atobSecure(encryptedId));
                } else {
                    handleSnackOpenInternal("Error While Deleting Event.", "error");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function editEvents(rowData) {
        dispatch(saveEventToolCaseIdAction(toolcaseId));
        dispatch(saveFromDate(fromPeriodValue));
        let id = rowData.eventId;
        let idEncrypted = btoaSecure(id);
        window.location.href = "/addEvents?id=" + idEncrypted;
    }

    //! Dropdown Functions
    function getSubModule(moduleID) {
        getSubModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleID)
            .then((response) => {
                const newArr = response.filter((object) => {
                    return object.subModuleName !== "Appeal";
                });
                setSubModule(newArr);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getEntity() {
        getCompany(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
            .then((response) => {
                setEntity(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getMergedEntity(companyId) {
        getMergedEntityList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,companyId)
            .then((response) => {
                setMergedEntity(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getEnquiry() {
        getEnquiryList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, IDTModuleId, CreateEnqModuleId)
            .then((response) => {
                setEnquiry(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getLocation(companyId) {
        getLocationList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, companyId)
            .then((response) => {
                setLocation(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getTaxManager(id) {
        getTaxManagerList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, IDTModuleId, id)
            .then((response) => {

                setManager(response);
                setManagerValue(response[0].userId);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getGSTIN(locationId, companyId, typeOfReg) {
        getGSTINList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, locationId, companyId, typeOfReg)
            .then((response) => {
                setGst(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getTypeOfReg(subModuleId) {
        getTypeOfRegList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId,subModuleId)
            .then((response) => {
                setNatureOfTax(response);
                if (response != 0 && natureOfTaxValue) {
                    var regName = response.filter(
                        (c) => c.hardCodeId === natureOfTaxValue
                    );
                    setNatureOfTaxName(regName[0].name);
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function GetFinancialYears(fromdate, toDate) {
        getYear(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, fromdate.toISOString(), toDate.toISOString())
            .then((response) => {
                setYear(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function GetEditFinancialYears(fromdate, toDate) {
        getYear(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, fromdate, toDate)
            .then((response) => {
                setYear(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function NavigateEventsPage(id) {
        dispatch(saveEventToolCaseIdAction(id));
        window.location.href = "/addEvents";
        dispatch(saveFromDate(fromPeriodValue));
    }
    function NavigateIssueHistoryPage() {
        window.location.href =
            "/issueWiseCompleteList?id=" + btoaSecure(toolcaseId);
    }
    function viewIssueDetails(rowData) {
        console.log(rowData); debugger;
        let issueDetails = {
            issueId: rowData.id,
            issueInvolvedId: rowData.issueInvolvedId,
            briefDescId: rowData.briefDescId,
            issueRemarks: rowData.issueRemark,
            issueName: rowData.issueName,
            issueDescName: rowData.briefDescName,
            toolcaseId: rowData.toolCaseId,
            uploadId: rowData.uploadId,
            subModuleId: rowData.subModuleId,
            natureOfTax: natureOfTaxName,
            status: rowData.issueLevelStatusName,
            statusId: rowData.issueLevelStatus,
            caseOriginId: subModuleValue,
            currentStageId: rowData.currentStage,
        };
        dispatch(storeIssueDetailsAction(issueDetails));
        let id = rowData.id;
        let idEncrypted = btoaSecure(id);
        window.location.href = baseAddressURL + "/issueDetails?id=" + idEncrypted;
    }

    function defaultIssueGlobalData() {
        let issueDetails = {
            issueId: 0,
            issueInVolvedId: 0,
            briefDescId: 0,
            issueRemarks: "",
            issueName: "",
            toolcaseId: 0,
            uploadId: 0,
            subModuleId: 0,
            natureOfTax: 0,
            status: "",
            statusId: 0,
            caseOriginId: 0,
            currentStageId: 0,
        };
        dispatch(storeIssueDetailsAction(issueDetails));
        dispatch(saveEventToolCaseIdAction(0));
    }

    function getEvents(eventId, id) {
        getEventsList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, eventId, id)
            .then((response) => {
                setEvents(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getIssuesList(toolcaseId, subModuleId) {
        setIsLoader(true);
        getModuleWiseIssuesList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, toolcaseId, subModuleId)
            .then((response) => {
                setEnqIssuesLists(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function getDependantSubModules(moduleId, subModuleId) {
        getDependantSubModuleList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, moduleId, subModuleId)
            .then((response) => {
                setDepSubModule(response);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //! Download Excel Files

    function getLatestIssueUploaded(toolcaseId, subModuleId) {
        getLastUploadedIssues(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, toolcaseId, subModuleId)
            .then((response) => {
                if (response.status === true) {
                    setLatestExcelLink(response.result);
                }
                if (response.status === false) {
                    setLatestExcelLink("");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    function GetSaveButton(rowData) {
        return (
            <Tooltip
                title={
                    latestExcelLInk === ""
                        ? "No File To Download"
                        : "Download last Uploaded File"
                }
                placement="top">
                <span>
                    <IconButton
                        href={latestExcelLInk}
                        disabled={latestExcelLInk === "" ? true : false}>
                        <CloudDownloadIcon fontSize="small" className="attachFileIcon" />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }

    //! Save Meta data
    function toolcaseGenerated(result) {
        let idEncrypted = btoaSecure(result);
        window.location.href =
            baseAddressURL + "/ToolCaseRegistration?id=" + idEncrypted;
    }
    function submitMetaData() {
        setIsLoader(true);
        var formdataPart1 = new FormData();
        formdataPart1.append("EntityId", entityValue);
        formdataPart1.append("EnquiryTypeId", 0);
        formdataPart1.append(
            "MergedEntityId",
            mergedEntityValue ? mergedEntityValue : 0
        );
        formdataPart1.append("LocationId", locationValue);
        formdataPart1.append("TaxProfessionalId", profId);
        formdataPart1.append("TaxManagerId", managerValue);
        formdataPart1.append("RegistrationNo", gstValue);
        formdataPart1.append("PeriodInvolvedFrom", fromPeriodValue.toISOString());
        formdataPart1.append("PeriodInvolvedTo", toPeriodValue.toISOString());
        formdataPart1.append("AssesmentYear", yearString);
        formdataPart1.append("RegTypeId", natureOfTaxValue);
        formdataPart1.append("SubModuleId", subModuleValue);
        formdataPart1.append(
            "InterestCalculationQuarterStartDate",
            interestStartDateValue ? interestStartDateValue.toISOString() : ""
        );
        formdataPart1.append(
            "InterestPercentPerMonth",
            monthlyInterest ? parseFloat(monthlyInterest) : 0
        );
        formdataPart1.append("CreatedBy", userDetails.userId);
        SaveMetaData(formdataPart1, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
            .then((response) => {
                if (response.status === 1) {
                    handleSnackOpenInternal("Tool Case Added Successfully.", "success");
                    toolcaseGenerated(response.result);
                    setIsLoader(false);
                } else {
                    setIsLoader(false);
                    handleSnackOpenInternal("Error While Adding Tool Case.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("error", error);
            });
    }

    //! Change Status
    function changeStatus() {
        setIsLoader(true);
        ChangeStatusOfToolCase(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, atobSecure(encryptedId))
            .then((response) => {
                if (response === 1) {
                    handleSnackOpen("Tool Case Sent For Approval.", "success");
                    getToolCaseData(atobSecure(encryptedId));
                    setIsLoader(false);
                } else {
                    setIsLoader(false);
                    handleSnackOpen(
                        "Error While Sending Tool Case For Approval.",
                        "error"
                    );
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("error", error);
            });
    }

    //! Change Module
    function checkModuleStatus(toolcaseId, subModuleId) {
        CheckModuleProceedStatus(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, toolcaseId, subModuleId)
            .then((response) => {
                setMovingStatus(response.status);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    function moveToScn() {
        updateToScnModule(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, atobSecure(encryptedId), currentStageId)
            .then((response) => {
                if (response.status === 1) {
                    handleSnackOpenInternal("Tool Case Moved To Next Stage.", "success");
                    getToolCaseData(atobSecure(encryptedId));
                } else {
                    handleSnackOpenInternal(
                        "Error While Moving Tool Case To Next Stage.",
                        "error"
                    );
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    //? Dynamic Grid Data

    function getNatureOfTaxName(id) {
        var regName = natureOfTax.filter((c) => c.hardCodeId === id);
        if (regName.length > 0) {
            setNatureOfTaxName(regName[0].name);
        }
    }

    function resetReloadData(newValue) {
        setReloadIssueData(newValue);
    }
    function changeIssueDataLength(newValue) {
        setIssueDataLength(newValue);
    }
    //! Upload Methods

    function uploadIssues() {
        setIsLoader(true);
        var formdataPart1 = new FormData();
        formdataPart1.append("ToolCaseId", toolcaseId);
        formdataPart1.append("MetaDataId", metaDataId);
        formdataPart1.append("TaxProfessionalId", profId);
        formdataPart1.append("TaxManagerId", managerValue);
        formdataPart1.append("CreatedBy", userDetails.userId);
        formdataPart1.append(
            "SubModuleId",
            selectedModule ? selectedModule : currentStageId
        );
        formdataPart1.append("TypeOfReg", natureOfTaxValue);
        formdataPart1.append("IssuesExcelFile", selectedFileUploadIssues);
        UploadModuleWiseIssues(formdataPart1, siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId)
            .then((response) => {
                if (response.status === 1) {
                    handleSnackOpenInternal("Issues Uploaded Successfully.", "success");
                    setReloadIssueData(true);
                    setErrorFileName("");
                    setIsLoader(false);
                } else if (response.status === 4) {
                    setIsLoader(false);
                    setDownloadPath(response.result);
                    setErrorFileName(response.result);
                    setInputFileValueUploadIssues(Date.now);
                    setSelectedFileUploadIssues(null);
                    handleSnackOpenInternal("Error While Uploading Issues.", "error");
                } else {
                    setIsLoader(false);
                    setErrorFileName("");
                    setInputFileValueUploadIssues(Date.now);
                    setSelectedFileUploadIssues(null);
                    handleSnackOpenInternal(response.result, "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("error", error);
            });
    }

    //! Add CFR Number
    function saveCFRNumber() {
        setIsLoader(true);
        addCfrNumber(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, metaDataId, cfrNo)
            .then((response) => {
                if (response === 1) {
                    handleSnackOpenInternal("CFR Number Added Successfully.", "success");
                    setIsLoader(false);
                    handleCloseCFRDialog();
                } else {
                    setIsLoader(false);
                    handleSnackOpenInternal("Error While Adding CFR Number.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                console.log("error", error);
            });
    }
    //! Get Notification Data
    function getIssueDetail() {
        getIssueDetailList(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, toolcaseId)
            .then((response) => {
                setNotificationData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
    //! Decrypt Methods
    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            var value = "";
            for (var j = 0; j < sParameterName.length; j++) {
                if (sParameterName[j] == sParam) {
                    for (var k = j + 1; k < sParameterName.length; k++) {
                        value = value + sParameterName[k];
                    }
                    break;
                }
                //return sParameterName[1];
            }
            return value;
        }
    }
    encryptedId = getURLParameter("id");
    function getToolCaseData(id) {
        setIsLoader(true);
        GetGeneralEnqFormDetails(siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, id)
            .then((response) => {
                setMetaDataId(response[0].metaDataId);
                setToolcaseId(response[0].toolCaseId);
                setApproveStatus(response[0].approveStatus);
                setSubModuleValue(response[0].subModuleId);
                getTypeOfReg(response[0].subModuleId);
                getDependantSubModules(1, response[0].subModuleId);
                setEntityValue(response[0].entityId);
                getMergedEntity(response[0].entityId);
                getLocation(response[0].entityId);
                setNatureOfTaxValue(response[0].regTypeId);
                setScnValue(response[0].scnAssessmentModuleId);
                setEnquiryValue(response[0].enquiryTypeId);
                setMergedEntityValue(response[0].mergedEntityId);
                setDraftNo(response[0].draftToolCaseNo);
                setToolcaseNo(response[0].toolCaseNo);
                let toolCaseDetails = {
                    draft: response[0].draftToolCaseNo,
                    approved: response[0].toolCaseNo,
                };
                dispatch(storeToolCaseNo(toolCaseDetails));

                setLocationValue(response[0].locationId);
                if (response[0].mergedEntityId === null) {
                    getGSTIN(
                        response[0].locationId,
                        response[0].entityId,
                        response[0].regTypeId
                    );
                } else {
                    getGSTIN(
                        response[0].locationId,
                        response[0].mergedEntityId,
                        response[0].regTypeId
                    );
                }
                setProfValue(response[0].taxProfessionalName);
                setProfId(response[0].taxProfessionalId);
                getTaxManager(response[0].taxProfessionalId);
                setManagerValue(response[0].taxManagerId);
                setGstValue(response[0].registrationNoId);
                setFromPeriodValue(response[0].periodInvolvedFrom);
                setToPeriodValue(response[0].periodInvolvedTo);
                setFromPeriod(response[0].periodInvolvedFrom);
                setToPeriod(response[0].periodInvolvedTo);
                GetEditFinancialYears(
                    response[0].periodInvolvedFrom,
                    response[0].periodInvolvedTo
                );
                editYearByUser(response[0].assesmentYear);
                setFilterYear(response[0].assesmentYear);
                setYearString(response[0].assesmentYear);
                setRejectMsg(response[0].rejectionReason);
                setCurrentStageId(response[0].currentStageId);
                setSelectedModule(response[0].currentStageId);
                setCurrentStageName(response[0].currentStageName);
                setCfrData(response[0].cfrnumber);
                if (response[0].toolCaseId && response[0].currentStageId) {
                    getLatestIssueUploaded(
                        response[0].toolCaseId,
                        response[0].currentStageId
                    );
                }
                if (response[0].currentStageId) {
                    getIssuesList(atobSecure(encryptedId), response[0].currentStageId);
                }
                setInterestStartDate(response[0].interestCalculationQuarterStartDate);
                setInterestStartDateValue(
                    response[0].interestCalculationQuarterStartDate
                );
                setMonthlyInterest(response[0].interestPercentPerMonth);
                setIsLoader(false);
            })
            .catch((error) => {
                console.log("error", error);
                setIsLoader(false);
            });
    }


    useEffect(() => {
        getSubModule(1);
        getEnquiry();
        getEntity();
        getTaxManager(userDetails.userId);
        defaultIssueGlobalData();
        dispatch(saveEventToolCaseIdAction(0));
        dispatch(saveFromDate(null));
        if (encryptedId) {
            getToolCaseData(atobSecure(encryptedId));
            getEvents(atobSecure(encryptedId));
        }
        if (currentStageId) {
            checkModuleStatus(atobSecure(encryptedId), currentStageId);
        }
    }, [siteToken,bearerToken,userDetails.emailId,userDetails.gid,userDetails.roleId,userDetails.roleName,uId, encryptedId, currentStageId, depSubModuleValue]);

    useEffect(() => {
        if (natureOfTax.length > 0 && natureOfTaxValue) {
            getNatureOfTaxName(natureOfTaxValue);
        }
    }, [natureOfTax.length > 0, natureOfTaxValue]);

    useEffect(() => {
        editYearByUser(filterYear);
    }, [year, filterYear]);

    return (
        <div className={classes.mainDiv}>
            <Grid container className="d-flex jc-space-bt">
                <Grid item xs={12} className="d-flex jc-space-bt">
                    <h3 className={classes.sectionHeader}>Tool Case Form</h3>
                    <IconButton
                        color="secondary"
                        aria-label="Check Pending Tasks"
                        className={classes.notificationBtn}>
                        <Tooltip title="Check Pending Tasks">
                            <BellIcon
                                className={classes.bellIcon}
                                onClick={handleClickOpenAlertDialog}
                            />
                        </Tooltip>
                    </IconButton>
                </Grid>
                {rejectMsg && approveStatus === "Rejected" ? (
                    <Grid item xs={12} md={4} lg={3} className="d-flex jc-flex-start m-0">
                        <h4 className={classes.rejectDiv}>
                            Rejection Reason:- {rejectMsg}
                        </h4>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>

            <Grid container spacing={2} className={classes.subGrid}>
                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Initiating Module Name"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        disabled={encryptedId ? true : false}
                        value={subModuleValue}
                        onChange={handleChangeSubModule}
                        variant="filled">
                        {subModule.map((item) => (
                            <MenuItem key={item.subModuleId} value={item.subModuleId}>
                                {item.subModuleName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Entity Name"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={entityValue}
                        onChange={handleChangeEntity}
                        variant="filled">
                        {entity.map((item) => (
                            <MenuItem key={item.companyId} value={item.companyId}>
                                {item.companyName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Merged Entity Name"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={mergedEntityValue}
                        onChange={handleChangeMergedEntity}
                        variant="filled">
                        <MenuItem key={0} value={0}>
                            None
                        </MenuItem>
                        {mergedEntity.map((item) => (
                            <MenuItem key={item.companyId} value={item.companyId}>
                                {item.companyName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="Tool Case No"
                        id="toolcaseno"
                        name="desiredOPInKW"
                        className=""
                        fullWidth
                        disabled
                        //onChange={handleInputChange}
                        value={approveStatus === "Approved" ? toolcaseNo : draftNo}
                        variant="filled"
                        InputLabelProps={{ shrink: draftNo ? true : false }}></TextField>
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Assesment Location"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={locationValue}
                        onChange={handleChangeAssesmentLocation}
                        variant="filled">
                        {location.map((item) => (
                            <MenuItem key={item.stateId} value={item.stateId}>
                                {item.state}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        label="IDT - Tax Professional"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        disabled
                        value={profValue}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"></TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="IDT - Tax Manager"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={managerValue}
                        //onChange={handleManager}
                        variant="filled">
                        {manager.map((item) => (
                            <MenuItem key={item.userId} value={item.userId}>
                                {item.firstName} {item.lastName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Nature of Tax"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        disabled={encryptedId ? true : false}
                        value={natureOfTaxValue}
                        onChange={handleNatureOfTax}
                        variant="filled">
                        {natureOfTax.map((item) => (
                            <MenuItem key={item.hardCodeId} value={item.hardCodeId}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={12} md={3}>
                    <TextField
                        select
                        label="Registration No"
                        id=""
                        name=""
                        className=""
                        fullWidth
                        value={gstValue}
                        onChange={handleGST}
                        variant="filled">
                        <MenuItem key="" value="">
                            Select
                        </MenuItem>
                        {gst.map((item) => (
                            <MenuItem key={item.companyGstid} value={item.companyGstid}>
                                {item.gstin}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="fromPeriod"
                            className="datepicker"
                            label="From Period Involved"
                            format="dd/MM/yyyy"
                            fullWidth
                            disableFuture
                            value={fromPeriodValue}
                            onChange={handleFromPeriod}
                            //maxDate={toPeriodValue}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="toPeriod"
                            className="datepicker"
                            label="To Period Involved"
                            format="dd/MM/yyyy"
                            fullWidth
                            disableFuture
                            value={toPeriodValue}
                            onChange={handleToPeriod}
                            minDate={fromPeriodValue}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                    <ReactMultiSelectCheckboxes
                        options={[{ label: "All", value: "*" }, ...year]}
                        placeholderButtonLabel="Assessment Year"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedOptionsYear}
                        onChange={onChangeYear}
                        setState={setSelectedOptionsYear}
                    />
                </Grid>
                {natureOfTaxName === "CST" ? (
                    <>
                        <Grid item xs={12} md={3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    id="grDate"
                                    className="datepicker"
                                    label="Annual Return Date"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={interestStartDateValue}
                                    onChange={handleAnnualReturnDate}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                    InputProps={{ readOnly: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Monthly Interest Rate(in%)"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                value={monthlyInterest}
                                onChange={handleMonthlyInterest}
                                helperText={monthlyInterestHelper}
                                error={monthlyInterestHelper ? true : false}
                                variant="filled"
                            />
                        </Grid>
                    </>
                ) : (
                    <></>
                )}
                <Grid container item xs={12} spacing={2} className="d-flex jc-center">
                    {!encryptedId ? (
                        <>
                            {userDetails.roleId === 2 ? (
                                <Grid item xs={6} md={1} className="align-self">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        className="pt-button--primary"
                                        onClick={submitMetaData}
                                        disabled={
                                            !entityValue ||
                                            !subModuleValue ||
                                            !locationValue ||
                                            !managerValue ||
                                            !natureOfTaxValue ||
                                            !gstValue ||
                                            !fromPeriodValue ||
                                            !toPeriodValue ||
                                            yearString === "" ||
                                            (natureOfTaxName === "CST" &&
                                                (!monthlyInterest || !interestStartDate)) ||
                                            monthlyInterestHelper
                                        }
                                        id="SupplierLoginBtn">
                                        Save
                                    </Button>
                                </Grid>
                            ) : (
                                <></>
                            )}

                            <Grid item xs={6} md={1} className="align-self">
                                <Link to={"/"}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        //onClick={handleBack}
                                        className="pt-button--secondary"
                                        id="SupplierLoginBtn">
                                        Back
                                    </Button>
                                </Link>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
            {encryptedId ? (
                <>
                    <Grid container spacing={2} className={classes.subGrid}>
                        <Grid item xs={12} className="">
                            <h4 className={classes.sectionHeader2}>Upload Issues</h4>
                        </Grid>
                        <Grid item container xs={12} className={classes.mainDiv}>
                            <Grid item xs={12} md={3} className="">
                                {selectedModule === 1 ? (
                                    <a href={IDTENQTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 3 && natureOfTaxName === "GST" ? (
                                    <a href={ScnGstinTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 3 &&
                                    (natureOfTaxName === "Service Tax - ISD" ||
                                        natureOfTaxName === "Service Tax - Central Registration" ||
                                        natureOfTaxName === "EXCISE") ? (
                                    <a href={ScnOthersTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 5 &&
                                    subModuleValue !== 2 &&
                                    (natureOfTaxName === "GST" ||
                                        natureOfTaxName === "Temporary GST") ? (
                                    <a href={AppealGSTINTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 5 &&
                                    (natureOfTaxName === "Service Tax - ISD" ||
                                        natureOfTaxName === "Service Tax - Central Registration" ||
                                        natureOfTaxName === "EXCISE") ? (
                                    <a href={AppealExciseTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 5 && natureOfTaxName === "VAT" ? (
                                    <a href={AppealVatTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 5 && natureOfTaxName === "CST" ? (
                                    <a href={AppealCstTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 4 && natureOfTaxName === "CST" ? (
                                    <a href={VatCstCSTTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 4 && natureOfTaxName === "VAT" ? (
                                    <a href={VatCstVATTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 2 ? (
                                    <a href={VdTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                                {selectedModule === 5 && subModuleValue === 2 ? (
                                    <a href={VdAppealTemplate}>
                                        <p className={classes.aTagTemplate}>
                                            Click to download Issue Details Template
                                        </p>
                                    </a>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                select
                                label="Module Name"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                value={selectedModule}
                                onChange={handleFilterSubModule}
                                variant="filled">
                                {depSubModule.map((item) => (
                                    <MenuItem key={item.subModuleId} value={item.subModuleId}>
                                        {item.subModuleName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Upload Updated Issue Details Template"
                                id=""
                                name=""
                                className=""
                                fullWidth
                                variant="filled"
                                type="file"
                                key={inputFileValueUploadIssues}
                                onChange={handleChangeFileDept}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={1} className="align-self">
                            {userDetails.roleId === 2 || userDetails.roleId === 1 ? (
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="pt-button--primary"
                                    disabled={!selectedFileUploadIssues}
                                    onClick={uploadIssues}
                                    id="SupplierLoginBtn">
                                    Upload
                                </Button>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        {(userDetails.roleId === 1 && selectedModule === 5) ||
                            (userDetails.roleId === 2 && selectedModule === 5 && !cfrData) ? (
                            <Grid item xs={6} md={1} className="align-self">
                                <Button
                                    type="submit"
                                    fullWidth
                                    className="pt-button--secondary"
                                    onClick={handleClickOpenCFRDialog}
                                    id="SupplierLoginBtn">
                                    Add CFR
                                </Button>
                            </Grid>
                        ) : (
                            <></>
                        )}

                        {errorFileName ? (
                            <Grid item xs={12} className="d-flex jc-center">
                                <a
                                    href={
                                        baseApiURL + "/Common/DownloadLog?fileName=" + errorFileName
                                    }>
                                    <p className={classes.errorTag}>
                                        Click to download Error Log File
                                    </p>
                                </a>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Grid>
                    <Grid container spacing={2} className="mt-1">
                        <Grid item xs={12} md={6} className="d-flex jc-flex-start">
                            {cfrData ? (
                                <p className={classes.cfrNo}>CFR Number:- {cfrData}</p>
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6} className="d-flex jc-flex-end">
                            <a href={historyLink} className={classes.issueHistory}>
                                Issues Workflow History &gt;&gt;
                            </a>
                        </Grid>
                        <Grid item xs={12} className="">
                            <MaterialTable
                                title="Issue Wise Liability List"
                                className=""
                                columns={liabilityColumnsGSTIN}
                                data={enqIssuesLists}
                                icons={tableIconsInputScreen}
                                options={{
                                    emptyRowsWhenPaging: false,
                                    actionsColumnIndex: -1,
                                    exportButton: {
                                        csv: true,
                                        pdf: false,
                                    },
                                    pageSize: 30,
                                    exportAllData: true,
                                    exportFileName: "UploadedIssues",
                                    rowStyle: (x) => {
                                        if (x.tableData.id % 2) {
                                            return { backgroundColor: "#F2FCFC" };
                                        } else {
                                            return { backgroundColor: "white" };
                                        }
                                    },
                                }}
                                actions={[
                                    (rowData) => ({
                                        icon: VisibilityIcon,
                                        isFreeAction: true,
                                        tooltip: `Add Issue Level Details`,
                                        onClick: (event, rowData) => viewIssueDetails(rowData),
                                        hidden: rowData.issueName === "Total" ? true : false,
                                    }),
                                    {
                                        icon: GetSaveButton,
                                        isFreeAction: true,
                                    },
                                ]}
                                localization={{
                                    header: {
                                        actions: "View Details",
                                    },
                                }}
                                components={{
                                    Container: (props) => (
                                        <Paper className="issueTable" {...props} />
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mt-1">
                        <Grid item xs={12} className="mt-1">
                            <MaterialTable
                                title="Event Details"
                                columns={eventColumns}
                                data={events}
                                icons={tableIconsInputScreen}
                                options={{
                                    search: true,
                                    emptyRowsWhenPaging: false,
                                    exportButton: {
                                        csv: true,
                                        pdf: false,
                                    },
                                    exportFileName: "EventsList",
                                    exportAllData: true,
                                    rowStyle: (x) => {
                                        if (x.tableData.id % 2) {
                                            return { backgroundColor: "#F2FCFC" };
                                        } else {
                                            return { backgroundColor: "white" };
                                        }
                                    },
                                }}
                                actions={[
                                    {
                                        icon: AddIcon,
                                        isFreeAction: true,
                                        tooltip: "Add Events",
                                        hidden:
                                            userDetails.roleId !== 1 && userDetails.roleId !== 2
                                                ? true
                                                : false,
                                        onClick: () => {
                                            NavigateEventsPage(toolcaseId);
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <></>
            )}
            <Grid container spacing={3} className="mt-1 d-flex jc-center">
                {encryptedId ? (
                    <Grid item xs={6} md={2}>
                        <Link to={"/viewToolCase"}>
                            <Button
                                type="submit"
                                fullWidth
                                className="pt-button--secondary"
                                id="SupplierLoginBtn">
                                Back
                            </Button>
                        </Link>
                    </Grid>
                ) : (
                    <></>
                )}
                {(encryptedId &&
                    approveStatus === "Draft" &&
                    userDetails.roleId === 2) ||
                    (encryptedId &&
                        approveStatus === "Rejected" &&
                        userDetails.roleId === 2) ? (
                    <Grid item xs={6} md={2}>
                        <Button
                            type="submit"
                            fullWidth
                            className="pt-button--primary"
                            id="SupplierLoginBtn"
                            // disabled={enqIssuesLists.length === 0 || events.length === 0}
                            disabled={
                                (subModuleValue !== 4 &&
                                    (enqIssuesLists.length === 0 || events.length === 0)) ||
                                    (subModuleValue === 4 && events.length === 0)
                                    ? true
                                    : false
                            }
                            onClick={changeStatus}>
                            Send for Approval
                        </Button>
                    </Grid>
                ) : (
                    <></>
                )}
                {movingStatus === 1 &&
                    approveStatus === "Approved" &&
                    (userDetails.roleId === 2 || userDetails.roleId === 1) ? (
                    <Grid item xs={6} md={2}>
                        <Button
                            type="submit"
                            fullWidth
                            className="pt-button--primary"
                            id="SupplierLoginBtn"
                            onClick={moveToScn}
                            endIcon={<Icon>send</Icon>}>
                            Move to Next Stage
                        </Button>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseCFRDialog(event, reason);
                    }
                }}
                className=""
                aria-labelledby="litigation-form--registration"
                open={openCFRDialog}
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={2}>
                            <h5 className={classes.m0}>Add CFR Number</h5>
                            <Grid item xs={12}>
                                <TextField
                                    label="CFR Number"
                                    id=""
                                    name=""
                                    className=""
                                    fullWidth
                                    value={cfrNo}
                                    onChange={handleCfrNo}
                                    variant="filled"
                                />
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        className="pt-button--secondary"
                        id="SupplierLoginBtn"
                        onClick={handleCloseCFRDialog}>
                        close
                    </Button>
                    <Button
                        type="submit"
                        className="pt-button--primary"
                        id="SupplierLoginBtn"
                        disabled={!cfrNo}
                        onClick={saveCFRNumber}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleCloseAlertDialog(event, reason);
                    }
                }}
                aria-labelledby="litigation-form--registration"
                open={alertDialogOpen}
                maxWidth={"md"}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <h5 className={classes.m0}>Notification :-</h5>
                            </Grid>
                            <Grid item xs={12}>
                                {notificationData.map((item) => (
                                    <p className={classes.pTag}>{item}</p>
                                ))}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        className="pt-button--secondary"
                        id="SupplierLoginBtn"
                        onClick={handleCloseAlertDialog}>
                        close
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                <SnackbarCustom
                    open={openSnackbar}
                    message={snackMessage}
                    alertType={alertType}
                    handleClose={handleCloseSnackbar}
                />
            </>
            <>
                <SnackbarCustom
                    open={openSnackbarInternal}
                    message={snackMessageInternal}
                    alertType={alertTypeInternal}
                    handleClose={handleCloseSnackbarInternal}
                />
            </>
            {isLoader ? <Loader loaderText="Loading...." /> : <></>}
        </div>
    );
}
